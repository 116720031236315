import React from "react";
import { colors } from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { alpha } from "@mui/material";

const blogWriterFromSpeech = {
  title: "Blog Writer From Speech",
  subtitle: "Create compelling blog content from audio files.",
  targetRoute: "/blog-writer-from-speech",
  color: colors.green[500],
  bgcolor: alpha(colors.green[500], 0.1),
  icon: <BorderColorOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default blogWriterFromSpeech;
