import React from "react";
import { colors } from "@mui/material";
import AudiotrackOutlinedIcon from "@mui/icons-material/AudiotrackOutlined";
import { alpha } from "@mui/material";

const modernHymnMaker = {
  title: "Modern Hymn Maker",
  subtitle: "Update traditional hymns for today's worship experience.",
  targetRoute: "/modern-hymn-maker",
  color: colors.amber[600],
  bgcolor: alpha(colors.amber[600], 0.1),
  icon: <AudiotrackOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default modernHymnMaker;
