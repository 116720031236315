import createOptLangKnowNoBibleTemplate from "../OptLangKnowNoBibleTemplate";

const parishEventPlanner = createOptLangKnowNoBibleTemplate({
  name: "parisheventplanner",
  endpoint: "/api/openai/parish-event-planner",
  aiText: "parishEventPlanner",
  aiTextHandler: "parishEventPlannerHandler",
  aiTextPlainText: "parishEventPlannerPlainText",
  title: "Parish Event Planner",
  subText:
    "Plan your church events effortlessly with our Parish Event Planner. Enter the event type and duration, and get a step-by-step planning guide. For example - Preparation for Christmas, 6 days.",
  label: "Your Parish Event Plan",
  placeholderText: "Enter the type of event and duration…",
  buttonText: "Plan Event",
  aiPlaceholder: "Your parish event plan will be displayed here",
});

export default parishEventPlanner;
