import createNoKeyOptLangToneTemplate from "../NoKeyOptLangToneTemplate";

const sermonWriter = createNoKeyOptLangToneTemplate({
  name: "sermon",
  endpoint: "/api/openai/sermon",
  aiText: "sermon",
  aiTextHandler: "sermonHandler",
  aiTextPlainText: "sermonPlainText",
  title: "Sermon Writer",
  subText:
    "The Sermon Writer is designed to assist you in creating comprehensive sermons. Simply input a scripture like 'John 3:16' or a topic like 'the Beatitudes', and see a thoughtfully-generated sermon ready in seconds!",
  label: "Sermon Text",
  placeholderText: "Type in your sermon theme...",
  buttonText: "Generate sermon",
  aiPlaceholder: "Your sermon will be displayed here",
});

export default sermonWriter;
