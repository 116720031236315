import createLongTopicNoKeyOptChapLangToneTemplate from "../LongTopicNoKeyOptChapLangToneTemplate";

const bibleGamesForFamilies = createLongTopicNoKeyOptChapLangToneTemplate({
  name: "bibleGamesForFamilies",
  endpoint: "/api/openai/bible-games-for-families",
  aiText: "bibleGamesForFamilies",
  aiTextHandler: "bibleGamesForFamiliesHandler",
  aiTextPlainText: "bibleGamesForFamiliesPlainText",
  title: "Bible Games for Families",
  subText:
    "Find Christian game ideas suitable for your family. Enter the ages and interests of your family members (e.g., '6, 9, 12, board games, puzzles') and get a fun game idea.",
  label: "Christian Family Game Idea",
  placeholderText: "Enter the ages and interests of your family members…",
  buttonText: "Create Bible Game",
  aiPlaceholder: "Bible game for families will be displayed here",
});

export default bibleGamesForFamilies;
