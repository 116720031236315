import React from "react";
import { colors } from "@mui/material";
import LibraryMusicOutlinedIcon from "@mui/icons-material/LibraryMusicOutlined";
import { alpha } from "@mui/material";

const songRecommender = {
  title: "Song Recommender",
  subtitle: "Find the perfect song for your prayer gathering.",
  targetRoute: "/song-recommender",
  color: colors.pink[600],
  bgcolor: alpha(colors.pink[600], 0.1),
  icon: <LibraryMusicOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default songRecommender;
