import createOptLangKnowKeyPlatformAudienceTemplate from "../OptLangKnowKeyPlatformAudienceTemplate";

const contentPlanner = createOptLangKnowKeyPlatformAudienceTemplate({
  name: "contentplanner",
  endpoint: "/api/openai/content-planner",
  aiText: "contentPlanner",
  aiTextHandler: "contentPlannerHandler",
  aiTextPlainText: "contentPlannerPlainText",
  title: "Content Planner",
  subText:
    "Stay on schedule on your website with Content Planner. Enter your main topic, and get a content publishing calendar filled with captivating content ideas for a month.",
  label: "Your Content Calendar",
  placeholderText: "What is your content focus?",
  buttonText: "Plan my content",
  aiPlaceholder: "See your content calendar here",
});

export default contentPlanner;
