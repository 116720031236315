import Fuse from "fuse.js";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import Container from "../../../Container";

import {
  bibleGamesForFamilies,
  bibleQuizGenerator,
  bibleStoriesForKids,
  bibleStudyGuide,
  bibleStudyPlanGenerator,
  bibleTriviaGeneratorAdults,
  bibleTriviaGeneratorChildren,
  biblicalSongwriter,
  couplesBibleStudyGuide,
  familyScriptureStudyPlan,
  scriptureCrossReference,
  scriptureSays,
  scriptureStudyPlanMen,
  scriptureStudyPlanWomen,
} from "../../cards/BibleTools/index";

import {
  canonLawReference,
  catechismReference,
  sacramentalPreparationGuide,
  saintBiography,
  saintSayings,
} from "../../cards/CatholicTools/index";

import {
  analogyMaker,
  blogContentPlanner,
  blogOutline,
  blogTopicIdeas,
  blogWriter,
  longReflectionGenerator,
  lyricsGenerator,
  keywordResearch,
  mensMinistryBlogPostIdeas,
  modernHymnMaker,
  narrateToAChild,
  quoteGenerator,
  rapCreator,
  relatedTopicsResearch,
  sermon,
  shortReflectionGenerator,
  websiteContentPlanner,
  websitePageContentGenerator,
  weddingVowsGenerator,
  womensMinistryBlogPostIdeas,
} from "../../cards/ContentTools/index";

import {
  couplesDevotionalPlanner,
  dailyDevotionsPlanner,
  hymnRecommender,
  parishEventPlanner,
  parishNewsletterCreator,
  songRecommender,
  youthMinistryEventPlanner,
} from "../../cards/PlannerTools/index";

import {
  familyPrayerGenerator,
  prayerGenerator,
} from "../../cards/PrayerTools/index";

import {
  marriageRetreatPlanner,
  retreatPlanner,
} from "../../cards/RetreatTools/index";

import {
  contentPlanner,
  podcastCreator,
  socialMediaContentGenerator,
  youtubeScriptwriter,
  youtubeVideoDescription,
  YoutubeVideoIdeas,
  youtubeVideoTitle,
} from "../../cards/SocialMediaTools/index";

import { blogWriterFromSpeech } from "../../cards/SpeechToTextTools/index";

const features = [
  bibleGamesForFamilies,
  bibleQuizGenerator,
  bibleStoriesForKids,
  bibleStudyGuide,
  bibleStudyPlanGenerator,
  bibleTriviaGeneratorAdults,
  bibleTriviaGeneratorChildren,
  biblicalSongwriter,
  couplesBibleStudyGuide,
  familyScriptureStudyPlan,
  scriptureCrossReference,
  scriptureSays,
  scriptureStudyPlanMen,
  scriptureStudyPlanWomen,

  canonLawReference,
  catechismReference,
  sacramentalPreparationGuide,
  saintBiography,
  saintSayings,

  analogyMaker,
  blogContentPlanner,
  blogOutline,
  blogTopicIdeas,
  blogWriter,
  longReflectionGenerator,
  lyricsGenerator,
  keywordResearch,
  mensMinistryBlogPostIdeas,
  modernHymnMaker,
  narrateToAChild,
  quoteGenerator,
  rapCreator,
  relatedTopicsResearch,
  sermon,
  shortReflectionGenerator,
  websiteContentPlanner,
  websitePageContentGenerator,
  weddingVowsGenerator,
  womensMinistryBlogPostIdeas,

  couplesDevotionalPlanner,
  dailyDevotionsPlanner,
  hymnRecommender,
  parishEventPlanner,
  parishNewsletterCreator,
  songRecommender,
  youthMinistryEventPlanner,

  familyPrayerGenerator,
  prayerGenerator,

  marriageRetreatPlanner,
  retreatPlanner,

  contentPlanner,
  podcastCreator,
  socialMediaContentGenerator,
  youtubeScriptwriter,
  youtubeVideoDescription,
  YoutubeVideoIdeas,
  youtubeVideoTitle,

  blogWriterFromSpeech,
];

const options = {
  includeScore: true,
  keys: ["title", "subtitle", "tags"],
  threshold: 0,
};

const fuse = new Fuse(features, options);

const SearchBar = ({ searchQuery, onSearchChange }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Container>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        alignItems={"center"}
        justifyContent={"center"}
        marginTop={4}
      >
        <TextField
          variant="outlined"
          color="primary"
          size="medium"
          sx={{
            width: { xs: "100%", sm: "800px" },
            marginRight: isMd ? 2 : 0,
            marginBottom: isMd ? 0 : 1,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon color={"primary"} />
              </InputAdornment>
            ),
          }}
          value={searchQuery}
          onChange={onSearchChange}
          placeholder="Search for features..."
        />
      </Box>
    </Container>
  );
};

export default SearchBar;
