import createAITextGenerator from "../WebsitePageContentTemplate";

const websitePageContentGenerator = createAITextGenerator({
  name: "websitepagecontentgenerator",
  endpoint: "/api/openai/website-page-content-generator",
  aiText: "websitePageContentGenerator",
  aiTextHandler: "websitePageContentGeneratorHandler",
  aiTextPlainText: "websitePageContentGeneratorPlainText",
  title: "Website Page Content Generator",
  subText:
    "Our Website Page Content Generator makes creating web page content simple. Add your church or ministry name, enter a page type like 'Youth Ministry' or ‘About us’ and receive tailored content!",
  label: "Web Page Content",
  placeholderText: "Page type",
  buttonText: "Create Content",
  aiPlaceholder: "Your page content will appear here",
});

export default websitePageContentGenerator;
