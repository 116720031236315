import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios"; // or your preferred method for API requests
import {
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Link,
} from "@mui/material";

const paymentVerificationImage =
  "https://res.cloudinary.com/dxdpahm3o/image/upload/v1691409472/payment_webwkw.svg";

const PaymentVerification = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const location = useLocation();
  const sessionId = new URLSearchParams(location.search).get("session_id");

  useEffect(() => {
    const fetchNewToken = async () => {
      try {
        const tokenResponse = await axios.get("/api/auth/refresh-token");
        const newAccessToken = tokenResponse.data.accessToken;

        if (newAccessToken) {
          // Save the new access token to local storage or any authentication state
          localStorage.setItem("authToken", newAccessToken);

          // Optionally, you could also use sessionId here to retrieve further details about the payment
        }
      } catch (err) {
        console.log("Error refreshing token:", err);
      }
    };

    fetchNewToken();
  }, [sessionId]);

  return (
    <Box
      px={1}
      width="100%"
      height="100vh"
      mx="auto"
      position="relative"
      zIndex={2}
    >
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        {isMd ? (
          <Grid item container justifyContent={"center"} xs={12} md={6}>
            <Box height={1} width={1} maxWidth={500}>
              <Box
                component={"img"}
                src={paymentVerificationImage}
                width={1}
                height={1}
                sx={{
                  filter:
                    theme.palette.mode === "dark" ? "brightness(0.8)" : "none",
                }}
              />
            </Box>
          </Grid>
        ) : null}
        <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
          <Box mt={3} mb={1} textAlign="center">
            <Typography variant="h4" color="text.primary">
              Payment Successful!
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Thank you for subscribing to our service! Your payment has been
              successfully processed. You can now{" "}
              <Link href="/dashboard" color={theme.palette.primary.main}>
                click here
              </Link>{" "}
              to go to your dashboard and start exploring.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaymentVerification;
