import createNoKeyOptChapLangToneTemplate from "../NoKeyOptChapLangToneTemplate";

const bibleTriviaGeneratorAdults = createNoKeyOptChapLangToneTemplate({
  name: "bibletriviageneratoradults",
  endpoint: "/api/openai/bible-trivia-generator-adults",
  aiText: "bibleTriviaGeneratorAdults",
  aiTextHandler: "bibleTriviaGeneratorAdultsHandler",
  aiTextPlainText: "bibleTriviaGeneratorAdultsPlainText",
  title: "Bible Trivia Generator - Adults",
  subText:
    "Generate engaging Bible trivia questions for adults. Enter a topic or book of the Bible and create an advanced quiz.",
  label: "Bible Trivia for Adults",
  placeholderText: "Enter a topic or book of the Bible…",
  buttonText: "Generate Trivia",
  aiPlaceholder: "Bible trivia questions will appear here",
});

export default bibleTriviaGeneratorAdults;
