// import React from "react";
// import { Select, MenuItem, Checkbox, ListItemText } from "@mui/material";

// const ProtestantBibleChaptersSelect = ({ bibleChapter, setBibleChapter }) => {
//   const handleChange = (event) => {
//     setBibleChapter(event.target.value);
//   };

import React from "react";
import { Select, MenuItem, Checkbox, ListItemText, FormControl, InputLabel } from "@mui/material";

const ProtestantBibleChaptersSelect = ({ bibleChapter, setBibleChapter }) => {
  const handleChange = (event) => {
    const selectedValues = event.target.value;
    
    // Special handling for Entire Bible, Old Testament, New Testament, and Gospels
    const specialSelections = ["Entire Bible", "Old Testament", "New Testament", "Gospels"];
    const isSpecialSelection = specialSelections.some(special => selectedValues.includes(special));
    const hadSpecialSelection = specialSelections.some(special => bibleChapter.includes(special));
    
    if (isSpecialSelection) {
      // Find which special selection was chosen
      const selectedSpecial = specialSelections.find(special => 
        selectedValues.includes(special) && !bibleChapter.includes(special)
      );
      
      if (selectedSpecial) {
        // If a special selection is chosen, make it the only selection
        setBibleChapter([selectedSpecial]);
        return;
      }
    } else if (hadSpecialSelection) {
      // If coming from a special selection, only keep the new selections
      setBibleChapter(selectedValues.filter(chapter => !specialSelections.includes(chapter)));
      return;
    }
    
    // Normal case: update the selection
    setBibleChapter(selectedValues);
  };

  const formatRenderValue = (selected) => {
    if (!selected || selected.length === 0) return "Select Bible chapters";
    if (selected.length === 1) return selected[0];
    return selected.join(", ");
  };

  const bibleChapters = [
    "Entire Bible",
    "Old Testament",
    "New Testament",
    "Gospels",
    "Genesis",
    "Exodus",
    "Leviticus",
    "Numbers",
    "Deuteronomy",
    "Joshua",
    "Judges",
    "Ruth",
    "1 Samuel",
    "2 Samuel",
    "1 Kings",
    "2 Kings",
    "1 Chronicles",
    "2 Chronicles",
    "Ezra",
    "Nehemiah",
    "Esther",
    "Job",
    "Psalms",
    "Proverbs",
    "Ecclesiastes",
    "Song of Solomon",
    "Isaiah",
    "Jeremiah",
    "Lamentations",
    "Ezekiel",
    "Daniel",
    "Hosea",
    "Joel",
    "Amos",
    "Obadiah",
    "Jonah",
    "Micah",
    "Nahum",
    "Habakkuk",
    "Zephaniah",
    "Haggai",
    "Zechariah",
    "Malachi",
    "Matthew",
    "Mark",
    "Luke",
    "John",
    "Acts",
    "Romans",
    "1 Corinthians",
    "2 Corinthians",
    "Galatians",
    "Ephesians",
    "Philippians",
    "Colossians",
    "1 Thessalonians",
    "2 Thessalonians",
    "1 Timothy",
    "2 Timothy",
    "Titus",
    "Philemon",
    "Hebrews",
    "James",
    "1 Peter",
    "2 Peter",
    "1 John",
    "2 John",
    "3 John",
    "Jude",
    "Revelation",
  ];

//   return (
//     <Select
//       multiple
//       renderValue={(value) => (value !== "" ? value : "Entire Bible")}
//       fullWidth
//       value={bibleChapter}
//       onChange={handleChange}
//       sx={{ mb: 1.5 }}
//     >
//       <MenuItem value="">
//         <em>Select a Bible chapter</em>
//       </MenuItem>
//       {bibleChapters.map((chapter) => (
//         <MenuItem key={chapter} value={chapter}>
//           <Checkbox checked={bibleChapter.indexOf(chapter) > -1} />
//           <ListItemText primary={chapter} />
//         </MenuItem>
//       ))}
//     </Select>
//   );
// };

return (
  <FormControl fullWidth>
    <InputLabel>Select Bible Chapter(s)</InputLabel>
    <Select
      multiple
      label="Select Bible Chapter(s)"
      renderValue={formatRenderValue}
      fullWidth
      value={bibleChapter}
      onChange={handleChange}
      sx={{ mb: 1.5 }}
    >
      {bibleChapters.map((chapter) => (
        <MenuItem key={chapter} value={chapter}>
          <Checkbox checked={bibleChapter.indexOf(chapter) > -1} />
          <ListItemText primary={chapter} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
};

export default ProtestantBibleChaptersSelect;
