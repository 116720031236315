import createOptLangToneKeyTemplate from "../OptLangToneKeyTemplate";

const youtubeVideoTitle = createOptLangToneKeyTemplate({
  name: "youtubevideotitle",
  endpoint: "/api/openai/youtube-video-title",
  aiText: "youtubeVideoTitle",
  aiTextHandler: "youtubeVideoTitleHandler",
  aiTextPlainText: "youtubeVideoTitlePlainText",
  title: "YouTube Title Maker",
  subText:
    "Our Title Maker creates intriguing titles for your YouTube videos. Enter your topic and receive five headlines designed to attract clicks.",
  label: "Your Video Titles",
  placeholderText: "Enter the topic of your video…",
  buttonText: "Generate Titles",
  aiPlaceholder: "See your captivating video titles here",
});

export default youtubeVideoTitle;
