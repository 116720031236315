import createOptLangKnowTone from "../OptLangKnowToneTemplate";

const youtubeVideoDescription = createOptLangKnowTone({
  name: "youtubevideodescription",
  endpoint: "/api/openai/youtube-video-description",
  aiText: "youtubeVideoDescription",
  aiTextHandler: "youtubeVideoDescriptionHandler",
  aiTextPlainText: "youtubeVideoDescriptionPlainText",
  title: "SEO-friendly YouTube Video Description",
  subText:
    "Use our YouTube Description Generator to create engaging video descriptions. Just provide the key elements of your video and receive a tailored 100-word description.",
  label: "Your Video Description",
  placeholderText: "Provide key points about your video…",
  buttonText: "Write Description",
  aiPlaceholder: "Generated description will be displayed here",
});

export default youtubeVideoDescription;
