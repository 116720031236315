import createOptLangToneTemplate from "../OptLangToneTemplate";

const lyricsGenerator = createOptLangToneTemplate({
  name: "lyricsgenerator",
  endpoint: "/api/openai/lyrics-generator",
  aiText: "lyricsGenerator",
  aiTextHandler: "lyricsGeneratorHandler",
  aiTextPlainText: "lyricsGeneratorPlainText",
  title: "Lyrics Generator",
  subText:
    "Compose soul-touching worship songs using Lyrics Generator. Just input your theme, like 'Eternal Love' and get inspired.",
  label: "Generated Lyrics",
  placeholderText: "Enter a theme for your song...",
  buttonText: "Write Lyrics",
  aiPlaceholder: "Your lyrics will appear here",
});

export default lyricsGenerator;
