// // import axios from "axios";
// // import { refreshToken } from "./refreshToken";

// // const instance = axios.create();

// // instance.interceptors.response.use(
// //   (response) => {
// //     console.log("Server response data:", response.data);
// //     return response;
// //   },
// //   async (error) => {
// //     const originalRequest = error.config;

// //     console.error("Error message:", error.message);
// //     console.log("Server response data:", error.response.data);

// //     if (error.response.status === 401 && !originalRequest._retry) {
// //       originalRequest._retry = true;

// //       if (error.response.data.error === "That email is not registered") {
// //         return Promise.reject("That email is not registered");
// //       }

// //       const newAccessToken = await refreshToken();

// //       if (newAccessToken !== null) {
// //         instance.defaults.headers.common[
// //           "Authorization"
// //         ] = `Bearer ${newAccessToken}`;
// //         originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
// //         return instance(originalRequest);
// //       } else {
// //         // This part handles when the refresh token is invalid or expired
// //         // 1. Remove stored tokens
// //         localStorage.removeItem("accessToken");
// //         localStorage.removeItem("refreshToken");

// //         // 2. Inform the user
// //         // Add your notification logic here (e.g., a toast)
// //         // For example: showToast("Your session has expired. Please log in again.");

// //         // 3. Redirect to login
// //         window.location.href = "/login";
// //       }
// //     }

// //     return Promise.reject(error);
// //   }
// // );

// // export default instance;


// // //client/src/helpers/axiosInstance.js
// // import axios from "axios";
// // import { refreshToken } from "./refreshToken";

// // const BASE_URL = 'http://localhost:4242';

// // // const BASE_URL = 'https://church-scribe-backend-158493438550.europe-west1.run.app'

// //   const instance = axios.create({
// //     baseURL: BASE_URL,
// //     withCredentials: true,
// //     headers: {
// //       'Content-Type': 'application/json'
// //     }
// //   });

// // // const instance = axios.create();

// // instance.interceptors.response.use(
// //   (response) => {
// //     console.log("Server response data:", response.data);
// //     return response;
// //   },
// //   async (error) => {
// //     const originalRequest = error.config;

// //     console.error("Error message:", error.message);
// //     if (error.response) {
// //       console.log("Server response data:", error.response.data);

// //       // Handle 401 Unauthorized errors
// //       if (error.response.status === 401 && !originalRequest._retry) {
// //         originalRequest._retry = true;

// //         if (error.response.data.error === "That email is not registered") {
// //           return Promise.reject("That email is not registered");
// //         }

// //         const newAccessToken = await refreshToken();

// //         if (newAccessToken !== null) {
// //           instance.defaults.headers.common["Authorization"] = `Bearer ${newAccessToken}`;
// //           originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
// //           return instance(originalRequest);
// //         } else {
// //           localStorage.removeItem("accessToken");
// //           localStorage.removeItem("refreshToken");
// //           window.location.href = "/login";
// //         }
// //       }

// //       // Handle 500 Server errors
// //       if (error.response.status === 500) {
// //         // For organization name related errors, return empty array instead of failing
// //         if (error.response.data.error === 'organisationName is not defined' &&
// //             originalRequest.url.includes('/api/knowledgebase/organisationName')) {
// //           return Promise.resolve({ data: { organisationName: [] } });
// //         }
        
// //         // For other 500 errors, reject with a more user-friendly message
// //         return Promise.reject({
// //           message: 'Something went wrong on the server. Please try again later.',
// //           originalError: error.response.data.error
// //         });
// //       }
// //     }

// //     // Handle network errors or other issues
// //     if (!error.response) {
// //       return Promise.reject({
// //         message: 'Unable to connect to the server. Please check your internet connection.',
// //         originalError: error.message
// //       });
// //     }

// //     return Promise.reject(error);
// //   }
// // );

// // export default instance;


// // client/src/helpers/axiosInstance.js
// import axios from "axios";
// import { refreshToken } from "./refreshToken";

// // const BASE_URL = 'http://localhost:4242';

// const BASE_URL = 'https://church-scribe-backend-158493438550.europe-west1.run.app';

// const instance = axios.create({
//   baseURL: BASE_URL,
//   withCredentials: true,
//   headers: {
//     'Content-Type': 'application/json'
//   }
// });

// // Add request interceptor to automatically add auth token
// instance.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem("authToken");
//     if (token) {
//       config.headers.Authorization = token.startsWith('Bearer ') ? token : `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// instance.interceptors.response.use(
//   (response) => {
//     console.log("Server response data:", response.data);
//     return response;
//   },
//   async (error) => {
//     const originalRequest = error.config;

//     console.error("Error message:", error.message);
//     if (error.response) {
//       console.log("Server response data:", error.response.data);

//       // Handle 401 Unauthorized errors
//       if (error.response.status === 401 && !originalRequest._retry) {
//         originalRequest._retry = true;

//         if (error.response.data.error === "That email is not registered") {
//           return Promise.reject("That email is not registered");
//         }

//         try {
//           const newAccessToken = await refreshToken();

//           if (newAccessToken !== null) {
//             // Update token in localStorage and headers
//             const bearerToken = newAccessToken.startsWith('Bearer ') ? 
//               newAccessToken : `Bearer ${newAccessToken}`;
//             localStorage.setItem("authToken", bearerToken);
//             instance.defaults.headers.common["Authorization"] = bearerToken;
//             originalRequest.headers["Authorization"] = bearerToken;
//             return instance(originalRequest);
//           } else {
//             localStorage.removeItem("authToken");
//             window.location.href = "/login";
//           }
//         } catch (refreshError) {
//           localStorage.removeItem("authToken");
//           window.location.href = "/login";
//           return Promise.reject(refreshError);
//         }
//       }

//       // Handle 500 Server errors
//       if (error.response.status === 500) {
//         if (error.response.data.error === 'organisationName is not defined' &&
//             originalRequest.url.includes('/api/knowledgebase/organisationName')) {
//           return Promise.resolve({ data: { organisationName: [] } });
//         }
        
//         return Promise.reject({
//           message: 'Something went wrong on the server. Please try again later.',
//           originalError: error.response.data.error
//         });
//       }
//     }

//     if (!error.response) {
//       return Promise.reject({
//         message: 'Unable to connect to the server. Please check your internet connection.',
//         originalError: error.message
//       });
//     }

//     return Promise.reject(error);
//   }
// );

// export default instance;



// // client/src/helpers/axiosInstance.js
// import axios from "axios";


// const BASE_URL = 'http://localhost:4242';


// // const BASE_URL = 'https://church-scribe-backend-158493438550.europe-west1.run.app';

// const instance = axios.create({
//   baseURL: BASE_URL,
//   withCredentials: true,
//   headers: {
//     'Content-Type': 'application/json'
//   }
// });

// // Request interceptor
// instance.interceptors.request.use(
//   (config) => {
//     // Skip auth header for login
//     if (config.url.includes('/auth/login')) {
//       return config;
//     }

//     const token = localStorage.getItem('authToken');
// if (token) {
//   config.headers.Authorization = `Bearer ${token}`;
// }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );

// // Response interceptor
// instance.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;

//     if (error.response?.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       localStorage.removeItem('authToken');
//       window.location.href = '/login';
//     }

//     return Promise.reject(error);
//   }
// );

// export default instance;




//client/src/helpers/axiosInstance.js

import axios from "axios";
import { getAuth } from "firebase/auth";


// const BASE_URL = 'http://localhost:4242';

const BASE_URL = 'https://church-scribe-backend-158493438550.europe-west1.run.app';

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Request interceptor
instance.interceptors.request.use(
  async (config) => {
    try {
      const auth = getAuth();
      if (auth.currentUser) {
        const token = await auth.currentUser.getIdToken();
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    } catch (error) {
      console.error("Auth error:", error);
      return config;
    }
  },
  (error) => Promise.reject(error)
);

// Simple response interceptor
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("Response error:", error);
    return Promise.reject(error);
  }
);

export default instance;