import createNoKeyOptChapLangToneTemplate from "../NoKeyOptChapLangToneTemplate";

const familyScriptureStudyPlanner = createNoKeyOptChapLangToneTemplate({
  name: "familyscripturestudyplanner",
  endpoint: "/api/openai/family-scripture-study-planner",
  aiText: "familyScriptureStudyPlanner",
  aiTextHandler: "familyScriptureStudyPlannerHandler",
  aiTextPlainText: "familyScriptureStudyPlannerPlainText",
  title: "Family Scripture Study Planner",
  subText:
    "Create a Bible study schedule for your family. Share which books or themes you would like to focus on and how long you plan to study, and receive a thorough study plan. An example of your input can be “love amongst family members, 5 days”.",
  label: "Family Bible Study Schedule",
  placeholderText: "Input books or theme and study period…",
  buttonText: "Create Schedule",
  aiPlaceholder: "Family Bible study schedule will be displayed here",
});

export default familyScriptureStudyPlanner;
