import React, { useState } from "react";
import instance from "../../../../helpers/axiosInstance";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Page from "../components/Page";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const validationSchema = yup.object({
  currentPassword: yup.string().required("Please specify your password"),
  newPassword: yup
    .string()
    .required("Please specify your password")
    .min(8, "The password should have at minimum length of 8"),
  repeatPassword: yup
    .string()
    .required("Please specify your password")
    .min(8, "The password should have at minimum length of 8")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

const Security = () => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const initialValues = {
    currentPassword: "",
    newPassword: "",
    repeatPassword: "",
  };

  const [successMessage, setSuccessMessage] = useState("");
  const [successVisible, setSuccessVisible] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowRepeatPassword = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (values) => {
    try {
      const response = await instance.put(
        "/api/auth/update-password",
        {
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
        },
        config
      );

      if (response.status === 200) {
        if (response.status === 200) {
          setSuccessMessage("Password updated successfully");
          setSuccessVisible(true);
        } else {
          console.error("Error updating password:", response);
        }
      }
    } catch (error) {
      console.error("Error updating password:", error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });
  return (
    <Page>
      <Box>
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent={"space-between"}
          alignItems={{ xs: "flex-start", md: "center" }}
        >
          <Typography variant="h6" fontWeight={700}>
            Change your password
          </Typography>
          {successVisible && (
            <Typography
              variant="body1"
              style={{ color: "green", marginBottom: 16 }}
            >
              {successMessage}
            </Typography>
          )}

          <Button
            size={"large"}
            variant={"outlined"}
            sx={{ marginTop: { xs: 2, md: 0 } }}
          >
            Log out
          </Button>
        </Box>
        <Box paddingY={4}>
          <Divider />
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography
                variant={"subtitle2"}
                sx={{ marginBottom: 2 }}
                fontWeight={700}
              >
                Current password
              </Typography>
              <TextField
                variant="outlined"
                name={"currentPassword"}
                type={showCurrentPassword ? "text" : "password"}
                fullWidth
                value={formik.values.currentPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.currentPassword &&
                  Boolean(formik.errors.currentPassword)
                }
                helperText={
                  formik.touched.currentPassword &&
                  formik.errors.currentPassword
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowCurrentPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showCurrentPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant={"subtitle2"}
                sx={{ marginBottom: 2 }}
                fontWeight={700}
              >
                New password
              </Typography>
              <TextField
                variant="outlined"
                name={"newPassword"}
                type={showNewPassword ? "text" : "password"}
                fullWidth
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.newPassword &&
                  Boolean(formik.errors.newPassword)
                }
                helperText={
                  formik.touched.newPassword && formik.errors.newPassword
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant={"subtitle2"}
                sx={{ marginBottom: 2 }}
                fontWeight={700}
              >
                Repeat password
              </Typography>
              <TextField
                variant="outlined"
                name={"repeatPassword"}
                type={showRepeatPassword ? "text" : "password"}
                fullWidth
                value={formik.values.repeatPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.repeatPassword &&
                  Boolean(formik.errors.repeatPassword)
                }
                helperText={
                  formik.touched.repeatPassword && formik.errors.repeatPassword
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowRepeatPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item container xs={12}>
              <Box
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "stretched", sm: "center" }}
                justifyContent={"space-between"}
                width={1}
                margin={"0 auto"}
              >
                <Button size={"large"} variant={"contained"} type={"submit"}>
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Page>
  );
};

export default Security;
