import React, { useState, useEffect } from "react";
import { useRecoilValue } from 'recoil';
import { authState } from '../../../../../../atoms';

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";

import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import ChurchOutlinedIcon from "@mui/icons-material/ChurchOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import VolunteerActivismOutlinedIcon from "@mui/icons-material/VolunteerActivismOutlined";
import TagOutlinedIcon from "@mui/icons-material/TagOutlined";
import OtherHousesOutlinedIcon from "@mui/icons-material/OtherHousesOutlined";

import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";

import { CollapsibleItem } from "./components";

const mock = [
  {
    groupTitle: "AI Writing Tools",
    id: "tools",
    pages: [
      {
        title: "All Tools",
        href: "/dashboard",
        icon: <NoteAltOutlinedIcon />,
      },
      {
        title: "Bible Tools",
        href: "/my-bible-tools",
        icon: <MenuBookOutlinedIcon />,
      },
      {
        title: "Catholic Tools",
        href: "/my-catholic-tools",
        icon: <ChurchOutlinedIcon />,
      },

      {
        title: "Content Tools",
        href: "/my-content-tools",
        icon: <EditNoteOutlinedIcon />,
      },
      {
        title: "Planner Tools",
        href: "/my-planner-tools",
        icon: <CalendarMonthOutlinedIcon />,
      },

      {
        title: "Prayer Tools",
        href: "/my-prayer-tools",
        icon: <VolunteerActivismOutlinedIcon />,
      },

      {
        title: "Retreat Tools",
        href: "/my-retreat-tools",
        icon: <OtherHousesOutlinedIcon />,
      },

      {
        title: "Social Media Tools",
        href: "/my-social-media-tools",
        icon: <TagOutlinedIcon />,
      },

      // {
      //   title: "Speech-to-Text Tools",
      //   href: "/my-speech-to-text-tools",
      //   icon: <MicNoneOutlinedIcon />,
      // },
    ],
  },
  {
    groupTitle: "Your History",
    id: "history",
    href: "/history",
    pages: [],
  },

  {
    groupTitle: "Your Account",
    id: "account",
    pages: [
      {
        title: "General Settings",
        href: "/user-profile",
        icon: <SettingsOutlinedIcon />,
      },
      {
        title: "Change Password",
        href: "/update-password",
        icon: <PasswordOutlinedIcon />,
      },
      {
        title: "Billing Portal",
        href: "/billing-portal",
        icon: <PaymentOutlinedIcon />,
      },
    ],
  },

  {
    groupTitle: "Support",
    id: "support",
    pages: [
      {
        title: "Support",
        href: "#",
        icon: <EmailOutlinedIcon />,
      },
      {
        title: "Announcements",
        href: "#",
        icon: <CampaignOutlinedIcon />,
      },
    ],
  },
];

const SidebarNav = () => {
  const auth = useRecoilValue(authState);
  const user = auth.user;

  return (
    <Box paddingY={2} display={"flex"} flexDirection={"column"} height={1}>
      <Box
        marginBottom={3}
        paddingX={2}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "0 !important",
          },
          "& .MuiOutlinedInput-input": {
            padding: 1,
          },
        }}
      />
      {mock.map((item, i) => (
        <Box key={i} marginBottom={3}>
          <CollapsibleItem item={item} isOpen={true} />
        </Box>
      ))}
      <Box sx={{ flexGrow: 1 }} />
      <List sx={{ paddingX: 2 }}>
        {user && (
          <ListItem alignItems="flex-start" disableGutters disablePadding>
            <ListItemAvatar>
              <Avatar
                alt={user.username || ''}
                src={user.avatar || ''}
              />
            </ListItemAvatar>
            <ListItemText
              primary={user.username || ''}
              secondary={user.email || ''}
            />
          </ListItem>
        )}
      </List>
    </Box>
  );
};

export default SidebarNav;