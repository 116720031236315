import React from "react";
import { colors } from "@mui/material";
import NotesOutlinedIcon from "@mui/icons-material/NotesOutlined";
import { alpha } from "@mui/material";

const sermon = {
  title: "Sermon Writer",
  subtitle:
    "Generate sermons that engage, inspire, and challenge your congregation.",
  targetRoute: "/sermon",
  color: colors.purple[500],
  bgcolor: alpha(colors.purple[500], 0.1),
  icon: <NotesOutlinedIcon fontSize="medium" />,
  tags: [
    "sermon",
    "sermon writer",
    "sermon generator",
    "homily",
    "homily generator",
    "homily writer",
    "write homily",
    "write sermon",
    "sermon writing",
    "homily writing",
  ],
};

export default sermon;
