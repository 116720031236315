import React from "react";
import { colors } from "@mui/material";
import CalendarViewMonthOutlinedIcon from "@mui/icons-material/CalendarViewMonthOutlined";
import { alpha } from "@mui/material";

const bibleStudyPlanGenerator = {
  title: "Bible Study Plan Generator",
  subtitle: "Create insightful Bible study plans for your community.",
  targetRoute: "/bible-study-plan-generator",
  color: colors.amber[500],
  bgcolor: alpha(colors.amber[500], 0.1),
  icon: <CalendarViewMonthOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default bibleStudyPlanGenerator;
