import React from "react";
import { colors } from "@mui/material";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import { alpha } from "@mui/material";

const bibleStoriesForKids = {
  title: "Bible Stories for Kids",
  subtitle: "Ignite children's imagination with engaging Bible tales.",
  targetRoute: "/bible-stories-for-kids",
  color: colors.deepOrange[400],
  bgcolor: alpha(colors.deepOrange[400], 0.1),
  icon: <AutoStoriesOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default bibleStoriesForKids;
