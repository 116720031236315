import React from "react";
import { Select, MenuItem, Menu } from "@mui/material";

const SacramentSelect = ({ sacrament, setSacrament }) => {
  return (
    <Select
      displayEmpty
      renderValue={(value) => (value !== "" ? value : "Select sacrament")}
      fullWidth
      value={sacrament}
      onChange={(e) => setSacrament(e.target.value)}
      sx={{ mb: 1.5 }}
    >
      <MenuItem value="Baptism">Baptism</MenuItem>
      <MenuItem value="Confirmation">Confirmation</MenuItem>
      <MenuItem value="Eucharist">Eucharist</MenuItem>
      <MenuItem value="Reconciliation">Reconciliation</MenuItem>
      <MenuItem value="Anointing of the sick">Anointing of the sick</MenuItem>
      <MenuItem value="Matrimony">Matrimony</MenuItem>
      <MenuItem value="Holy Orders">Holy Orders</MenuItem>
    </Select>
  );
};

export default SacramentSelect;
