import createAITextGenerator from "../OptKnowKeyTemplate";

const websiteContentPlanner = createAITextGenerator({
  name: "websitecontentplanner",
  endpoint: "/api/openai/website-content-planner",
  aiText: "websiteContentPlanner",
  aiTextHandler: "websiteContentPlannerHandler",
  aiTextPlainText: "websiteContentPlannerPlainText",
  title: "Website Content Planner",
  subText:
    "Our Website Content Planner helps you in organizing your Church website. Input details of your church or ministry, and get a structured content plan.",
  label: "Content Plan",
  placeholderText: "Input info about your church or ministry…",
  buttonText: "Create Website Plan",
  aiPlaceholder: "Website content plan will appear here",
});

export default websiteContentPlanner;
