import createBlogFromSpeechTemplate from "../BlogFromSpeechTemplate";

const blogWriterFromSpeech = createBlogFromSpeechTemplate({
  name: "blogwriterfromspeech",
  endpoint: "/api/openai/blog-writer-from-speech",
  aiText: "blogWriterFromSpeech",
  aiTextHandler: "blogWriterFromSpeechHandler",
  aiTextPlainText: "blogWriterFromSpeechPlainText",
  title: "Blog Writer From Speech",
  subText:
    "Convert any sermon, teaching, or talk into impactful blog posts with our specialized AI Blog Writer for Church Scribe. Simply upload the sermon's audio and watch our AI craft a captivating, unique blog post. Perfect for personal reflection or professional church websites, our tool caters to a broad spectrum of writing needs within the Catholic Church. Harness the power of AI and share your spiritual wisdom with the world.",
  label: "Blog Post",
  placeholderText: "Enter your blog's main topic…",
  buttonText: "Write Blog Post",
  aiPlaceholder: "Your blog post will appear here",
});

export default blogWriterFromSpeech;
