import React from "react";
import { colors } from "@mui/material";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import { alpha } from "@mui/material";

const weddingVowsGenerator = {
  title: "Wedding Vows Generator",
  subtitle: "Express your love and commitment with beautiful vows.",
  targetRoute: "/wedding-vows-generator",
  color: colors.red[500],
  bgcolor: alpha(colors.red[500], 0.1),
  icon: <FavoriteOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default weddingVowsGenerator;
