import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { alpha, useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../../../assets/images/ChurchScribe-logo.svg";
import negativeLogo from "../../../../assets/images/ChurchScribe-logo-negative.svg";
import { useNavigate } from "react-router-dom";

import ThemeModeToggler from "../../../../components/ThemeModeToggler";
import { NavItem } from "./components";

import { useRecoilValue, useResetRecoilState } from 'recoil';

import { logout } from '../../../../helpers/api';
import { authState } from '../../../../atoms';


const Topbar = ({ onSidebarOpen, pages, colorInvert = false }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const {
    about: aboutPages,
    tools: toolsPages,
    pricing: pricingPages,
    dashboard: dashboardPages,
    contact: contactPages,
  } = pages;
  const auth = useRecoilValue(authState);
  const resetAuthState = useResetRecoilState(authState);
  const isAuthenticated = auth.isAuthenticated;



  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("authToken") !== null
  );

  const navigate = useNavigate();

  const logoutHandler = async () => {
    try {
      await logout();
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={1}
    >
      <Box
        display={"flex"}
        component="a"
        href="/"
        title="theFront"
        width={{ xs: 100, md: 120 }}
      >
        <Box
          component={"img"}
          src={mode === "light" && !colorInvert ? logo : negativeLogo}
          height={35}
        />
      </Box>
      <Box sx={{ display: { xs: "none", md: "flex" } }} alignItems={"center"}>
        <Box>
          <NavItem
            title={"About"}
            id={"about-pages"}
            items={aboutPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={"Tools"}
            id={"tools-pages"}
            items={toolsPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={"Pricing"}
            id={"pricing-pages"}
            items={pricingPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={"Dashboard"}
            id={"dashboard-pages"}
            items={dashboardPages}
            colorInvert={colorInvert}
          />
        </Box>

        <Box marginLeft={4}>
          <NavItem
            title={"Contact"}
            id={"contact-pages"}
            items={contactPages}
            colorInvert={colorInvert}
          />
        </Box>

        <Box marginLeft={4}>
          <ThemeModeToggler />
        </Box>
        {loggedIn ? (
          <Box marginLeft={4}>
            <Button onClick={logoutHandler} variant="contained" color="primary">
              Logout
            </Button>
          </Box>
        ) : (
          <>
            <Box marginLeft={4}>
              <Button
                component={Link}
                href="/register"
                variant="contained"
                color="primary"
              >
                Register
              </Button>
            </Box>
            <Box marginLeft={4}>
              <Button
                component={Link}
                href="/login"
                variant="contained"
                color="primary"
              >
                Login
              </Button>
            </Box>
          </>
        )}
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" } }} alignItems={"center"}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={"outlined"}
          sx={{
            borderRadius: 2,
            minWidth: "auto",
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;
