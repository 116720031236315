import React from "react";
import { colors } from "@mui/material";
import FestivalOutlinedIcon from "@mui/icons-material/FestivalOutlined";
import { alpha } from "@mui/material";

const youthMinistryEventPlanner = {
  title: "Youth Ministry Event Planner",
  subtitle: "Create engaging events for your youth ministry.",
  targetRoute: "/youth-ministry-event-planner",
  color: colors.deepOrange[700],
  bgcolor: alpha(colors.deepOrange[700], 0.1),
  icon: <FestivalOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default youthMinistryEventPlanner;
