// //client/src/theme/index.js

// import { responsiveFontSizes } from "@mui/material";
// import { createTheme } from "@mui/material/styles";
// import shadows from "./shadows";
// import { light, dark } from "./palette";

// const getTheme = (mode, themeToggler) =>
//   responsiveFontSizes(
//     createTheme({
//       palette: mode === "light" ? light : dark,
//       shadows: shadows(mode),
//       typography: {
//         fontFamily: '"Inter", sans-serif',
//         button: {
//           textTransform: "none",
//           fontWeight: "medium",
//         },
//       },
//       zIndex: {
//         appBar: 1200,
//         drawer: 1300,
//       },
//       components: {
//         MuiButton: {
//           styleOverrides: {
//             root: {
//               fontWeight: 400,
//               borderRadius: 5,
//               paddingTop: 10,
//               paddingBottom: 10,
//             },
//             containedSecondary: mode === "light" ? { color: "white" } : {},
//           },
//         },
//         MuiInputBase: {
//           styleOverrides: {
//             root: {
//               borderRadius: 5,
//             },
//           },
//         },
//         MuiOutlinedInput: {
//           styleOverrides: {
//             root: {
//               borderRadius: 5,
//             },
//             input: {
//               borderRadius: 5,
//             },
//           },
//         },
//         MuiCard: {
//           styleOverrides: {
//             root: {
//               borderRadius: 8,
//             },
//           },
//         },
//       },
//       themeToggler,
//     })
//   );

// export default getTheme;



// theme/index.js
import { responsiveFontSizes } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import shadows from "./shadows";
import { light, dark } from "./palette";

const getTheme = (mode, themeToggler) =>
  responsiveFontSizes(
    createTheme({
      palette: mode === "light" ? light : dark,
      shadows: shadows(mode),
      typography: {
        // System fonts for UI elements
        fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
        // Charter for content areas
        h1: {
          fontFamily: 'Charter, serif',
          fontWeight: 500,
          letterSpacing: '-0.02em',
        },
        h2: {
          fontFamily: 'Charter, serif',
          fontWeight: 500,
          letterSpacing: '-0.02em',
        },
        h3: {
          fontFamily: 'Charter, serif',
          fontWeight: 500,
          letterSpacing: '-0.02em',
        },
        h4: {
          fontFamily: 'Charter, serif',
          fontWeight: 500,
          letterSpacing: '-0.015em',
        },
        h5: {
          fontFamily: 'Charter, serif',
          fontWeight: 500,
          letterSpacing: '-0.015em',
        },
        h6: {
          fontFamily: 'Charter, serif',
          fontWeight: 500,
          letterSpacing: '-0.015em',
        },
        body1: {
          fontFamily: 'Charter, serif',
          fontSize: '1rem',
          lineHeight: 1.6,
          letterSpacing: '-0.01em',
        },
        body2: {
          fontFamily: 'Charter, serif',
          letterSpacing: '-0.01em',
        },
        // System fonts for UI elements
        button: {
          fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif`,
          textTransform: "none",
          fontWeight: 400,
          letterSpacing: '-0.01em',
        },
        // Monospace for code
        code: {
          fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
        }
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              fontWeight: 500,
              borderRadius: 8,
              paddingTop: 12,
              paddingBottom: 12,
              paddingLeft: 24,
              paddingRight: 24,
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none',
              },
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              borderRadius: 8,
              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                sans-serif`,
            },
          },
        },
        MuiTypography: {
          defaultProps: {
            variantMapping: {
              code: 'code'
            }
          }
        }
      },
      themeToggler,
    })
  );

export default getTheme;