import createBlogFromSpeechTemplate from "../BlogFromSpeechTemplate";

const bulletPointsFromSpeech = createBlogFromSpeechTemplate({
  name: "bulletpointsfromspeech",
  endpoint: "/api/openai/bullet-points-from-speech",
  aiText: "bulletPointsFromSpeech",
  aiTextHandler: "bulletPointsFromSpeechHandler",
  aiTextPlainText: "bulletPointsFromSpeechPlainText",
  title: "Bullet Points From Speech",
  subText:
    "Transform lengthy sermons or spiritual discussions into easy-to-read bullet points. Perfect for congregation members who want to quickly grasp the main themes and points.",
  label: "Bullet Points",
  placeholderText: "Enter your blog's main topic…",
  buttonText: "List Bullet Points",
  aiPlaceholder: "Bullet points will appear here",
});

export default bulletPointsFromSpeech;
