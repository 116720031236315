import React from 'react';
import { Grid, Typography, Switch, Box } from '@mui/material';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import DatabaseIcon from '@mui/icons-material/StorageOutlined';
import * as colors from '@mui/material/colors';

const ControlRowKnowledgeOnly = ({
  modelType,
  setModelType,
  churchType,
  handleChurchTypeChange,
  useKnowledgeBase,
  setUseKnowledgeBase
}) => {
  return (
    <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
      {/* Model Selection */}
      <Grid item>
        <ToggleButtonGroup
          value={modelType}
          exclusive
          onChange={(e, newValue) => {
            if (newValue !== null) {
              setModelType(newValue);
            }
          }}
          size="small"
          sx={{
            height: '40px',
            '& .MuiToggleButton-root': {
              px: 2,
              border: '1px solid',
              borderColor: 'divider',
              transition: 'all 0.2s ease',
              '&.Mui-selected': {
                backgroundColor: 'transparent',
                borderColor: 'primary.main',
                color: 'primary.main',
                fontWeight: 500,
              }
            }
          }}
        >
          <ToggleButton value="gpt" aria-label="GPT-4">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <SmartToyOutlinedIcon sx={{ fontSize: 20 }} />
              <Typography variant="body2">GPT</Typography>
            </Box>
          </ToggleButton>
          <ToggleButton value="anthropic" aria-label="Claude">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <AutoAwesomeOutlinedIcon sx={{ fontSize: 20 }} />
              <Typography variant="body2">Claude</Typography>
            </Box>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      {/* Divider */}
      <Grid item>
        <Box sx={{ borderLeft: 1, borderColor: 'divider', height: 24, mx: 1 }} />
      </Grid>

      {/* Knowledge Base Toggle */}
      <Grid item>
        <ToggleButton
          value="knowledge"
          selected={useKnowledgeBase}
          onChange={() => setUseKnowledgeBase(!useKnowledgeBase)}
          size="small"
          sx={{
            height: '40px',
            px: 2,
            border: '1px solid',
            borderColor: 'divider',
            '&.Mui-selected': {
              backgroundColor: 'transparent',
              borderColor: 'primary.main',
              color: 'primary.main',
              fontWeight: 500,
            }
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <DatabaseIcon sx={{ fontSize: 20 }} />
            <Typography variant="body2">Knowledge Base</Typography>
          </Box>
        </ToggleButton>
      </Grid>

      {/* Divider */}
      <Grid item>
        <Box sx={{ borderLeft: 1, borderColor: 'divider', height: 24, mx: 1 }} />
      </Grid>

      {/* Church Type Toggle */}
      <Grid item sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Switch
          checked={churchType === "Protestant"}
          onChange={handleChurchTypeChange}
          color="default"
          size="small"
          sx={{
            "& .MuiSwitch-switchBase": {
              color: churchType === "Catholic" ? colors.purple[700] : "",
            },
            "& .MuiSwitch-switchBase.Mui-checked": {
              color: churchType === "Protestant" ? colors.pink[700] : "",
            },
            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
              backgroundColor: churchType === "Protestant" ? colors.pink[100] : "",
            },
            "& .MuiSwitch-track": {
              backgroundColor: churchType === "Catholic" ? colors.purple[100] : "",
            },
          }}
        />
        <Typography variant="body2">{churchType}</Typography>
      </Grid>
    </Grid>
  );
};

export default ControlRowKnowledgeOnly;