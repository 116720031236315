import createNoKeyOptLangKnowToneTemplate from "../NoKeyOptLangKnowToneTemplate";

const marriageRetreatPlanner = createNoKeyOptLangKnowToneTemplate({
  name: "marriageretreatplanner",
  endpoint: "/api/openai/marriage-retreat-planner",
  aiText: "marriageRetreatPlanner",
  aiTextHandler: "marriageRetreatPlannerHandler",
  aiTextPlainText: "marriageRetreatPlannerPlainText",
  title: "Marriage Retreat Planner",
  subText:
    "Plan the perfect marriage retreat. Input your desired activities and themes along with retreat duration, and get a comprehensive retreat schedule. For example, “communication in marriage, 3 days.”",
  label: "Marriage Retreat Plan",
  placeholderText: "Enter desired activities, themes and duration…",
  buttonText: "Create Schedule",
  aiPlaceholder: "Marriage retreat plan will be generated here",
});

export default marriageRetreatPlanner;
