import createNoKeyOptChapLangToneTemplate from "../NoKeyOptChapLangToneTemplate";

const dailyDevotionsPlanner = createNoKeyOptChapLangToneTemplate({
  name: "dailydevotionsplanner",
  endpoint: "/api/openai/daily-devotions-planner",
  aiText: "dailyDevotionsPlanner",
  aiTextHandler: "dailyDevotionsPlannerHandler",
  aiTextPlainText: "dailyDevotionsPlannerPlainText",
  title: "Daily Devotions Planner",
  subText:
    "Keep your prayer life well-organized with our Daily Prayer Schedule Maker. Input your routine, and get a tailored prayer schedule.",
  label: "Your Daily Prayer Schedule",
  placeholderText: "Input your daily routine…",
  buttonText: "Create Prayer Schedule",
  aiPlaceholder: "Your daily prayer schedule will appear here",
});

export default dailyDevotionsPlanner;
