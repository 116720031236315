import createNoKeyOptChapLangToneTemplate from "../NoKeyOptChapLangToneTemplate";

const couplesDevotionalPlanner = createNoKeyOptChapLangToneTemplate({
  name: "couplesdevotionalplanner",
  endpoint: "/api/openai/couples-devotional-planner",
  aiText: "couplesDevotionalPlanner",
  aiTextHandler: "couplesDevotionalPlannerHandler",
  aiTextPlainText: "couplesDevotionalPlannerPlainText",
  title: "Couple's Devotional Planner",
  subText:
    "Develop a personal devotional guide for couples. Enter your chosen themes and preferred duration, for example, “forgiveness in a marriage, 3 weeks”, and receive a tailored devotional plan.",
  label: "Couple's Devotional Plan",
  placeholderText: "Enter desired theme and duration…",
  buttonText: "Generate Plan",
  aiPlaceholder: "Couple's devotional plan will appear here",
});

export default couplesDevotionalPlanner;
