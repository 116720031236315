import React from "react";
import { colors } from "@mui/material";
import FormatQuoteOutlinedIcon from "@mui/icons-material/FormatQuoteOutlined";
import { alpha } from "@mui/material";

const quoteGenerator = {
  title: "Quote Generator",
  subtitle: "Generate the perfect, faith-based quote for any occasion.",
  targetRoute: "/quote-generator",
  color: colors.red[400],
  bgcolor: alpha(colors.red[400], 0.1),
  icon: <FormatQuoteOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default quoteGenerator;
