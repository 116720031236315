import instance from "./axiosInstance";
import { saveAs } from "file-saver";

const downloadPdf = async (contentRef) => {
  // Check if contentRef and contentRef.current exist
  if (!contentRef || !contentRef.current) {
    alert("No content to download");
    return;
  }

  const logoUrl =
    "https://res.cloudinary.com/dxdpahm3o/image/upload/v1687344423/WisdomScribe-logo_hlwb8s.svg";
  const siteUrl = "https://wisdomscribe.ai";

  let footer = `
    <div class="footer">
      <a href="${siteUrl}">
        <img class="logo" src="${logoUrl}" alt="Wisdom Scribe">
      </a>
    </div>
  `;

  let content = `
  <html>
  <head>
    <link href="https://fonts.googleapis.com/css2?family=PT+Serif:wght@500;700&display=swap" rel="stylesheet">
    <style>
      body {
        font-family: 'PT Serif', serif;
        color: #1e2022;
        font-size: 12px;
        line-height: 1.6;
        position: relative;
      }
      .container {
        margin: 30px;
      }
      .logo {
        width: 150px;
        height: 50px;
      }
      .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
      }
      h1, h2, h3, h4, h5, h6 {
        font-weight: 600;
      }
    </style>
  </head>
  <body>
    <div class="container">
      ${contentRef.current.innerHTML}
    </div>
    ${footer}
  </body>
</html>
  `;

  const result = await instance.post(
    "http://localhost:3030/api/pdf/generate-pdf",
    { html: content },
    { responseType: "blob" }
  );

  const pdfBlob = new Blob([result.data], { type: "application/pdf" });

  saveAs(pdfBlob, "eph320.pdf");
};

export default downloadPdf;
