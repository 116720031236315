import React from "react";
import { Select, MenuItem } from "@mui/material";

const CatholicBibleSelect = ({ bibleVersion, setBibleVersion }) => {
  const handleChange = (event) => {
    setBibleVersion(event);
  };

  const bibleVersions = [
    "New American Bible (NAB)",
    "Revised Standard Version Catholic Edition (RSVCE)",
    "New Revised Standard Version Catholic Edition (NRSVCE)",
    "Douay-Rheims Bible (DRB)",
    "Jerusalem Bible (JB)",
    "New Jerusalem Bible (NJB)",
    "Good News Translation Catholic Edition (GNTCE)",
    "Contemporary English Version Catholic Edition (CEVCE)",
    "The Catholic Living Bible (CLB)",
  ];

  return (
    <Select
      displayEmpty
      renderValue={(value) =>
        value !== "" ? value : "New American Bible (NAB)"
      }
      fullWidth
      value={bibleVersion}
      onChange={handleChange}
      sx={{ mb: 1.5 }}
    >
      <MenuItem value="">
        <em>Select a Bible version</em>
      </MenuItem>
      {bibleVersions.map((version) => (
        <MenuItem key={version} value={version}>
          {version}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CatholicBibleSelect;
