import React from "react";
import { colors } from "@mui/material";
import BedroomChildOutlinedIcon from "@mui/icons-material/BedroomChildOutlined";
import { alpha } from "@mui/material";

const narrateToChild = {
  title: "Narrate To A Child",
  subtitle:
    "Transform complex scriptures or topics into child-friendly stories seamlessly.",
  targetRoute: "/narrate-to-child",
  color: colors.deepOrange[500],
  bgcolor: alpha(colors.deepOrange[500], 0.1),
  icon: <BedroomChildOutlinedIcon fontSize="medium" />,
  tags: [
    "narrate",
    "child",
    "children",
    "story",
    "stories",
    "narrate to child",
    "narrate to children",
    "bible stories",
    "bible story",
  ],
};

export default narrateToChild;
