import React from "react";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Container from "../../Container";
import Form from "./components/Form/Form";
import RegisterImage from "../../../assets/images/RegisterImage.svg";

const RegisterScreen = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box
      position={"relative"}
      minHeight={"calc(100vh - 247px)"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      height={1}
    >
      <Container>
        <Grid container spacing={6}>
          <Grid
            item
            container
            alignItems={"center"}
            justifyContent={"center"}
            xs={12}
            md={6}
          >
            <Box>
              <Form />
            </Box>
          </Grid>
          {isMd ? (
            <Grid item container justifyContent={"center"} xs={12} md={6}>
              <Box height={1} width={1} maxWidth={500}>
                <Box
                  component={"img"}
                  src={RegisterImage}
                  width={1}
                  height={1}
                  sx={{
                    filter:
                      theme.palette.mode === "dark"
                        ? "brightness(0.8)"
                        : "none",
                  }}
                />
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </Container>
    </Box>
  );
};

export default RegisterScreen;
