import React from "react";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

const UploadingDialog = ({ open }) => {
  const theme = useTheme();

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <Paper
        sx={{
          padding: theme.spacing(2),
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <DialogTitle>
          <Typography variant="h6" component="div">
            Uploading Images
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Your images are being uploaded, text is being extracted, and saved
            in your syllabus. Please wait.
          </Typography>
        </DialogContent>
      </Paper>
    </Dialog>
  );
};

export default UploadingDialog;
