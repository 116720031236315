import React from "react";
import { colors } from "@mui/material";
import VerticalSplitOutlinedIcon from "@mui/icons-material/VerticalSplitOutlined";
import { alpha } from "@mui/material";

const analogyMaker = {
  title: "Biblical Analogy Maker",
  subtitle: "Simplify complex scripture with insightful analogies.",
  targetRoute: "/analogy-maker",
  color: colors.blueGrey[300],
  bgcolor: alpha(colors.blueGrey[300], 0.1),
  icon: <VerticalSplitOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default analogyMaker;
