import React from "react";
import { colors } from "@mui/material";
import LibraryMusicOutlinedIcon from "@mui/icons-material/LibraryMusicOutlined";
import { alpha } from "@mui/material";

const lyricsGenerator = {
  title: "Lyrics Generator",
  subtitle: "Write songs that elevate worship experiences.",
  targetRoute: "/lyrics-generator",
  color: colors.teal[300],
  bgcolor: alpha(colors.teal[300], 0.1),
  icon: <LibraryMusicOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default lyricsGenerator;
