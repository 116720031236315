/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import Container from "../../Container";

import {
  contentPlanner,
  podcastCreator,
  socialMediaContentGenerator,
  youtubeScriptwriter,
  youtubeVideoDescription,
  YoutubeVideoIdeas,
  youtubeVideoTitle,
} from "../cards/SocialMediaTools/index";

const MySocialMediaTools = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const features = [
    contentPlanner,
    podcastCreator,
    socialMediaContentGenerator,
    youtubeScriptwriter,
    youtubeVideoDescription,
    YoutubeVideoIdeas,
    youtubeVideoTitle,
  ];

  const socialMediaToolsInstructions = (
    <Box
      sx={{
        margin: "8px",
        padding: "16px",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: "5px",
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
      }}
    >
      <Typography variant="h6" gutterBottom>
        Personalizing Your Bible Tools
      </Typography>
      <Typography variant="body1" gutterBottom>
        To achieve an experience that truly aligns with your specific business
        requirements, we offer the opportunity to customize our tools by
        uploading your company's knowledge base and defining your preferred tone
        of voice. Here's how you can do it:
        <ul>
          <li>
            Upload your knowledge base: Click the button below, input your
            organization's information, assign it a title, and save it. During
            the tool usage, you can select "Use my knowledge base" and choose
            the appropriate information to integrate into the tool's
            functionality.
          </li>
          <li>
            Define your tone of voice: Click the button below, describe your
            preferred writing style, name it, and save it. This could include
            specific sentence structures or language nuances that echo your
            brand's style of communication. You can then select this tone from a
            dropdown list when using our tools.
          </li>
        </ul>
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: { xs: "center", sm: "flex-start" }, // center on mobile, left-align on larger screens
          alignItems: "center",
          gap: 1, // provides a gap between the buttons
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/knowledge-base")}
        >
          Upload Knowledge Base
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: { xs: 1, sm: 0 }, // Adjust as necessary
          }}
          onClick={() => navigate("/upload-tone")}
        >
          Define Tone of Voice
        </Button>
      </Box>
    </Box>
  );

  // This function will be used to navigate to the tool's route
  const handleToolClick = (targetRoute) => {
    if (localStorage.getItem("authToken")) {
      navigate(targetRoute);
    } else {
      // Redirect to login page if user is not logged in
      navigate("/login");
    }
  };

  return (
    <>
      <Box
        sx={{
          marginTop: {
            xs: "-16px", // value for screens 0px or larger
            sm: "-70px", // value for screens 600px or larger
            md: "-32px", // value for screens 960px or larger
            lg: "-40px", // value for screens 1280px or larger
            xl: "-48px", // value for screens 1920px or larger
          },
          backgroundImage: `linear-gradient(to bottom, ${alpha(
            theme.palette.background.paper,
            0
          )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
          backgroundRepeat: "repeat-x",
          position: "relative",
        }}
      >
        <Container>
          {socialMediaToolsInstructions}
          <Grid container spacing={4} sx={{ marginTop: "32px" }}>
            {features.map((item, i) => (
              <Grid item xs={12} sm={6} md={4} key={i}>
                <IconButton
                  onClick={() => handleToolClick(item.targetRoute)}
                  sx={{
                    width: "100%",
                    height: "100%",
                    padding: 0,
                    borderRadius: 2,
                    border: "1px solid transparent",
                    transition: "border-color 0.3s, box-shadow 0.3s",
                    "&:hover": {
                      borderColor: theme.palette.action.hover,
                      boxShadow: theme.shadows[4],
                    },
                  }}
                >
                  <Box
                    component={Card}
                    padding={4}
                    borderRadius={2}
                    width={1}
                    height={1}
                    data-aos={"fade-up"}
                    data-aos-delay={i * 100}
                  >
                    <Box
                      component={Avatar}
                      width={60}
                      height={60}
                      marginBottom={2}
                      bgcolor={item.bgcolor}
                      color={item.color}
                      variant={"rounded"}
                      borderRadius={2}
                    >
                      {item.icon}
                    </Box>
                    <Typography
                      variant={"h6"}
                      gutterBottom
                      sx={{ fontWeight: 500 }}
                    >
                      {item.title}
                    </Typography>
                    <Typography color="text.secondary">
                      {item.subtitle}
                    </Typography>
                  </Box>
                </IconButton>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default MySocialMediaTools;
