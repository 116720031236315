import React from "react";
import { colors } from "@mui/material";
import AudioFileOutlinedIcon from "@mui/icons-material/AudioFileOutlined";
import { alpha } from "@mui/material";

const biblicalSongwriter = {
  title: "Biblical Songwriter",
  subtitle: "Transform scripture into meaningful worship songs.",
  targetRoute: "/biblical-songwriter",
  color: colors.pink[500],
  bgcolor: alpha(colors.pink[500], 0.1),
  icon: <AudioFileOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default biblicalSongwriter;
