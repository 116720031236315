import createNoKeyOptChapLangToneTemplate from "../NoKeyOptChapLangToneTemplate";

const bibleStudyGuide = createNoKeyOptChapLangToneTemplate({
  name: "biblestudyguide",
  endpoint: "/api/openai/bible-study-guide",
  aiText: "bibleStudyGuide",
  aiTextHandler: "bibleStudyGuideHandler",
  aiTextPlainText: "bibleStudyGuidePlainText",
  title: "Bible Study Guide",
  subText:
    "Bible Study Guide enriches your understanding of scriptures. Type in a book like 'Romans', or a theme such as 'Mercy', and receive a comprehensive, thought-provoking study guide within seconds!",
  label: "Study Guide",
  placeholderText: "Enter Bible study theme...",
  buttonText: "Compose Guide",
  aiPlaceholder: "Your Bible study guide will be displayed here",
});

export default bibleStudyGuide;
