// src/atoms.js
import { atom } from 'recoil';

export const authState = atom({
  key: 'authState',
  default: {
    user: null,
    accessToken: null,
    isAuthenticated: false,
    isLoading: true,
    emailVerified: null,
  },
});