import React from "react";
import { colors } from "@mui/material";
import ChurchOutlinedIcon from "@mui/icons-material/ChurchOutlined";
import { alpha } from "@mui/material";

const catechismReference = {
  title: "Catechism Cross Referencer",
  subtitle: "Check what does the CCC says about any topic.",
  targetRoute: "/catechism-reference",
  color: colors.amber[600],
  bgcolor: alpha(colors.amber[600], 0.1),
  icon: <ChurchOutlinedIcon fontSize="medium" />,
  tags: "",
};

export default catechismReference;
