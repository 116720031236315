import React from "react";
import { colors } from "@mui/material";
import NotStartedOutlinedIcon from "@mui/icons-material/NotStartedOutlined";
import { alpha } from "@mui/material";

const youtubeVideoTitle = {
  title: "YouTube Title Maker",
  subtitle: "Generate captivating YouTube titles that drive clicks.",
  targetRoute: "/youtube-video-title",
  color: colors.red[600],
  bgcolor: alpha(colors.red[600], 0.1),
  icon: <NotStartedOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default youtubeVideoTitle;
