import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const mock = [
  {
    title: "I. Acceptance of Terms",
    description:
      'By using Church Scribe, an AI Writer service for the Catholic Church (the "Service"), you agree to be bound by the following terms and conditions ("Terms") and our Privacy Policy. If you do not agree with these Terms or the Privacy Policy, you should not use the Service.',
  },
  {
    title: "II. Description of Service",
    description:
      "Church Scribe is designed to assist with the creation of text and documents for the Catholic Church. It is intended to supplement, not replace, human input and discretion.",
  },
  {
    title: "III. User Responsibilities and Rights",
    description:
      'As a user, you are responsible for the content you create using Church Scribe. You agree not to use the Service to create content that is illegal, harmful, threatening, abusive, defamatory, or in violation of any laws. All content created by users ("User Content") using our Service is owned by the user who created it. The user retains all rights and responsibilities for their User Content.',
  },
  {
    title: "IV. Intellectual Property",
    description:
      "Unless otherwise stated, all materials within the Service, including, but not limited to, the design, text, software, graphics, and other files, as well as their selection and arrangement, are the proprietary property of Church Scribe, excluding User Content.",
  },
  {
    title: "V. Limitations of Liability",
    description:
      "Church Scribe and its affiliates, representatives, officers, employees, agents, and successors shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use the Service.",
  },
  {
    title: "VI. Changes to Terms",
    description:
      "We reserve the right to modify these Terms at any time. We will always post the most current version of these Terms on our website. By continuing to use the Service after the changes become effective, you agree to be bound by the revised Terms.",
  },
  {
    title: "VII. Governing Law",
    description:
      "These Terms are governed by the laws of [Your jurisdiction] without regard to its conflict of laws provisions.",
  },
  {
    title: "VIII. Privacy Policy",
    description:
      "We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you as to how we look after your personal data when you use our Service and tell you about your privacy rights and how the law protects you.",
  },
  {
    title: "IX. Contact Information",
    description:
      "If you have any questions about these Terms or our Privacy Policy, please contact us at [Your contact information].",
  },
];
const PrivacySection = ({ title, description }) => {
  return (
    <Box>
      <Typography
        variant={"h6"}
        gutterBottom
        sx={{
          fontWeight: "medium",
        }}
      >
        {title}
      </Typography>
      <Typography component={"p"} color={"text.secondary"}>
        {description}
      </Typography>
    </Box>
  );
};

PrivacySection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const Content = () => {
  return (
    <Box>
      {mock.map((item, i) => (
        <Box key={i} marginBottom={i < mock.length - 1 ? 4 : 0}>
          <PrivacySection {...item} />
        </Box>
      ))}
    </Box>
  );
};

export default Content;
