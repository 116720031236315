import React from "react";
import { Box, Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import emailVerificationImage from "../../assets/images/emailVerification.svg";

const EmailVerification = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      px={1}
      width="100%"
      height="100vh"
      mx="auto"
      position="relative"
      zIndex={2}
    >
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        {isMd ? (
          <Grid item container justifyContent={"center"} xs={12} md={6}>
            <Box height={1} width={1} maxWidth={500}>
              <Box
                component={"img"}
                src={emailVerificationImage}
                width={1}
                height={1}
                sx={{
                  filter:
                    theme.palette.mode === "dark" ? "brightness(0.8)" : "none",
                }}
              />
            </Box>
          </Grid>
        ) : null}
        <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
          <Box mt={3} mb={1} textAlign="center">
            <Typography variant="h4" color="text.primary">
              Email Verification
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Thank you for registering! Please check your email and click on
              the verification link we've sent to complete your registration.
              Remember to check your{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: theme.palette.primary.main,
                }}
              >
                spam folder
              </span>{" "}
              if you can't find it in your inbox.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmailVerification;
