// import { Route as ElementRoute, Navigate } from "react-router-dom";
// import { useEffect, useState } from "react";
// import { checkSubscription } from "../../helpers/subscriptionHelper";
// import SplashScreen from "../../components/splashScreen";

// const ProtectedRoute = ({ component: Component }) => {
//   const [isLoading, setIsLoading] = useState(true);
//   const [isAuthorized, setIsAuthorized] = useState(false);

//   useEffect(() => {
//     const fetchSubscriptionStatus = async () => {
//       const subscription = await checkSubscription();
//       setIsAuthorized(
//         subscription === "Starter" ||
//           subscription === "cs-pro-monthly" ||
//           subscription === "Pro Plan - Monthly" ||
//           subscription === "cs-pro-annual" ||
//           subscription === "Pro Plan - Annual" ||
//           subscription === "cs-enter-monthly" ||
//           subscription === "Enterprise Plan - Monthly" ||
//           subscription === "cs-enter-annual" ||
//           subscription === "Enterprise Plan - Annual" ||
//           subscription === "Testing Church"
//       );
//       setIsLoading(false);
//     };
//     fetchSubscriptionStatus();
//   }, []);

//   if (isLoading) {
//     return <SplashScreen />;
//   }

//   return isAuthorized ? <Component /> : <Navigate to="/pricing" replace />;
// };

// export default ProtectedRoute;


// src/components/ProtectedRoute/index.js
import { Navigate } from "react-router-dom";
import { useRecoilValue } from 'recoil';
import { authState } from '../../atoms';
import SplashScreen from '../splashScreen';

const ProtectedRoute = ({ component: Component }) => {
  const auth = useRecoilValue(authState);

  if (auth.isLoading) {
    return <SplashScreen />;
  }

  if (!auth.isAuthenticated || !auth.user) {
    return <Navigate to="/login" replace />;
  }

  return <Component />;
};

export default ProtectedRoute;