import React from "react";
import { colors } from "@mui/material";
import LyricsOutlinedIcon from "@mui/icons-material/LyricsOutlined";
import { alpha } from "@mui/material";

const rapCreator = {
  title: "Rap Creator",
  subtitle: "Create faith-inspired rap verses that engage your youth.",
  targetRoute: "/rap-creator",
  color: colors.deepPurple[500],
  bgcolor: alpha(colors.deepPurple[500], 0.1),
  icon: <LyricsOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default rapCreator;
