import createOptLangKnowKeyNoBibleTemplate from "../OptLangKnowKeyNoBibleTemplate";

const youtubeVideoIdeas = createOptLangKnowKeyNoBibleTemplate({
  name: "youtubevideoideas",
  endpoint: "/api/openai/youtube-video-ideas",
  aiText: "youtubeIdeas",
  aiTextHandler: "youtubeVideoIdeasHandler",
  aiTextPlainText: "youtubeVideoIdeasPlainText",
  title: "YouTube Content Ideas",
  subText:
    "Our YouTube Video Ideas Generator is designed to help you create engaging content for your Church's YouTube channel. Simply input a theme or subject, and get a diverse range of video ideas instantly.",
  label: "Church Video Brainstorm",
  placeholderText: "What's the purpose of your video or series?",
  buttonText: "Inspire Me!",
  aiPlaceholder: "Ideas will appear here",
});

export default youtubeVideoIdeas;
