import createWeddingVows from "../WeddingVowsTemplate";

const weddingVowsGenerator = createWeddingVows({
  name: "weddingvowsgenerator",
  endpoint: "/api/openai/wedding-vows-generator",
  aiText: "weddingVowsGenerator",
  aiTextHandler: "weddingVowsGeneratorHandler",
  aiTextPlainText: "weddingVowsGeneratorPlainText",
  title: "Wedding Vows Generator",
  subText:
    "Create deeply meaningful wedding vows. Enter key themes and sentiments, and receive beautifully crafted vows.",
  label: "Your Wedding Vows",
  placeholderText: "Enter key themes and sentiments…",
  buttonText: "Generate Vows",
  aiPlaceholder: "Your personalized wedding vows will be displayed here",
});

export default weddingVowsGenerator;
