import React from "react";
import { colors } from "@mui/material";
import FamilyRestroomOutlinedIcon from "@mui/icons-material/FamilyRestroomOutlined";
import { alpha } from "@mui/material";

const familyPrayerGenerator = {
  title: "Family Prayer Generator",
  subtitle: "Strengthen family unity with heartfelt prayers.",
  targetRoute: "/family-prayer-generator",
  color: colors.lightBlue[800],
  bgcolor: alpha(colors.lightBlue[800], 0.1),
  icon: <FamilyRestroomOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default familyPrayerGenerator;
