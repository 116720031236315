import React from "react";
import { colors } from "@mui/material";
import WysiwygOutlinedIcon from "@mui/icons-material/WysiwygOutlined";
import { alpha } from "@mui/material";

const websiteContentPlanner = {
  title: "Website Content Planner",
  subtitle: "Organize and strategize your website content for maximum impact.",
  targetRoute: "/website-content-planner",
  color: colors.indigo[200],
  bgcolor: alpha(colors.indigo[200], 0.1),
  icon: <WysiwygOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default websiteContentPlanner;
