import React from "react";
import { colors } from "@mui/material";
import LightbulbCircleOutlinedIcon from "@mui/icons-material/LightbulbCircleOutlined";
import { alpha } from "@mui/material";

const youtubeVideoIdeas = {
  title: "YouTube Video Ideas",
  subtitle:
    "Generate faith-filled, engaging video ideas for your YouTube channel.",
  targetRoute: "/youtube-video-ideas",
  color: colors.red[900],
  bgcolor: alpha(colors.red[900], 0.1),

  icon: <LightbulbCircleOutlinedIcon fontSize="medium" />,
  tags: [
    "YouTube",
    "video",
    "ideas",
    "video ideas",
    "video idea",
    "video ideas generator",
    "video idea generator",
  ],
};

export default youtubeVideoIdeas;
