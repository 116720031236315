import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';

const ModelControl = ({
  modelType,
  setModelType,
}) => {
  return (
    <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
      <Grid item>
        <ToggleButtonGroup
          value={modelType}
          exclusive
          onChange={(e, newValue) => {
            if (newValue !== null) {
              setModelType(newValue);
            }
          }}
          size="small"
          sx={{
            height: '40px',
            '& .MuiToggleButton-root': {
              px: 2,
              border: '1px solid',
              borderColor: 'divider',
              transition: 'all 0.2s ease',
              '&.Mui-selected': {
                backgroundColor: 'transparent',
                borderColor: 'primary.main',
                color: 'primary.main',
                fontWeight: 500,
              }
            }
          }}
        >
          <ToggleButton value="gpt" aria-label="GPT-4">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <SmartToyOutlinedIcon sx={{ fontSize: 20 }} />
              <Typography variant="body2">GPT</Typography>
            </Box>
          </ToggleButton>
          <ToggleButton value="anthropic" aria-label="Claude">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <AutoAwesomeOutlinedIcon sx={{ fontSize: 20 }} />
              <Typography variant="body2">Claude</Typography>
            </Box>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );
};

export default ModelControl;