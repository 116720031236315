import React from "react";
import { colors } from "@mui/material";
import VideoChatOutlinedIcon from "@mui/icons-material/VideoChatOutlined";
import { alpha } from "@mui/material";

const youtubeVideoDescription = {
  title: "SEO-friendly YouTube Video Description",
  subtitle:
    "Craft compelling YouTube descriptions that amplify viewer engagement.",
  targetRoute: "/youtube-video-description",
  color: colors.red[700],
  bgcolor: alpha(colors.red[700], 0.1),
  icon: <VideoChatOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default youtubeVideoDescription;
