import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const CollapsibleItem = ({ item, isOpen = false, onToggle }) => {
  const [open, setOpen] = React.useState(isOpen);
  const navigate = useNavigate();

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleGroupClick = () => {
    setOpen(!open);
    if (onToggle) {
      onToggle();
    }
  };

  const handleItemClick = (href) => {
    navigate(href);
  };

  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        paddingX={2}
        sx={{ cursor: "pointer", color: "text.secondary" }}
        onClick={
          item.href ? () => handleItemClick(item.href) : handleGroupClick
        }
      >
        <Typography
          variant="caption"
          color={"text.secondary"}
          sx={{
            fontWeight: 700,
            textTransform: "uppercase",
            marginBottom: 1,
            display: "block",
          }}
        >
          {item.groupTitle}
        </Typography>
        {!item.href && (open ? <ExpandLess /> : <ExpandMore />)}
      </Box>
      <Collapse in={open && !item.href} timeout="auto" unmountOnExit>
        {item.pages.map((p, i) => (
          <Box marginBottom={1 / 2} key={i}>
            <Button
              component={"a"}
              href={p.href}
              fullWidth
              sx={{
                justifyContent: "flex-start",
                color: "text.primary",
                borderRadius: 0,
                paddingX: 2,
              }}
              startIcon={p.icon || null}
              onClick={(event) => {
                event.stopPropagation();
                handleItemClick(p.href);
              }}
            >
              {p.title}
            </Button>
          </Box>
        ))}
      </Collapse>
    </Box>
  );
};

CollapsibleItem.propTypes = {
  item: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default CollapsibleItem;
