import React from "react";
import { colors } from "@mui/material";
import LocalActivityOutlinedIcon from "@mui/icons-material/LocalActivityOutlined";
import { alpha } from "@mui/material";

const parishEventPlanner = {
  title: "Parish Event Planner",
  subtitle: "Streamline your parish events with effective planning.",
  targetRoute: "/parish-event-planner",
  color: colors.deepPurple[300],
  bgcolor: alpha(colors.deepPurple[300], 0.1),
  icon: <LocalActivityOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default parishEventPlanner;
