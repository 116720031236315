import createBlogFromSpeechTemplate from "../BlogFromSpeechTemplate";

const summaryFromSpeech = createBlogFromSpeechTemplate({
  name: "summaryfromspeech",
  endpoint: "/api/openai/summary-from-speech",
  aiText: "summaryFromSpeech",
  aiTextHandler: "summaryFromSpeechHandler",
  aiTextPlainText: "summaryFromSpeechPlainText",
  title: "Summary From Speech",
  subText:
    "This tool distills long sermons or spiritual talks into concise summaries, capturing the essence and key takeaways. Ideal for quick reviews or sharing the core message.",
  label: "Summary",
  placeholderText: "Enter your blog's main topic…",
  buttonText: "Summarize",
  aiPlaceholder: "Your summary will appear here",
});

export default summaryFromSpeech;
