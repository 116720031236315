import React from "react";
import { colors } from "@mui/material";
import GamepadOutlinedIcon from "@mui/icons-material/GamepadOutlined";
import { alpha } from "@mui/material";

const bibleGamesForFamilies = {
  title: "Bible Games for Families",
  subtitle: "Engage your family in fun, faith-based games.",
  targetRoute: "/bible-games-for-families",
  color: colors.cyan[900],
  bgcolor: alpha(colors.cyan[900], 0.1),
  icon: <GamepadOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default bibleGamesForFamilies;
