import React from "react";
import { colors } from "@mui/material";
import LocalPoliceOutlinedIcon from "@mui/icons-material/LocalPoliceOutlined";

import { alpha } from "@mui/material";

const canonLawReference = {
  title: "Canon Law Reference Tool",
  subtitle: "Explore Canon Law with ease by simply inputting a topic.",
  targetRoute: "/canon-law-reference",
  color: colors.deepPurple[700],
  bgcolor: alpha(colors.deepPurple[700], 0.1),
  icon: <LocalPoliceOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default canonLawReference;
