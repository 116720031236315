import createOptLangKeyTemplate from "../OptLangKeyTemplate";

const relatedTopicsResearch = createOptLangKeyTemplate({
  name: "relatedtopicsresearch",
  endpoint: "/api/openai/related-topics-research",
  aiText: "relatedTopicsResearch",
  aiTextHandler: "relatedTopicsResearchHandler",
  aiTextPlainText: "relatedTopicsResearchPlainText",
  title: "Related Topic Finder",
  subText:
    "This tool helps you delve deeper into your subject. Input your main topic and receive a list of relevant topics to enhance your writing.",
  label: "Related Topics",
  placeholderText: "Enter your main topic…",
  buttonText: "Find Topics",
  aiPlaceholder: "Generated topics will be displayed here",
});

export default relatedTopicsResearch;
