import creatOptTopicLang from "../OptTopicLangTemplate";

const keywordResearch = creatOptTopicLang({
  name: "keywordresearch",
  endpoint: "/api/openai/keyword-research",
  aiText: "keywordResearch",
  aiTextHandler: "keywordResearchPlannerHandler",
  aiTextPlainText: "keywordResearchPlannerPlainText",
  title: "Keyword Research Tool",
  subText:
    "Boost your website's ranking with SEO Keyword Explorer. Enter your main topic and get 10 keyword ideas that can increase your website's visibility.",
  label: "Keywords",
  placeholderText: "Enter your main topic…",
  buttonText: "Find Keywords",
  aiPlaceholder: "Generated keywords will be displayed here",
});

export default keywordResearch;
