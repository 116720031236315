import React, { useState, useEffect, useCallback } from "react";
import instance from "../../helpers/axiosInstance";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";

const HistoryTitle = styled(Typography)(({ theme }) => ({
  marginBottom: "0.5rem",
  fontWeight: "bold",
  flexGrow: 1,
}));

const HistoryContent = styled(Typography)(({ theme }) => ({
  whiteSpace: "pre-line",
}));

const HistoryDetailCardContent = styled(CardContent)(({ theme }) => ({
  p: 2,
  display: "flex",
  flexDirection: "column",
}));

const HistoryDetailBox = styled(Box)(({ theme }) => ({
  position: "relative",
}));

const CopyButton = styled(IconButton)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& svg": {
    stroke: "currentColor",
    fill: "none",
    strokeWidth: 2,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    height: "1em",
    width: "1em",
  },
  "& span": {
    marginLeft: "0.5rem",
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
  "&:active": {
    boxShadow: "none", // Add this
  },
}));

const HistoryDetailScreen = () => {
  const [historyItem, setHistoryItem] = useState(null);
  const { id } = useParams();
  const [copied, setCopied] = useState(false);

  const fetchHistoryItem = useCallback(async () => {
    try {
      // Fetch access token

      const authToken = localStorage.getItem("authToken");

      // Fetch the history item by ID
      const response = await instance.get(`/api/auth/history/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });

      setHistoryItem(response.data);
    } catch (error) {
      console.error("Error fetching history item:", error);
    }
  }, [id]);

  useEffect(() => {
    fetchHistoryItem();
  }, [fetchHistoryItem]);

  const handleCopyContent = () => {
    if (historyItem && historyItem.content) {
      try {
        const textContent = historyItem.content
          .replace(/<br\/>/g, "\n")
          .replace(/<[^>]+>/g, "");
        navigator.clipboard.writeText("\n" + textContent + "\n");
        setCopied(true);
        setTimeout(() => setCopied(false), 5000);
      } catch (error) {
        console.error("Error copying content:", error);
      }
    }
  };

  return (
    <Container maxWidth="md">
      {historyItem ? (
        <HistoryDetailBox sx={{ p: 4 }}>
          <Card>
            <HistoryDetailCardContent>
              <Box display="flex" alignItems="center">
                <HistoryTitle variant="h6">
                  {new Date(historyItem.createdAt).toLocaleString()}
                </HistoryTitle>
                <CopyButton onClick={handleCopyContent} disableRipple>
                  {copied ? (
                    <>
                      <svg viewBox="0 0 24 24" height="24" width="24">
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                      <Typography variant="body2" component="span">
                        Copied
                      </Typography>
                    </>
                  ) : (
                    <>
                      <svg viewBox="0 0 24 24" height="24" width="24">
                        <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                        <rect
                          x="8"
                          y="2"
                          width="8"
                          height="4"
                          rx="1"
                          ry="1"
                        ></rect>
                      </svg>
                      <Typography variant="body2" component="span">
                        Copy text
                      </Typography>
                    </>
                  )}
                </CopyButton>
              </Box>
              <HistoryContent
                variant="body1"
                dangerouslySetInnerHTML={{ __html: historyItem.content }}
              />
            </HistoryDetailCardContent>
          </Card>
        </HistoryDetailBox>
      ) : (
        <Box sx={{ border: "1px solid #ccc", p: 2 }}>
          <Typography
            variant="h5"
            component="h2"
            textAlign="center"
            gutterBottom
          >
            Loading...
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default HistoryDetailScreen;
