import createOptLangKnowKeyNoBibleTemplate from "../OptLangKnowKeyNoBibleTemplate";

const blogContentPlanner = createOptLangKnowKeyNoBibleTemplate({
  name: "blogcontentplanner",
  endpoint: "/api/openai/blog-content-planner",
  aiText: "blogContentPlanner",
  aiTextHandler: "blogContentPlannerHandler",
  aiTextPlainText: "blogContentPlannerPlainText",
  title: "Blog Content Planner",
  subText:
    "Stay on schedule with Content Planner. Enter your main topic, and get a blog content publishing calendar filled with 10 captivating content ideas.",
  label: "Your Blog Content Calendar",
  placeholderText: "What is your content focus?",
  buttonText: "Plan my content",
  aiPlaceholder: "See your blog content calendar here",
});

export default blogContentPlanner;
