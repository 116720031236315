import React from "react";
import { Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const KnowledgeBase = ({
  useKnowledgeBase,
  organisationName,
  selectedOrganisation,
  setSelectedOrganisation,
  itemName,
  selectedItem,
  setSelectedItem,
}) => {
  return (
    <>
      {useKnowledgeBase ? (
        <>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth sx={{ mb: 1.5 }}>
              <InputLabel id="organisation-select-label">
                Organisation
              </InputLabel>
              <Select
                labelId="organisation-select-label"
                id="organisation-select"
                value={selectedOrganisation}
                onChange={(e) => setSelectedOrganisation(e.target.value)}
                label="Organisation"
              >
                {organisationName.map((name, index) => (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {selectedOrganisation && (
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth sx={{ mb: 1.5 }}>
                <InputLabel id="item-select-label">Item</InputLabel>
                <Select
                  labelId="item-select-label"
                  id="item-select"
                  value={selectedItem}
                  onChange={(e) => setSelectedItem(e.target.value)}
                  label="Item"
                >
                  {itemName.map((name, index) => (
                    <MenuItem key={index} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </>
      ) : (
        <Grid item xs={12} style={{ height: "auto" }} />
      )}
    </>
  );
};

export default KnowledgeBase;
