import React, { useState, useEffect } from "react";
import instance from "../../helpers/axiosInstance";
import {
  Box,
  Container,
  Typography,
  TextField,
  FormControl,
  Grid,
  Pagination,
  styled,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import filterAndRenderHistory from "./filterAndRenderHistory";

const UserHistoryScreen = (props) => {
  const [userHistory, setUserHistory] = useState([]);
  const [search, setSearch] = useState("");
  const [contentTypeFilter, setContentTypeFilter] = useState([]);
  const [dateRangeFilter, setDateRangeFilter] = useState("");
  const [page, setPage] = useState(1);

  const itemsPerPage = 10;

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleContentTypeFilterChange = (event) => {
    setContentTypeFilter(event.target.value);
  };

  const handleDateRangeFilterChange = (event) => {
    setDateRangeFilter(event.target.value);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const fetchAccessToken = async () => {
    try {
      const accessTokenResponse = await instance.get(
        "/api/auth/refresh-token",
        {
          withCredentials: true,
        }
      );

      return accessTokenResponse.data;
    } catch (error) {
      console.error("Failed to get access token. Please log in.");
      return null;
    }
  };

  // Fetch user history data on mount
  useEffect(() => {
    const fetchUserHistory = async () => {
      try {
        const authToken = localStorage.getItem("authToken");
        if (!authToken) {
          console.error("Failed to get access token. Please log in.");
          return;
        }

        const response = await instance.get("/api/auth/history", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        });

        setUserHistory(response.data);
      } catch (error) {
        console.error("Error fetching user history:", error);
      }
    };
    fetchUserHistory();
  }, []);

  // Handle click on "View full content" button
  const handleViewFullContent = (historyItemId) => {
    window.open(`/history/${historyItemId}`, "_blank");
  };

  // Render user history items using filterAndRenderHistory function
  const renderedHistory = filterAndRenderHistory(
    userHistory,
    search,
    contentTypeFilter,
    dateRangeFilter,
    handleViewFullContent
  );

  return (
    <Container sx={{ padding: 0 }}>
      <Box mt={4} mb={4} sx={{ margin: "2rem 2rem" }}>
        <Typography
          variant="h5"
          component="h3"
          textAlign="center"
          gutterBottom
          mb={4}
        >
          Your History
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={3}>
            <TextField
              label="Search"
              variant="outlined"
              fullWidth
              value={search}
              onChange={handleSearchChange}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="content-type-label">Content Type</InputLabel>
              <Select
                labelId="content-type-label"
                id="content-type-select"
                value={contentTypeFilter}
                onChange={handleContentTypeFilterChange}
                label="Content Type"
              >
                <MenuItem value={"analogyMaker"}>
                  Biblical Analogy Maker
                </MenuItem>
                <MenuItem value={"bibleGamesForFamilies"}>
                  Bible Games for Families
                </MenuItem>
                <MenuItem value={"bibleQuizGenerator"}>
                  Bible Quiz Generator
                </MenuItem>
                <MenuItem value={"bibleStoriesForKids"}>
                  Bible Stories for Kids
                </MenuItem>
                <MenuItem value={"bibleStudyGuide"}>Bible Study Guide</MenuItem>
                <MenuItem value={"bibleStudyPlanGenerator"}>
                  Bible Study Plan Generator
                </MenuItem>
                <MenuItem value={"bibleTriviaGeneratorAdults"}>
                  Bible Trivia Generator - Adults
                </MenuItem>
                <MenuItem value={"bibleTriviaGeneratorChildren"}>
                  Children's Bible Trivia Maker
                </MenuItem>
                <MenuItem value={"biblicalSongwriter"}>
                  Biblical Songwriter
                </MenuItem>
                <MenuItem value={"blogOutline"}>
                  Detailed Blog Outline Generator
                </MenuItem>
                <MenuItem value={"blogTopicIdeas"}>
                  Blog Idea Generator
                </MenuItem>
                <MenuItem value={"blogWriter"}>Blog Writer</MenuItem>
                <MenuItem value={"canonLawReference"}>
                  Canon Law Reference Tool
                </MenuItem>
                <MenuItem value={"catechismReference"}>
                  Catechism Cross Referencer
                </MenuItem>

                <MenuItem value={"contentPlanner"}>
                  Catholic Content Planner
                </MenuItem>
                <MenuItem value={"couplesBibleStudyGuide"}>
                  Couples' Bible Study Guide
                </MenuItem>
                <MenuItem value={"couplesDevotionalPlanner"}>
                  Couple's Devotional Planner
                </MenuItem>
                <MenuItem value={"dailyDevotionsPlanner"}>
                  Daily Devotions Planner
                </MenuItem>
                <MenuItem value={"familyMinistryBlogPostIdeas"}>
                  Family Ministry Blog Post Ideas
                </MenuItem>
                <MenuItem value={"familyPrayerGenerator"}>
                  Family Prayer Generator
                </MenuItem>
                <MenuItem value={"familyScriptureStudyPlanner"}>
                  Family Scripture Study Planner
                </MenuItem>
                <MenuItem value={"hymnRecommender"}>Hymn Recommender</MenuItem>
                <MenuItem value={"longReflectionGenerator"}>
                  Long Reflection Generator
                </MenuItem>
                <MenuItem value={"lyricsGenerator"}>Lyrics Generator</MenuItem>
                <MenuItem value={"marriageRetreatPlanner"}>
                  Marriage Retreat Planner
                </MenuItem>
                <MenuItem value={"mensMinistryBlogPostIdeas"}>
                  Men’s Ministry Blog Post Ideas
                </MenuItem>
                <MenuItem value={"modernHymnMaker"}>Modern Hymn Maker</MenuItem>
                <MenuItem value={"narrateToAChild"}>
                  Narrate to a Child
                </MenuItem>
                <MenuItem value={"parishEventPlanner"}>
                  Parish Event Planner
                </MenuItem>
                <MenuItem value={"parishNewsletterCreator"}>
                  Parish Newsletter Creator
                </MenuItem>
                <MenuItem value={"podcastCreator"}>
                  Podcast Topic Generator
                </MenuItem>
                <MenuItem value={"prayerGenerator"}>Prayer Generator</MenuItem>
                <MenuItem value={"quoteGenerator"}>Quote Generator</MenuItem>
                <MenuItem value={"rapCreator"}>Rap Creator</MenuItem>
                <MenuItem value={"relatedTopicsResearch"}>
                  Related Topic Finder
                </MenuItem>
                <MenuItem value={"retreatPlanner"}>Retreat Planner</MenuItem>
                <MenuItem value={"sacramentalPreparationGuide"}>
                  Sacramental Preparation Guide
                </MenuItem>
                <MenuItem value={"saintBiography"}>
                  Saint Biography Generator
                </MenuItem>
                <MenuItem value={"saintSayings"}>Saint Sayings</MenuItem>
                <MenuItem value={"scriptureCrossReference"}>
                  Scripture Cross-Referencer
                </MenuItem>
                <MenuItem value={"scriptureSays"}>Scripture Says</MenuItem>
                <MenuItem value={"scriptureStudyPlanMen"}>
                  Men's Scripture Study Planner
                </MenuItem>
                <MenuItem value={"scriptureStudyPlanWomen"}>
                  Women's Scripture Study Planner
                </MenuItem>
                <MenuItem value={"sermon"}>Sermon Writer</MenuItem>
                <MenuItem value={"shortReflectionGenerator"}>
                  Short Reflection Generator
                </MenuItem>
                <MenuItem value={"socialMediaContentGenerator"}>
                  Social Media Content Generator
                </MenuItem>
                <MenuItem value={"simpleKeywordResearch"}>
                  SEO Keyword Explorer
                </MenuItem>
                <MenuItem value={"weddingVowsGenerator"}>
                  Wedding Vows Generator
                </MenuItem>
                <MenuItem value={"womensMinistryBlogPostIdeas"}>
                  Women's Ministry Blog Idea Generator
                </MenuItem>
                <MenuItem value={"youthMinistryEventPlanner"}>
                  Youth Ministry Event Planner
                </MenuItem>
                <MenuItem value={"youtubeVideoIdeas"}>
                  Generate YouTube Content Ideas
                </MenuItem>
                <MenuItem value={"youtubeScriptwriter"}>
                  YouTube Scriptwriter
                </MenuItem>
                <MenuItem value={"youtubeVideoDescription"}>
                  SEO-friendly YouTube Video Description
                </MenuItem>
                <MenuItem value={"youtubeVideoTitle"}>
                  YouTube Title Maker
                </MenuItem>
                <MenuItem value={"websitePageContentGenerator"}>
                  Website Page Content Generator
                </MenuItem>
                <MenuItem value={"websiteContentPlanner"}>
                  Website Content Planner
                </MenuItem>

                {/* Add more content types if needed */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="date-range-label">Date Range</InputLabel>
              <Select
                labelId="date-range-label"
                id="date-range-select"
                value={dateRangeFilter}
                onChange={handleDateRangeFilterChange}
                label="Date Range"
              >
                <MenuItem value={"today"}>Today</MenuItem>
                <MenuItem value={"yesterday"}>Yesterday</MenuItem>
                <MenuItem value={"thisWeek"}>This Week</MenuItem>
                <MenuItem value={"thisMonth"}>This Month</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center" mt={3}>
          <Grid item xs={12}>
            {renderedHistory.slice(
              (page - 1) * itemsPerPage,
              page * itemsPerPage
            )}
            <Box display="flex" justifyContent="center" mt={4}>
              <Pagination
                count={Math.ceil(renderedHistory.length / itemsPerPage)}
                page={page}
                onChange={handlePageChange}
                color="primary"
                size="large"
                shape="rounded"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default UserHistoryScreen;
