import createAiTextGenLang from "../AiTextGenLangTemplate";

const canonLawReference = createAiTextGenLang({
  name: "canonLawReference",
  endpoint: "/api/openai/canon-law-reference",
  aiText: "canonLawReference",
  aiTextHandler: "canonLawReferenceHandler",
  aiTextPlainText: "canonLawReferencePlainText",
  title: "Canon Law Reference Tool",
  subText:
    "This tool helps you navigate the Church law. Input a topic like 'marriage' or a canon number, and receive a clear summary and reference.",
  label: "Canon Law Summary",
  placeholderText: "Enter a canon law topic or canon number...",
  buttonText: "Explore Canon Law",
  aiPlaceholder: "Canon law reference will appear here",
});

export default canonLawReference;
