import React from "react";
import { colors } from "@mui/material";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { alpha } from "@mui/material";

const familyScriptureStudyPlan = {
  title: "Family Scripture Study Planner",
  subtitle: "Create a Bible study schedule that suits your family.",
  targetRoute: "/family-scripture-study-planner",
  color: colors.cyan[400],
  bgcolor: alpha(colors.cyan[400], 0.1),
  icon: <CalendarMonthOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default familyScriptureStudyPlan;
