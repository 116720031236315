
//client/src/AuthManager.js
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { authState } from './atoms';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';

const AuthManager = ({ children }) => {
  const setAuthState = useSetRecoilState(authState);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        const user = {
          uid: firebaseUser.uid,
          email: firebaseUser.email,
          displayName: firebaseUser.displayName,
          // Add any other properties you need
        };
        setAuthState({
          user,
          isAuthenticated: true,
          isLoading: false,
        });
      } else {
        setAuthState({
          user: null,
          isAuthenticated: false,
          isLoading: false,
        });
      }
      // if (firebaseUser) {
      //   setAuthState({
      //     user: firebaseUser,
      //     isAuthenticated: true,
      //     isLoading: false,
      //   });
      // } else {
      //   setAuthState({
      //     user: null,
      //     isAuthenticated: false,
      //     isLoading: false,
      //   });
      // }
    });

    return () => unsubscribe();
  }, [setAuthState]);

  return children;
};

export default AuthManager;