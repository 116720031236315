import React from "react";
import { colors } from "@mui/material";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import { alpha } from "@mui/material";

const keywordResearch = {
  title: "Keyword Researcher for SEO",
  subtitle: "Discover potent keywords to enhance your website's SEO.",
  targetRoute: "/keyword-research",
  color: colors.red[800],
  bgcolor: alpha(colors.red[800], 0.1),
  icon: <PreviewOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default keywordResearch;
