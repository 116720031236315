import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { alpha, useTheme } from "@mui/material/styles";

import Container from "../../../../Container";
import JesusImage from "../../../../../assets/images/Jesus-img.svg";

const Hero = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box
      sx={{
        position: "relative",
        backgroundImage: `linear-gradient(to bottom, ${alpha(
          theme.palette.background.paper,
          0
        )}, ${alpha(theme.palette.alternate.main, 1)} 50%, transparent 50%)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 20%", // Adjust the background size to match the height of the JesusImage
        backgroundPosition: "bottom center", // Adjust the background position if needed
      }}
    >
      <Box paddingY={{ xs: 0, sm: "4rem", md: "8rem" }}>
        <Container>
          <Box
            maxWidth={{ xs: 1, sm: "60%" }}
            position="relative" // Added position relative
            zIndex={1} // Added z-index
          >
            <Typography
              variant="h2"
              color="text.primary"
              gutterBottom
              sx={{
                fontWeight: 700,
                fontSize: { xs: "2rem", sm: "3.75rem" },
                lineHeight: { xs: 1.2, sm: 1.1 },
              }}
            >
              Spread the Word to the world with{" "}
              <Typography
                color={"primary"}
                component={"span"}
                variant={"inherit"}
                sx={{
                  background: `linear-gradient(180deg, transparent 82%, ${alpha(
                    theme.palette.secondary.main,
                    0.3
                  )} 0%)`,
                }}
              >
                Church Scribe.
              </Typography>
            </Typography>
            <Typography
              variant="h6"
              component="p"
              color="text.secondary"
              sx={{
                fontWeight: 400,
                fontSize: { xs: "1rem", sm: "1.25rem" },
                lineHeight: { xs: 1.5, sm: 1.7 },
              }}
            >
              Go into all the world with your AI-powered partner for creating
              compelling content that leads souls to Jesus.
            </Typography>

            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "flex-start" }}
              marginTop={4}
            >
              <Button
                component={"a"}
                variant="contained"
                color="primary"
                size="large"
                fullWidth={isMd ? false : true}
                href={"/tools"}
              >
                View Features
              </Button>
              <Box
                marginTop={{ xs: 2, sm: 0 }}
                marginLeft={{ sm: 2 }}
                width={{ xs: "100%", md: "auto" }}
              >
                <Button
                  component={"a"}
                  href={"/pricing"}
                  variant="outlined"
                  color="primary"
                  size="large"
                  fullWidth={isMd ? false : true}
                >
                  Subscribe
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        component={"svg"}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 100.1"
        sx={{
          width: "100%",
          position: "absolute",
          bottom: theme.spacing(-1),
        }}
      >
        <path
          fill={theme.palette.background.paper}
          d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
        ></path>
      </Box>
      <Box
        sx={{
          display: { xs: "none", sm: "block" },
          position: "absolute",
          top: 0,
          right: -100, // Adjust the value to move the image further towards the right
          width: { sm: "50%" },
          paddingRight: { sm: "5%" },
        }}
      >
        <img
          src={JesusImage}
          alt="Jesus"
          style={{
            width: "70%",
            height: "70%",
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      </Box>
    </Box>
  );
};

export default Hero;
