import createNoKeyOptLangToneTemplate from "../NoKeyOptLangToneTemplate";

const narrateToChild = createNoKeyOptLangToneTemplate({
  name: "narratetochild",
  endpoint: "/api/openai/narrate-to-child",
  aiText: "narrateToChild",
  aiTextHandler: "narrateToChildHandler",
  aiTextPlainText: "narrateToChildPlainText",
  title: "Narrate to a Child",
  subText:
    "Our Narrate to a Child tool simplifies complex biblical themes. Just input a scripture like 'Genesis 1:1' or a topic like 'Noah's Ark', and receive a captivating, child-friendly narrative instantly!",
  label: "Kid-Friendly Narration",
  placeholderText: "Enter a scripture or topic...",
  buttonText: "Create Story",
  aiPlaceholder: "Narration for children will appear here",
});

export default narrateToChild;
