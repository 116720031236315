import React from "react";
import { colors } from "@mui/material";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import { alpha } from "@mui/material";

const familyMinistryBlogPostIdeas = {
  title: "Family Ministry Blog Post Ideas",
  subtitle: "Engage your congregation's families with relevant blog topics.",
  targetRoute: "/family-ministry-blog-post-ideas",
  color: colors.grey[800],
  bgcolor: alpha(colors.grey[800], 0.1),
  icon: <TipsAndUpdatesOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default familyMinistryBlogPostIdeas;
