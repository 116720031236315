// client/src/helpers/api.js
import instance from './axiosInstance';
import {
  getAuth,
  signOut,
} from 'firebase/auth';
import { getRecoil, setRecoil } from 'recoil-nexus';
import { authState } from '../atoms';
import axiosInstance from './axiosInstance';


// export const logout = async () => {
//   try {
//     const auth = getAuth();
//     const user = auth.currentUser;

//     if (user) {
//       await axiosInstance.post('/api/auth/logout', { uid: user.uid });
//     }

//     await signOut(auth);

//     const nullAuthState = {
//       user: null,
//       accessToken: null,
//       isAuthenticated: false,
//       isLoading: false,
//       userHMAC: null,
//       role: null,
//       id: null,
//       _id: null,
//       emailVerified: null,
//     };

//     setRecoil(authState, nullAuthState);

//     localStorage.removeItem('authState');
//     localStorage.removeItem('lastRoute');
//     localStorage.removeItem('justLoggedIn');
//     localStorage.removeItem('tokenExpirationTime');
//     localStorage.removeItem('initialDashboard');


//     sessionStorage.clear();

//     // Dispatch custom event for cross-subdomain communication
//     window.dispatchEvent(
//       new CustomEvent('authStateChange', { detail: nullAuthState })
//     );

//     return true;
//   } catch (error) {
//     console.error('An error happened during sign-out:', error);
//     throw error;
//   }
// };

export const logout = async () => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      await axiosInstance.post('/api/auth/logout', { uid: user.uid });
    }

    await signOut(auth);
    
    const nullAuthState = {
      user: null,
      isAuthenticated: false,
      isLoading: false
    };

    setRecoil(authState, nullAuthState);
    localStorage.clear();
    sessionStorage.clear();

    return true;
  } catch (error) {
    console.error('Logout error:', error);
    throw error;
  }
};

export const fetchUserSubscription = async () => {
  try {
    const auth = getRecoil(authState);
    const response = await instance.get('/api/subscription', {
      headers: {
        Authorization: `Bearer ${auth.accessToken}`,
      },
    });
    setRecoil(authState, (prevState) => ({
      ...prevState,
      subscriptionStatus: response.data.subscriptionStatus,
    }));
  } catch (error) {
    console.error('Failed to fetch subscription:', error);
    throw error;
  }
};

