/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from "react";
import { alpha, useTheme } from "@mui/material/styles";
import {
  Box,
  Container,
  Typography,
  Grid,
  IconButton,
  Card,
  Avatar,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { checkSubscription } from "../../../helpers/subscriptionHelper";
import LoginDialog from "../Dialog/LoginDialog";
import SubscribeDialog from "../Dialog/SubscribeDialog";
import HeadlineSubtextBible from "./HeadlineSubtext/HeadlineSubtextBible";

import {
  marriageRetreatPlanner,
  retreatPlanner,
} from "../cards/RetreatTools/index";

const RetreatTools = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openSubscribeDialog, setOpenSubscribeDialog] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("authToken")) {
      checkSubscription().then((status) => {
        setSubscriptionStatus(status);
      });
    }
  }, []); // empty dependency array makes this run once on mount

  const handleClickOpen = async (plan, targetRoute) => {
    if (localStorage.getItem("authToken")) {
      if (!subscriptionStatus) {
        setSelectedPlan(plan);
        setOpenSubscribeDialog(true); // Open SubscribeDialog
      } else {
        if (targetRoute) {
          navigate(targetRoute);
        }
      }
    } else {
      setOpenLoginDialog(true); // Open LoginDialog
    }
  };

  const features = [marriageRetreatPlanner, retreatPlanner];

  return (
    <>
      <HeadlineSubtextBible />

      <Box
        sx={{
          marginTop: {
            xs: "-16px", // value for screens 0px or larger
            sm: "-70px", // value for screens 600px or larger
            md: "-32px", // value for screens 960px or larger
            lg: "-40px", // value for screens 1280px or larger
            xl: "-48px", // value for screens 1920px or larger
          },

          paddingBottom: "32px", // <-- Add this line to give padding at the bottom

          backgroundImage: `linear-gradient(to bottom, ${alpha(
            theme.palette.background.paper,
            0
          )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
          backgroundRepeat: "repeat-x",
          position: "relative",
        }}
      >
        <Container>
          <Grid container spacing={4}>
            {features.map((item, i) => (
              <Grid item xs={12} sm={6} md={4} key={i}>
                <IconButton
                  onClick={() => handleClickOpen("basic", item.targetRoute)}
                  sx={{
                    width: "100%",
                    height: "100%",
                    padding: 0,
                    borderRadius: 2,
                    border: "1px solid transparent",
                    transition: "border-color 0.3s, box-shadow 0.3s",
                    "&:hover": {
                      borderColor: theme.palette.action.hover,
                      boxShadow: theme.shadows[4],
                    },
                  }}
                >
                  <Box
                    component={Card}
                    padding={4}
                    borderRadius={2}
                    width={1}
                    height={1}
                    data-aos={"fade-up"}
                    data-aos-delay={i * 100}
                  >
                    <Box
                      component={Avatar}
                      width={60}
                      height={60}
                      marginBottom={2}
                      bgcolor={item.bgcolor}
                      color={item.color}
                      variant={"rounded"}
                      borderRadius={2}
                    >
                      {item.icon}
                    </Box>
                    <Typography
                      variant={"h6"}
                      gutterBottom
                      sx={{ fontWeight: 500 }}
                    >
                      {item.title}
                    </Typography>
                    <Typography color="text.secondary">
                      {item.subtitle}
                    </Typography>
                  </Box>
                </IconButton>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <SubscribeDialog
        open={openSubscribeDialog}
        handleClose={() => setOpenSubscribeDialog(false)}
        navigate={navigate}
      />
      <LoginDialog
        open={openLoginDialog}
        handleClose={() => setOpenLoginDialog(false)}
        navigate={navigate}
      />
    </>
  );
};

export default RetreatTools;
