import React from "react";
import { colors } from "@mui/material";
import QueueMusicOutlinedIcon from "@mui/icons-material/QueueMusicOutlined";
import { alpha } from "@mui/material";

const hymnRecommender = {
  title: "Hymn Recommender",
  subtitle: "Find the perfect hymn for your worship service.",
  targetRoute: "/hymn-recommender",
  color: colors.purple[300],
  bgcolor: alpha(colors.purple[300], 0.1),
  icon: <QueueMusicOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default hymnRecommender;
