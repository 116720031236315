import createSacramentTemplate from "../SacramentTemplate";

const sacramentalPreparationGuide = createSacramentTemplate({
  name: "sacramentalpreparationguide",
  endpoint: "/api/openai/sacramental-preparation-guide",
  aiText: "sacramentalPreparationGuide",
  aiTextHandler: "sacramentalPreparationGuideHandler",
  aiTextPlainText: "sacramentalPreparationGuidePlainText",
  title: "Sacramental Preparation Guide",
  subText:
    "Prepare for sacraments effectively with our Guide. Input the sacrament like 'Baptism' and get a helpful preparation guide.",
  label: "Your Sacrament Readiness Plan",
  placeholderText: "Enter a sacrament…",
  buttonText: "Create Guide",
  aiPlaceholder: "Your sacrament preparation guide will appear here",
});

export default sacramentalPreparationGuide;
