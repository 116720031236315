import React from "react";
import { colors } from "@mui/material";
import PodcastsOutlinedIcon from "@mui/icons-material/PodcastsOutlined";
import { alpha } from "@mui/material";

const podcastCreator = {
  title: "Podcast Creator",
  subtitle: "Craft engaging and thought-provoking scripts for your podcasts.",
  targetRoute: "/podcast-creator",
  color: colors.orange[600],
  bgcolor: alpha(colors.orange[600], 0.1),
  icon: <PodcastsOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default podcastCreator;
