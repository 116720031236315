import React from "react";
import { Select, MenuItem, Checkbox, ListItemText } from "@mui/material";

const PlatformTypeSelect = ({ platformType, setPlatformType }) => {
  const handleChange = (event) => {
    setPlatformType(event.target.value);
  };

  const platformOptions = [
    "Facebook Page",
    "Twitter Feed",
    "YouTube Channel",
    "Instagram Page",
    "Podcast",
    "TikTok Channel",
    "Snapchat",
    "LinkedIn Account",
  ];

  return (
    <Select
      multiple
      displayEmpty
      value={platformType}
      onChange={handleChange}
      sx={{ mb: 1.5 }}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return "Select platform type";
        }
        return selected.join(", ");
      }}
      fullWidth
    >
      {platformOptions.map((option) => (
        <MenuItem key={option} value={option}>
          <Checkbox checked={platformType.indexOf(option) > -1} />
          <ListItemText primary={option} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default PlatformTypeSelect;
