import React from "react";
import { colors } from "@mui/material";
import PendingActionsOutlinedIcon from "@mui/icons-material/PendingActionsOutlined";
import { alpha } from "@mui/material";

const retreatPlanner = {
  title: "Retreat Planner",
  subtitle: "Create impactful retreat experiences with detailed planning.",
  targetRoute: "/retreat-planner",
  color: colors.blue[600],
  bgcolor: alpha(colors.blue[600], 0.1),
  icon: <PendingActionsOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default retreatPlanner;
