import React from "react";
import { colors } from "@mui/material";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import { alpha } from "@mui/material";

const contentPlanner = {
  title: "Content Planner - Social Media",
  subtitle: "Turn your topics into a structured content publishing calendar.",
  targetRoute: "/content-planner",
  color: colors.purple[700],
  bgcolor: alpha(colors.purple[700], 0.1),
  icon: <DifferenceOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default contentPlanner;
