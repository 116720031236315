// /* eslint-disable react/no-unescaped-entities */
// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { useFormik } from "formik";
// import * as yup from "yup";
// import instance from "../../../../../helpers/axiosInstance";

// import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

// import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid";
// import TextField from "@mui/material/TextField";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import Link from "@mui/material/Link";
// import AvatarSelection from "../../../../Avatars/AvatarSelection";
// import IconButton from "@mui/material/IconButton";
// import InputAdornment from "@mui/material/InputAdornment";
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import EmailUseDialogComponent from "../../../Dialog/EmailUseDialogComponent";

// import trackButtonClick from "../../../../../helpers/trackButtonClick";

// const validationSchema = yup.object({
//   firstName: yup
//     .string()
//     .trim()
//     .min(2, "Please enter a valid name")
//     .max(50, "Please enter a valid name")
//     .required("Please specify your first name"),
//   lastName: yup
//     .string()
//     .trim()
//     .min(2, "Please enter a valid name")
//     .max(50, "Please enter a valid name")
//     .required("Please specify your last name"),
//   email: yup
//     .string()
//     .trim()
//     .email("Please enter a valid email address")
//     .required("Email is required."),
//   password: yup
//     .string()
//     .required("Please specify your password")
//     .min(8, "The password should have at minimum length of 8"),
//   avatar: yup.string().required("Please select your Saint Avatar"),
// });

// const Form = () => {
//   const navigate = useNavigate();
//   const [showPassword, setShowPassword] = useState(false);
//   const [openEmailInUseDialog, setOpenEmailInUseDialog] = useState(false);
//   const [avatarType, setAvatarType] = useState("Catholic");

//   const initialValues = {
//     firstName: "",
//     lastName: "",
//     email: "",
//     password: "",
//     avatar: "",
//   };

//   const onSubmit = async (values) => {
//     try {
//       const response = await instance.post("/api/auth/register", values);

//       trackButtonClick("User registered successfully");

//       navigate("/email-verification");
//     } catch (error) {
//       if (
//         error.response &&
//         error.response.status === 400 &&
//         error.response.data.error === "Email already is in use"
//       ) {
//         setOpenEmailInUseDialog(true);
//       } else {
//         console.error("Error:", error);
//       }
//     }
//   };

//   const handleClickShowPassword = () => {
//     setShowPassword(!showPassword);
//   };

//   const handleMouseDownPassword = (event) => {
//     event.preventDefault();
//   };

//   const formik = useFormik({
//     initialValues,
//     validationSchema: validationSchema,
//     onSubmit,
//   });

//   return (
//     <Box>
//       <Box marginBottom={4}>
//         <Typography
//           sx={{
//             textTransform: "uppercase",
//             fontWeight: "medium",
//           }}
//           gutterBottom
//           color={"text.secondary"}
//         >
//           Signup
//         </Typography>
//         <Typography
//           variant="h4"
//           sx={{
//             fontWeight: 700,
//           }}
//         >
//           Create an account
//         </Typography>
//         <Typography color="text.secondary">
//           Fill out the form to get started.
//         </Typography>
//       </Box>
//       <form onSubmit={formik.handleSubmit}>
//         <Grid container spacing={4}>
//           <Grid item xs={12} sm={6}>
//             <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
//               Enter your first name
//             </Typography>
//             <TextField
//               label="First name *"
//               variant="outlined"
//               name={"firstName"}
//               fullWidth
//               value={formik.values.firstName}
//               onChange={formik.handleChange}
//               error={
//                 formik.touched.firstName && Boolean(formik.errors.firstName)
//               }
//               helperText={formik.touched.firstName && formik.errors.firstName}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
//               Enter your last name
//             </Typography>
//             <TextField
//               label="Last name *"
//               variant="outlined"
//               name={"lastName"}
//               fullWidth
//               value={formik.values.lastName}
//               onChange={formik.handleChange}
//               error={formik.touched.lastName && Boolean(formik.errors.lastName)}
//               helperText={formik.touched.lastName && formik.errors.lastName}
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
//               Enter your email
//             </Typography>
//             <TextField
//               label="Email *"
//               variant="outlined"
//               name={"email"}
//               fullWidth
//               value={formik.values.email}
//               onChange={formik.handleChange}
//               error={formik.touched.email && Boolean(formik.errors.email)}
//               helperText={formik.touched.email && formik.errors.email}
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
//               Enter your password
//             </Typography>
//             <TextField
//               label="Password *"
//               variant="outlined"
//               name={"password"}
//               type={showPassword ? "text" : "password"} // Toggle between "text" and "password"
//               fullWidth
//               value={formik.values.password}
//               onChange={formik.handleChange}
//               error={formik.touched.password && Boolean(formik.errors.password)}
//               helperText={formik.touched.password && formik.errors.password}
//               InputProps={{
//                 // <-- This is where the toggle button is added using endAdornment
//                 endAdornment: (
//                   <InputAdornment position="end">
//                     <IconButton
//                       onClick={handleClickShowPassword}
//                       onMouseDown={handleMouseDownPassword}
//                       edge="end"
//                     >
//                       {showPassword ? <VisibilityOff /> : <Visibility />}
//                     </IconButton>
//                   </InputAdornment>
//                 ),
//               }}
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Typography variant={"subtitle1"} sx={{ marginBottom: 2 }}>
//               Select your Avatar
//             </Typography>

//             <RadioGroup
//               row
//               aria-label="avatar"
//               name="avatarType"
//               value={avatarType}
//               onChange={(e) => setAvatarType(e.target.value)}
//             >
//               <FormControlLabel
//                 value="Catholic"
//                 control={<Radio />}
//                 label="Catholic"
//               />
//               <FormControlLabel
//                 value="Protestant"
//                 control={<Radio />}
//                 label="Protestant"
//               />
//             </RadioGroup>

//             <AvatarSelection
//               selectedAvatar={formik.values.avatar}
//               setSelectedAvatar={(avatar) =>
//                 formik.setFieldValue("avatar", avatar)
//               }
//               avatarType={avatarType}
//             />

//             {formik.touched.avatar && Boolean(formik.errors.avatar) && (
//               <Typography
//                 variant={"subtitle2"}
//                 sx={{ color: "red", marginTop: 2 }}
//               >
//                 {formik.errors.avatar}
//               </Typography>
//             )}
//           </Grid>
//           <Grid item container xs={12}>
//             <Box
//               display="flex"
//               flexDirection={{ xs: "column", sm: "row" }}
//               alignItems={{ xs: "stretched", sm: "center" }}
//               justifyContent={"space-between"}
//               width={1}
//               maxWidth={600}
//               margin={"0 auto"}
//             >
//               <Box marginBottom={{ xs: 1, sm: 0 }}>
//                 <Typography variant={"subtitle2"}>
//                   Already have an account?{" "}
//                   <Link
//                     component={"a"}
//                     color={"primary"}
//                     href={"/login"}
//                     underline={"none"}
//                   >
//                     Login.
//                   </Link>
//                 </Typography>
//               </Box>
//               <Button size={"large"} variant={"contained"} type={"submit"}>
//                 Sign up
//               </Button>
//             </Box>
//           </Grid>
//           <Grid
//             item
//             container
//             xs={12}
//             justifyContent={"center"}
//             alignItems={"center"}
//           >
//             <Typography
//               variant={"subtitle2"}
//               color={"text.secondary"}
//               align={"center"}
//             >
//               By clicking "Sign up" button you agree with our{" "}
//               <Link
//                 component={"a"}
//                 color={"primary"}
//                 href={"/company-terms"}
//                 underline={"none"}
//               >
//                 company terms and conditions.
//               </Link>
//             </Typography>
//           </Grid>
//         </Grid>
//       </form>
//       <EmailUseDialogComponent
//         open={openEmailInUseDialog}
//         handleClose={() => setOpenEmailInUseDialog(false)}
//         navigate={navigate}
//       />
//     </Box>
//   );
// };

// export default Form;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { auth } from '../../../../../firebase';
import instance from "../../../../../helpers/axiosInstance";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";


import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, 'First name should be at least 2 characters')
    .required('First name is required'),
  lastName: yup
    .string()
    .trim()
    .min(2, 'Last name should be at least 2 characters')
    .required('Last name is required'),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required"),
  password: yup
    .string()
    .required("Please specify your password")
    .min(8, "The password should have a minimum length of 8"),
  confirmPassword: yup
    .string()
    .required("Please confirm your password")
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const Form = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);


  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setError("");
      setSuccess("");
      setLoading(true);
      
      try {
        // First create user in Firebase
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          values.email,
          values.password
        );

        // Get the Firebase UID
        const firebaseUid = userCredential.user.uid;

        // Register user in MongoDB
        const response = await instance.post("/api/auth/register", {
          uid: firebaseUid,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          emailVerified: false
        });

        if (response.data.success) {
          // Send email verification
          await sendEmailVerification(userCredential.user);
          setSuccess("Registration successful! Please check your email to verify your account.");
          
          // Clear form
          formik.resetForm();
          
          // Redirect to login after a delay
          setTimeout(() => {
            navigate('/login');
          }, 3000);
        }

      } catch (error) {
        console.error("Registration error:", error);
        
        if (error.code === 'auth/email-already-in-use') {
          setError("An account with this email already exists.");
        } else if (error.code === 'auth/invalid-email') {
          setError("Invalid email address.");
        } else if (error.code === 'auth/weak-password') {
          setError("Please choose a stronger password.");
        } else {
          setError(error.message || "Registration failed. Please try again.");
        }
      } finally {
        setLoading(false);
      }
    },
  });

    const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


   return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium",
          }}
          gutterBottom
          color={"text.secondary"}
        >
          Signup
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Create an account
        </Typography>
        <Typography color="text.secondary">
          Fill out the form to get started.
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your first name
            </Typography>
            <TextField
              label="First name *"
              variant="outlined"
              name={"firstName"}
              fullWidth
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your last name
            </Typography>
            <TextField
              label="Last name *"
              variant="outlined"
              name={"lastName"}
              fullWidth
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your email
            </Typography>
            <TextField
              label="Email *"
              variant="outlined"
              name={"email"}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your password
            </Typography>
            <TextField
              label="Password *"
              variant="outlined"
              name={"password"}
              type={showPassword ? "text" : "password"} // Toggle between "text" and "password"
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
  <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
    Confirm your password
  </Typography>
  <TextField
    label="Confirm Password *"
    variant="outlined"
    name={"confirmPassword"}
    type={showPassword ? "text" : "password"}
    fullWidth
    value={formik.values.confirmPassword}
    onChange={formik.handleChange}
    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
</Grid>

          {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}
         
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={1}
              maxWidth={600}
              margin={"0 auto"}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={"subtitle2"}>
                  Already have an account?{" "}
                  <Link
                    component={"a"}
                    color={"primary"}
                    href={"/login"}
                    underline={"none"}
                  >
                    Login.
                  </Link>
                </Typography>
              </Box>
              <Button 
  size={"large"} 
  variant={"contained"} 
  type={"submit"}
  disabled={loading || (formik.touched.email && !formik.isValid)} // Only disable if form has been touched and is invalid
  startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
>
  {loading ? "Signing up..." : "Sign up"}
</Button>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              variant={"subtitle2"}
              color={"text.secondary"}
              align={"center"}
            >
              By clicking "Sign up" button you agree with our{" "}
              <Link
                component={"a"}
                color={"primary"}
                href={"/company-terms"}
                underline={"none"}
              >
                company terms and conditions.
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
      
    </Box>
  );
};


export default Form;