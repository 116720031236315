import createOptLangKnowToneKeyPlatformAudienceTemplate from "../OptLangKnowToneKeyPlatformAudienceTemplate";

const socialMediaContentGenerator =
  createOptLangKnowToneKeyPlatformAudienceTemplate({
    name: "socialmediacontentgenerator",
    endpoint: "/api/openai/social-media-content-generator",
    aiText: "socialMediaContentGenerator",
    aiTextHandler: "socialMediaContentGeneratorHandler",
    aiTextPlainText: "socialMediaContentGeneratorPlainText",
    title: "Social Media Content Generator",
    subText:
      "Create engaging posts with our Social Media Content Planner. Enter a theme and get tailored content for Facebook, Instagram, and Twitter.",
    label: "Social Media Content",
    placeholderText: "What theme are your posts based on?",
    buttonText: "Create Posts",
    aiPlaceholder: "Your social posts will be generated here",
  });

export default socialMediaContentGenerator;
