// import React, { useRef, useState, useEffect } from "react";
// import { io } from "socket.io-client";

// import {
//   Box,
//   Link,
//   Typography,
//   useTheme,
//   useMediaQuery,
//   Collapse,
//   Alert,
//   TextField,
//   Button,
//   Card,
//   CircularProgress,
//   Grid,
//   FormControlLabel,
//   Checkbox,
//   ButtonGroup,
//   Switch,
// } from "@mui/material";
// import * as colors from "@mui/material/colors";

// import instance from "../../../helpers/axiosInstance";
// import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
// import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
// import MonthlyWordCountErrorDialog from "../Dialog/monthlyWordCountErrorDialog";
// import trackButtonClick from "../../../helpers/trackButtonClick";
// import GeneratingDialog from "../Dialog/GeneratingDialog";
// import LanguageSelect from "../../../helpers/LanguageSelect";
// import KnowledgeBase from "../../../helpers/KnowledgeBase";
// import downloadPdf from "../../../helpers/downloadPdf";
// import printDocument from "../../../helpers/printDocument";
// import PrintDownErrorDialog from "../Dialog/PrintDownErrorDialog";
// import CatholicBibleSelect from "../../../helpers/CatholicBibleSelect";
// import ProtestantBibleSelect from "../../../helpers/ProtestantBibleSelect";

// import { auth } from '../../../firebase';

// export function createOptLangKnowKeyNoBibleTemplate({
//   name,
//   placeholderText,
//   label,
//   title,
//   subText,
//   buttonText,
//   aiPlaceholder,
// }) {
//   return function OptLangKnowKeyNoBibleScreen() {
//     const theme = useTheme();
//     const isNotMobile = useMediaQuery("(min-width: 1000px)");
//     const contentRef = useRef(null);
//     const [keywords, setKeywords] = useState("");
//     const [topic, setTopic] = useState("");

//     const [error, setError] = useState("");
//     const [aiText, setAiText] = useState("");
//     const [aiTextPlainText, setAiTextPlainText] = useState("");
//     const [information, setInformation] = useState("");
//     const [informationError, setInformationError] = useState("");
//     const [loading, setLoading] = useState(false);
//     const [isPrintDownErrorDialogOpen, setPrintDownErrorDialogOpen] =
//       useState(false);
//     const [copied, setCopied] = useState(false);
//     const [dialogOpen, setDialogOpen] = useState(false);
//     const [buttonDisabled, setButtonDisabled] = useState(false);
//     const [topicError, setTopicError] = useState("");
//     const [keywordsError, setKeywordsError] = useState("");
//     const [language, setLanguage] = useState("English (American)");
//     const [churchType, setChurchType] = useState("Catholic");

//     const [organisationName, setOrganisationName] = useState([]);
//     const [selectedOrganisation, setSelectedOrganisation] = useState("");
//     const [itemName, setItemName] = useState([]);
//     const [selectedItem, setSelectedItem] = useState("");
//     const [useKnowledgeBase, setUseKnowledgeBase] = useState(false);
//     const [bibleVersion, setBibleVersion] = useState("");

//     const [socket, setSocket] = useState(null);
//     const [isInitializing, setIsInitializing] = useState(false);
//     const [isStreaming, setIsStreaming] = useState(false);
//     const [isLoading, setIsLoading] = useState(false);

//     // Socket setup - keep this exactly as is

//     useEffect(() => {
//       const setupSocket = async () => {
//         try {
//           const user = auth.currentUser;
//           if (!user) {
//             setError("Authentication required");
//             return null;
//           }
     
//           const token = await user.getIdToken();
//           const socketUrl = process.env.NODE_ENV === 'production' 
//             ? process.env.REACT_APP_API_URL 
//             : "http://localhost:4242";
     
//           console.log("Connecting to socket at:", socketUrl);
          
//           const newSocket = io(socketUrl, {
//             auth: { token },
//             transports: ["websocket"],
//             withCredentials: true,
//             autoConnect: true,
//             reconnection: true,
//             reconnectionDelay: 1000,
//             reconnectionDelayMax: 5000,
//             reconnectionAttempts: 5
//           });
     
//           newSocket.on("connect", () => {
//             console.log("Socket connected with ID:", newSocket.id);
//             setError("");
//           });
     
//           newSocket.on("connect_error", (err) => {
//             console.error("Socket connection error:", err);
//             setError(`Connection error: ${err.message}`);
//           });
     
//           newSocket.on("disconnect", (reason) => {
//             console.log("Socket disconnected:", reason);
//             if (reason === "io server disconnect") {
//               newSocket.connect();
//             }
//           });
     
//           return newSocket;
//         } catch (error) {
//           console.error("Socket setup error:", error);
//           setError(`Setup error: ${error.message}`);
//           return null;
//         }
//       };
     
//       setupSocket().then(socket => {
//         if (socket) {
//           setSocket(socket);
//           return () => {
//             console.log("Cleaning up socket connection");
//             socket.disconnect();
//           };
//         }
//       });
//      }, [name, label]);
     
  

//     useEffect(() => {
//       if (!socket) return;
    
//       const handleProgress = ({ content }) => {
//         if (isInitializing) {
//           setIsInitializing(false);
//           setIsStreaming(true);
//           setIsLoading(false);
//         }
//         setAiText(prevText => prevText + content);
//       };
    
//       const handleComplete = () => {
//         setIsStreaming(false);
//         setButtonDisabled(false);
//         setIsLoading(false);
//       };
    
//       socket.on('generation-progress', handleProgress);
//       socket.on('generation-complete', handleComplete);
      
//       return () => {
//         socket.off('generation-progress', handleProgress);
//         socket.off('generation-complete', handleComplete);
//       };
//     }, [socket, isInitializing]);

//     useEffect(() => {
//       if (contentRef.current && aiText) {
//         const scrollElement = contentRef.current;
//         const isNearBottom = scrollElement.scrollHeight - scrollElement.scrollTop <= scrollElement.clientHeight + 100;
        
//         if (isNearBottom) {
//           scrollElement.scrollTop = scrollElement.scrollHeight;
//         }
//       }
//     }, [aiText]);
    

//     const handleChurchTypeChange = (event) => {
//       setChurchType(event.target.checked ? "Protestant" : "Catholic");
//     };

//     useEffect(() => {
//       window.scrollTo(0, 0);
//     }, []);

//     useEffect(() => {}, [selectedOrganisation, selectedItem]);

//     useEffect(() => {
//       const fetchOrganisationName = async () => {
//         try {
//           const token = localStorage.getItem("authToken");
//           const config = {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           };
//           console.log(
//             `Sending request for organisation: ${selectedOrganisation}, item: ${selectedItem}`
//           );

//           const { data } = await instance.get(
//             "/api/knowledgebase/organisationName",
//             config
//           );
//           setOrganisationName(data.organisationName);
//           console.log(data.organisationName);
//         } catch (error) {
//           console.error(error);
//         }
//       };

//       fetchOrganisationName();
//     }, []);

//     useEffect(() => {
//       const fetchItemName = async () => {
//         try {
//           if (selectedOrganisation) {
//             const token = localStorage.getItem("authToken");
//             const config = {
//               headers: {
//                 Authorization: `Bearer ${token}`,
//               },
//             };
//             console.log("Selected Organisation):", selectedOrganisation);

//             console.log(
//               `Sending request for organisation again: ${selectedOrganisation}, item: ${selectedItem}`
//             );

//             const { data } = await instance.get(
//               `/api/knowledgebase/items?organisationName=${selectedOrganisation}`,
//               config
//             );
//             setItemName(data.itemName);
//             console.log(data.itemName);
//           }
//         } catch (error) {
//           console.error(error);
//         }
//       };
//       console.log("Selected organisation updated:", selectedOrganisation);

//       fetchItemName();
//     }, [selectedOrganisation, instance]);

//     const aiTextHandler = async (e) => {
//       console.log("aiTextHandler triggered");

//       e.preventDefault();

//       // Track button click here
//       trackButtonClick(label);

//       setLoading(true);
//       setButtonDisabled(true); // Disable the button when processing starts

//       // New validations
//       if (!topic) {
//         setTopicError("Please input text.");
//         setLoading(false);
//         setButtonDisabled(false);
//         return;
//       }

//       if (topic.split(" ").filter((n) => n).length < 1) {
//         setTopicError("Text should contain at least 1 word.");
//         setLoading(false);
//         setButtonDisabled(false);
//         return;
//       }

//       setLoading(true);

//       try {
//         const token = localStorage.getItem("authToken");
//         const config = {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         };

//         let apiUrl = process.env.REACT_APP_API_URL || "http://localhost:4242";

//         const payload = {
//           keywords,
//           topic,
//           language,
//           churchType,
//           information,
//           useMyKnowledgeBase: useKnowledgeBase,
//           toolName: name,
//         };

//         if (useKnowledgeBase) {
//           payload.organisationName = selectedOrganisation;
//           payload.itemName = selectedItem;
//         }

//         const { data } = await instance.post(
//           "/api/generation/start-generation",
//           payload,
//           config
//         );

//         console.log("name:", name);

//         const taskId = data.taskId;
//         console.log("taskId:", taskId);

//         console.log(
//           `SSE URL: ${apiUrl}/api/sse/stream-results?taskId=${taskId}`
//         );

//         let eventSource = new EventSource(
//           `${apiUrl}/api/sse/stream-results?taskId=${taskId}&token=${token}`
//         );

//         let isFirstChunkReceived = false;

//         eventSource.onmessage = (event) => {
//           console.log("SSE event received:", event.data);
//           try {
//             if (!isFirstChunkReceived) {
//               setLoading(false); // Hide the GeneratingDialog
//               isFirstChunkReceived = true;
//             }

//             // Process the received data immediately
//             const processedText = processTextForDisplay(event.data);

//             // Update the state with the processed text
//             setAiText((prevAiText) => prevAiText + processedText);

//             // Update aiTextPlainText with plain text
//             const plainText = stripHtml(processedText);
//             setAiTextPlainText((prevText) => prevText + plainText);
//           } catch (e) {
//             console.error("Error handling message content:", e);
//             setLoading(false); // Ensure loading is set to false in case of error
//           }
//         };

//         eventSource.onerror = function (error) {
//           console.error("EventSource failed:", error);

//           if (!data.taskId) {
//             console.error("No task ID received from the server:", data);
//             setLoading(false);
//             setButtonDisabled(false);
//             return;
//           }

//           eventSource.close();
//         };
//       } catch (err) {
//         console.error("Error in start-generation request:", err);
//         let errorMessage = "Unknown error. Please try again.";

//         // Handle specific error for monthly word count exceeded
//         if (err.response && err.response.status === 402) {
//           errorMessage = err.response.data.message;
//           setDialogOpen(true);
//         } else if (
//           err.response &&
//           err.response.status === 403 &&
//           err.response.data.message.includes(
//             "Monthly limit exceeded. Upgrade your plan."
//           )
//         ) {
//           errorMessage = err.response.data.message;
//           setDialogOpen(true);
//         } else if (err.response && err.response.data.error) {
//           setError(err.response.data.error);
//         } else if (err.message) {
//           setError(err.message);
//         }
//         setTimeout(() => {
//           setError("");
//         }, 5000);

//         setLoading(false); // Ensure loading is set to false in case of error
//         setButtonDisabled(false);
//       } finally {
//         setButtonDisabled(false); // Reset button state
//       }
//     };

//     const processTextForDisplay = (text) => {
//       // Remove literal \n"" and "" from the string
//       let processedText = text.replace(/\\n""/g, "");
//       processedText = processedText.replace(/""/g, "");

//       // Remove any stray \n and standalone quotation marks
//       processedText = processedText.replace(/\\n/g, "");
//       processedText = processedText.replace(/\"\"/g, "");

//       // Remove any stray # symbols
//       processedText = processedText.replace(/#/g, "");

//       // Remove individual quotation marks that are out of place
//       processedText = processedText.replace(/\"/g, "");

//       // Add quotation marks around text within <blockquote> tags
//       processedText = processedText.replace(
//         /<blockquote>(.*?)<\/blockquote>/g,
//         '"$1"'
//       );

//       // Replace double newlines with two line breaks for HTML display
//       return processedText.replace(/\\n\\n/g, "<br><br>");
//     };

//     const stripHtml = (html) => {
//       let text = html;
//       // Replace ending heading tags with two newlines for a break
//       text = text.replace(/<\/h[1-6]>/gi, "\n\n");
//       // Replace paragraph endings and line breaks with two newlines
//       text = text.replace(/<\/p>/gi, "\n\n");
//       text = text.replace(/<br\s*\/?>/gi, "\n\n");

//       // Create a temporary div element
//       let temporalDivElement = document.createElement("div");
//       // Set the HTML content with the provided
//       temporalDivElement.innerHTML = text;
//       // Retrieve the text property of the element
//       return (
//         temporalDivElement.textContent || temporalDivElement.innerText || ""
//       );
//     };

//     const copyToClipboardHtml = () => {
//       const htmlContent = aiText; // This should be your HTML-formatted string
//       const tempElement = document.createElement("div");
//       tempElement.style.position = "absolute";
//       tempElement.style.left = "-9999px";
//       tempElement.innerHTML = htmlContent;
//       document.body.appendChild(tempElement);

//       const selection = window.getSelection();
//       const range = document.createRange();
//       range.selectNodeContents(tempElement);
//       selection.removeAllRanges();
//       selection.addRange(range);

//       try {
//         document.execCommand("copy");
//         setCopied(true);
//         setTimeout(() => setCopied(false), 5000);
//       } catch (err) {
//         console.error("Error copying HTML content: ", err);
//       }

//       document.body.removeChild(tempElement);
//       selection.removeAllRanges();
//     };

//     const handleDownload = () => {
//       if (!contentRef || !contentRef.current) {
//         setPrintDownErrorDialogOpen(true);
//         return;
//       }

//       downloadPdf(contentRef);
//     };

//     const handlePrint = () => {
//       if (!contentRef || !contentRef.current) {
//         setPrintDownErrorDialogOpen(true);
//         return;
//       }

//       printDocument(contentRef);
//     };

//     // Define the resetAll function to reset all the states
//     const resetAll = () => {
//       setTopic("");
//       setAiText("");
//       setAiTextPlainText("");
//       setError("");
//       setLoading(false);
//       setButtonDisabled(false);
//       setLanguage("English (American)");
//       setInformation("");

//       setChurchType("Catholic");

//       setBibleVersion("");
//     };

//     const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

//     return (
//       <Box
//         width={isNotMobile ? "70%" : "90%"}
//         p="2rem"
//         m="2rem auto"
//         borderRadius={5}
//         backgroundColor={theme.palette.background.alt}
//         sx={{ boxShadow: 5 }}
//       >
//         <Collapse in={error}>
//           <Alert severity="error" sx={{ mb: 2 }}>
//             {error}
//           </Alert>
//         </Collapse>
//         <form onSubmit={aiTextHandler}>
//           <Typography variant="h5" mb={2}>
//             {title}
//           </Typography>
//           <Typography variant="body2" mb={2}>
//             {subText}
//           </Typography>
//           <Grid container alignItems="center" spacing={2} mb={2}>
//             <Grid item>
//               <FormControlLabel
//                 control={
//                   <Checkbox
//                     checked={useKnowledgeBase}
//                     onChange={(e) => setUseKnowledgeBase(e.target.checked)}
//                     name="useKnowledgeBase"
//                   />
//                 }
//                 label="Use my knowledge base"
//               />
//             </Grid>

//             <Grid item>
//               <Switch
//                 checked={churchType === "Protestant"}
//                 onChange={handleChurchTypeChange}
//                 color="default"
//                 sx={{
//                   "& .MuiSwitch-switchBase": {
//                     color: churchType === "Catholic" ? colors.purple[700] : "",
//                   },
//                   "& .MuiSwitch-switchBase.Mui-checked": {
//                     color: churchType === "Protestant" ? colors.pink[700] : "",
//                   },
//                   "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
//                     backgroundColor:
//                       churchType === "Protestant" ? colors.pink[100] : "",
//                   },
//                   "& .MuiSwitch-track": {
//                     backgroundColor:
//                       churchType === "Catholic" ? colors.purple[100] : "",
//                   },
//                 }}
//               />
//             </Grid>
//             <Grid item>{churchType}</Grid>
//           </Grid>

//           <Grid container spacing={2} alignItems="flex-end">
//             <Grid item xs={12}>
//               <TextField
//                 placeholder={placeholderText}
//                 fullWidth
//                 value={topic}
//                 error={Boolean(topicError)}
//                 helperText={topicError}
//                 onChange={(e) => {
//                   const wordCount = e.target.value
//                     .split(" ")
//                     .filter((n) => n).length;
//                   if (wordCount <= 100) {
//                     setTopic(e.target.value);
//                     setTopicError("");
//                   } else {
//                     setTopicError(
//                       "Topic should not contain more than 100 words."
//                     );
//                   }
//                 }}
//                 sx={{ mb: 1.5 }}
//               />
//             </Grid>
//           </Grid>
//           <Grid container spacing={2} alignItems="flex-end">
//             <Grid item xs={12}>
//               <TextField
//                 multiline
//                 rows={3}
//                 placeholder="Input any additional information (optional)"
//                 fullWidth
//                 value={information}
//                 error={Boolean(informationError)}
//                 helperText={informationError}
//                 onChange={(e) => {
//                   const wordCount = e.target.value
//                     .split(" ")
//                     .filter((n) => n).length;
//                   if (wordCount <= 500) {
//                     setInformation(e.target.value);
//                     setInformationError("");
//                   } else {
//                     setInformationError(
//                       "Information should not contain more than 500 words."
//                     );
//                   }
//                 }}
//                 sx={{ mb: 1.5 }}
//               />
//             </Grid>
//           </Grid>

//           <Grid container spacing={2} alignItems="flex-end">
//             <KnowledgeBase
//               useKnowledgeBase={useKnowledgeBase}
//               organisationName={organisationName}
//               selectedOrganisation={selectedOrganisation}
//               setSelectedOrganisation={setSelectedOrganisation}
//               itemName={itemName}
//               selectedItem={selectedItem}
//               setSelectedItem={setSelectedItem}
//             />
//           </Grid>
//           <Grid container spacing={2} alignItems="flex-end">
//             <Grid item xs={12} sm={4}>
//               <LanguageSelect language={language} setLanguage={setLanguage} />
//             </Grid>
//             <Grid item xs={12} sm={4}>
//               <TextField
//                 multiline
//                 placeholder="Enter keywords"
//                 fullWidth
//                 value={keywords}
//                 error={Boolean(keywordsError)}
//                 helperText={keywordsError}
//                 onChange={(e) => {
//                   const wordLimit = e.target.value
//                     .split(" ")
//                     .filter((n) => n).length;
//                   if (wordLimit <= 10) {
//                     setKeywords(e.target.value);
//                     setKeywordsError("");
//                   } else {
//                     setKeywordsError(
//                       "Keywords should not contain more than 10 words."
//                     );
//                   }
//                 }}
//                 sx={{ mb: 1.5 }}
//               />
//             </Grid>

//             <Grid item xs={12} sm={4}>
//               <Button
//                 disableElevation
//                 variant="contained"
//                 type="submit"
//                 sx={{
//                   color: "white",
//                   width: "100%",
//                   padding: "1.1em",
//                   mb: 1.5,
//                 }}
//                 disabled={buttonDisabled}
//               >
//                 {buttonText}
//               </Button>
//             </Grid>
//           </Grid>
//         </form>

//         {/* Card component */}
//         <Card
//           sx={{
//             p: 2,
//             border: 1,
//             boxShadow: 0,
//             borderColor: theme.palette.divider,
//             borderRadius: 2,
//             height: "500px",
//             bgcolor: "background.default",
//             position: "relative",
//             overflow: loading ? "hidden" : "auto",
//             display: "flex",
//             alignItems: "center",
//           }}
//         >
//           {/* Top border with label and copy button */}
//           {aiText && (
//             <Box
//               sx={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "space-between",
//                 height: "50px",
//                 position: "absolute",
//                 top: -16,
//                 left: 0,
//                 right: 0,
//                 bgcolor: theme.palette.background.level1,
//                 px: 1,
//                 zIndex: 1,
//                 borderBottom: `1px solid ${theme.palette.divider}`,
//               }}
//             >
//               <Typography
//                 variant="body2"
//                 component="span"
//                 sx={{
//                   marginTop: 1.8,
//                   marginLeft: 1,
//                   color: "currentColor",
//                   fontWeight: 500,
//                 }}
//               >
//                 {label}
//               </Typography>
//               <Box
//                 sx={{
//                   display: "flex",
//                   alignItems: "center",
//                   cursor: "pointer",
//                 }}
//                 onClick={copyToClipboardHtml}
//               >
//                 {copied ? (
//                   <DoneOutlinedIcon
//                     color="theme.palette.text.primary"
//                     style={{ marginRight: 1, marginTop: 12 }}
//                   />
//                 ) : (
//                   <ContentPasteOutlinedIcon
//                     color="theme.palette.text.primary"
//                     style={{ marginRight: 1, marginTop: 12 }}
//                   />
//                 )}
//                 <Typography
//                   variant="body2"
//                   component="span"
//                   sx={{
//                     marginLeft: 1,
//                     marginRight: 1,
//                     marginTop: 1.8,
//                     color: "currentColor",
//                     fontWeight: 500,
//                   }}
//                 >
//                   {copied ? "Copied!" : "Copy text"}
//                 </Typography>
//               </Box>
//             </Box>
//           )}

//           {/* Content */}
//           {loading ? (
//             <Box
//               sx={{
//                 textAlign: "center",
//                 position: "absolute",
//                 top: "50%",
//                 left: "50%",
//                 transform: "translate(-50%, -50%)",
//               }}
//             >
//               <CircularProgress color="primary" />
//               <Typography variant="h6" color="text.primary" sx={{ mt: 2 }}>
//                 Loading...
//               </Typography>
//             </Box>
//           ) : (
//             <>
//               {aiText ? (
//                 <Box
//                   component="div"
//                   sx={{
//                     margin: 0,
//                     padding: "3rem 16px",
//                     marginBottom: "1.5rem",
//                     flexGrow: 1,
//                     position: "absolute",
//                     top: 0,
//                     left: 0,
//                     right: 0,
//                     bottom: 0,
//                     overflow: "auto",
//                     fontWeight: 400,
//                     fontSize: 14,
//                     lineHeight: 1.8,
//                   }}
//                   dangerouslySetInnerHTML={{ __html: aiText }}
//                 />
//               ) : (
//                 <Box
//                   sx={{
//                     position: "absolute",
//                     top: "50%",
//                     left: "50%",
//                     transform: "translate(-50%, -50%)",
//                     fontWeight: 500,
//                     fontSize: 20,
//                     textAlign: "center",
//                   }}
//                 >
//                   {aiPlaceholder}
//                 </Box>
//               )}
//             </>
//           )}
//         </Card>

//         {/* Card Actions */}
//         <Grid
//           container
//           justifyContent={matches ? "center" : "space-between"}
//           alignItems="center"
//           sx={{ marginTop: 2, marginBottom: 2 }}
//         >
//           <Grid
//             item
//             xs={matches ? 12 : "auto"}
//             style={{ textAlign: matches ? "center" : "left" }}
//           >
//             <Typography>
//               Not the tool you were looking for?{" "}
//               <Link href="/dashboard">Go back</Link>
//             </Typography>
//           </Grid>
//           <Grid
//             item
//             xs={matches ? 12 : "auto"}
//             style={{ textAlign: matches ? "center" : "right" }}
//           >
//             <ButtonGroup variant="contained" color="primary">
//               <Button
//                 variant="contained"
//                 color="inherit"
//                 onClick={handleDownload}
//               >
//                 Download PDF
//               </Button>
//               <Button variant="contained" color="inherit" onClick={handlePrint}>
//                 Print
//               </Button>
//               <Button variant="contained" color="primary" onClick={resetAll}>
//                 Reset
//               </Button>
//             </ButtonGroup>
//           </Grid>
//         </Grid>

//         <PrintDownErrorDialog
//           open={isPrintDownErrorDialogOpen}
//           onClose={() => setPrintDownErrorDialogOpen(false)}
//         />
//         <GeneratingDialog open={loading} />
//         <MonthlyWordCountErrorDialog
//           open={dialogOpen}
//           onClose={() => setDialogOpen(false)}
//           errorMessage="Monthly word limit exceeded. Please wait until the next reset or upgrade your subscription."
//         />
//       </Box>
//     );
//   };
// }

// export default createOptLangKnowKeyNoBibleTemplate;


import React, { useRef, useState, useEffect } from "react";
import { io } from "socket.io-client";

import {
  Box,
  Link,
  Typography,
  useTheme,
  useMediaQuery,
  Collapse,
  Alert,
  TextField,
  Button,
  Card,
  CircularProgress,
  Grid,

  ButtonGroup,
} from "@mui/material";

import instance from "../../../helpers/axiosInstance";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import MonthlyWordCountErrorDialog from "../Dialog/monthlyWordCountErrorDialog";
import LanguageSelect from "../../../helpers/LanguageSelect";
import KnowledgeBase from "../../../helpers/KnowledgeBase";
import ToneOfVoice from "../../../helpers/ToneOfVoice";
import downloadPdf from "../../../helpers/downloadPdf";
import printDocument from "../../../helpers/printDocument";
import PrintDownErrorDialog from "../Dialog/PrintDownErrorDialog";

import {ControlRowWithKnowledge} from "../../../utils/ControlRow";

import { auth } from '../../../firebase';
import ControlRowKnowledgeOnly from "../../../utils/ControlRowKnowledgeOnly";

export function createOptLangKnowToneKeyTemplate({
  name,
  placeholderText,
  label,
  title,
  subText,
  buttonText,
  aiPlaceholder,
}) {
  return function OptLangKnowToneKeyScreen() {
    const theme = useTheme();
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    
    const contentRef = useRef(null);
    const [keywords, setKeywords] = useState("");
    const [topic, setTopic] = useState("");

    const [error, setError] = useState("");
    const [aiText, setAiText] = useState("");
    const [aiTextPlainText, setAiTextPlainText] = useState("");
    const [information, setInformation] = useState("");
    const [informationError, setInformationError] = useState("");
    const [loading, setLoading] = useState(false);
    const [isPrintDownErrorDialogOpen, setPrintDownErrorDialogOpen] =
      useState(false);
    const [copied, setCopied] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [topicError, setTopicError] = useState("");
    const [keywordsError, setKeywordsError] = useState("");
    const [language, setLanguage] = useState("English (American)");
    const [churchType, setChurchType] = useState("Catholic");

    const [organisationName, setOrganisationName] = useState([]);
    const [selectedOrganisation, setSelectedOrganisation] = useState("");
    const [itemName, setItemName] = useState([]);
    const [selectedItem, setSelectedItem] = useState("");
    const [useKnowledgeBase, setUseKnowledgeBase] = useState(false);

    const [modelType, setModelType] = useState("gpt");

    const [socket, setSocket] = useState(null);
    const [isInitializing, setIsInitializing] = useState(false);
    const [isStreaming, setIsStreaming] = useState(false);
    const [isLoading, setIsLoading] = useState(false);



    // Socket setup - keep this exactly as is

    useEffect(() => {
      const setupSocket = async () => {
        try {
          const user = auth.currentUser;
          if (!user) {
            setError("Authentication required");
            return null;
          }
     
          const token = await user.getIdToken();
          const socketUrl = process.env.NODE_ENV === 'production' 
            ? process.env.REACT_APP_API_URL 
            : "http://localhost:4242";
     
          console.log("Connecting to socket at:", socketUrl);
          
          const newSocket = io(socketUrl, {
            auth: { token },
            transports: ["websocket"],
            withCredentials: true,
            autoConnect: true,
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: 5
          });
     
          newSocket.on("connect", () => {
            console.log("Socket connected with ID:", newSocket.id);
            setError("");
          });
     
          newSocket.on("connect_error", (err) => {
            console.error("Socket connection error:", err);
            setError(`Connection error: ${err.message}`);
          });
     
          newSocket.on("disconnect", (reason) => {
            console.log("Socket disconnected:", reason);
            if (reason === "io server disconnect") {
              newSocket.connect();
            }
          });
     
          return newSocket;
        } catch (error) {
          console.error("Socket setup error:", error);
          setError(`Setup error: ${error.message}`);
          return null;
        }
      };
     
      setupSocket().then(socket => {
        if (socket) {
          setSocket(socket);
          return () => {
            console.log("Cleaning up socket connection");
            socket.disconnect();
          };
        }
      });
     }, [name, label]);
     
  

  
      useEffect(() => {
        if (!socket) return;
      
        const handleProgress = ({ content }) => {
          if (isInitializing) {
            setIsInitializing(false);
            setIsStreaming(true);
            setIsLoading(false);
          }
      
          // Clean the content before adding it to aiText
          let cleanContent = content;
          if (isInitializing || !aiText) {
            // Only clean html prefix for first chunk
            cleanContent = content.replace(/^html/i, '');
          }
          
          // Remove closing backticks at any point
          cleanContent = cleanContent.replace(/```\s*$/g, '');
      
          setAiText(prevText => prevText + cleanContent);
        };
      
        const handleComplete = () => {
          // Clean up any remaining closing backticks when generation is complete
          setAiText(prevText => prevText.replace(/```\s*$/g, ''));
          setIsStreaming(false);
          setButtonDisabled(false);
          setIsLoading(false);
        };
      
        socket.on('generation-progress', handleProgress);
        socket.on('generation-complete', handleComplete);
        
        return () => {
          socket.off('generation-progress', handleProgress);
          socket.off('generation-complete', handleComplete);
        };
      }, [socket, isInitializing, aiText]);

    useEffect(() => {
      if (contentRef.current && aiText) {
        const scrollElement = contentRef.current;
        const isNearBottom = scrollElement.scrollHeight - scrollElement.scrollTop <= scrollElement.clientHeight + 100;
        
        if (isNearBottom) {
          scrollElement.scrollTop = scrollElement.scrollHeight;
        }
      }
    }, [aiText]);


    const handleChurchTypeChange = (event) => {
      setChurchType(event.target.checked ? "Protestant" : "Catholic");
    };

    useEffect(() => {}, [selectedOrganisation, selectedItem]);

    useEffect(() => {
      const fetchOrganisationName = async () => {
        try {
          // Only fetch if knowledge base is enabled
          if (useKnowledgeBase) {
            const token = localStorage.getItem("authToken");
            const config = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };
    
            const { data } = await instance.get(
              "/api/knowledgebase/organisationName",
              config
            );
            
            if (data.organisationName) {
              setOrganisationName(data.organisationName);
              console.log("Fetched organization names:", data.organisationName);
            } else {
              setOrganisationName([]);
              console.log("No organization names available");
            }
          } else {
            // If knowledge base is not enabled, set empty array
            setOrganisationName([]);
          }
        } catch (error) {
          console.error("Error fetching organization names:", error);
          // Set empty array on error to prevent UI issues
          setOrganisationName([]);
          
          // Optional: Show error to user if it's not the "not defined" error
          if (error.originalError !== 'organisationName is not defined') {
            setError(error.message || "Failed to fetch organization names");
          }
        }
      };
    
      fetchOrganisationName();
    }, [useKnowledgeBase]);


    useEffect(() => {
      const fetchItemName = async () => {
        try {
          if (selectedOrganisation) {
            const token = localStorage.getItem("authToken");
            const config = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };
            console.log("Selected Organisation):", selectedOrganisation);

            console.log(
              `Sending request for organisation again: ${selectedOrganisation}, item: ${selectedItem}`
            );

            const { data } = await instance.get(
              `/api/knowledgebase/items?organisationName=${selectedOrganisation}`,
              config
            );
            setItemName(data.itemName);
            console.log(data.itemName);
          }
        } catch (error) {
          console.error(error);
        }
      };
      console.log("Selected organisation updated:", selectedOrganisation);

      fetchItemName();
    }, [selectedOrganisation, instance]);

    
    const aiTextHandler = async (e) => {
      e.preventDefault();
      
      setAiText(''); 
      setIsInitializing(true);
      setIsStreaming(false);
      setError("");
      setButtonDisabled(true);
      setIsLoading(true);
    
      if (!topic) {
        setTopicError("Please input text.");
        setButtonDisabled(false);
        setIsLoading(false);
        return;
      }
    
      if (topic.split(" ").filter((n) => n).length < 1) {
        setTopicError("Text should contain at least 1 word.");
        setButtonDisabled(false);
        setIsLoading(false);
        return;
      }
    
    
    
      try {
        const payload = {
          keywords,
          topic,
          language,
          churchType,
          information,
          useMyKnowledgeBase: useKnowledgeBase,
          toolName: name,
          model: modelType,

        };
    
        if (useKnowledgeBase) {
          payload.organisationName = selectedOrganisation;
          payload.itemName = selectedItem;
        }
    
        // Emit the generation request
        socket.emit("start-generation", payload);
      } catch (err) {
        console.error("Error starting generation:", err);
        setError("Error starting generation. Please try again.");
        setIsInitializing(false);
        setButtonDisabled(false);
        setIsLoading(false);
      }
    };
    const copyToClipboardHtml = () => {
      const htmlContent = aiText; // This should be your HTML-formatted string
      const tempElement = document.createElement("div");
      tempElement.style.position = "absolute";
      tempElement.style.left = "-9999px";
      tempElement.innerHTML = htmlContent;
      document.body.appendChild(tempElement);

      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(tempElement);
      selection.removeAllRanges();
      selection.addRange(range);

      try {
        document.execCommand("copy");
        setCopied(true);
        setTimeout(() => setCopied(false), 5000);
      } catch (err) {
        console.error("Error copying HTML content: ", err);
      }

      document.body.removeChild(tempElement);
      selection.removeAllRanges();
    };


    const handleDownload = () => {
      if (!contentRef || !contentRef.current) {
        setPrintDownErrorDialogOpen(true);
        return;
      }

      downloadPdf(contentRef);
    };

    const handlePrint = () => {
      if (!contentRef || !contentRef.current) {
        setPrintDownErrorDialogOpen(true);
        return;
      }

      printDocument(contentRef);
    };

    // Define the resetAll function to reset all the states
    const resetAll = () => {
      setTopic("");
      setAiText("");
      setAiTextPlainText("");
      setError("");
      setLoading(false);
      setButtonDisabled(false);
      setLanguage("English (American)");
      setInformation("");
      setChurchType("Catholic");
      setUseKnowledgeBase("");
    };

    const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    return (
      <Box
        width={isNotMobile ? "70%" : "90%"}
        p="2rem"
        m="2rem auto"
        borderRadius={5}
        backgroundColor={theme.palette.background.alt}
        sx={{ boxShadow: 5 }}
      >
        <Collapse in={error}>
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        </Collapse>
        <form onSubmit={aiTextHandler}>
          <Typography variant="h5" mb={2}>
            {title}
          </Typography>
          <Typography variant="body2" mb={2}>
            {subText}
          </Typography>
          <ControlRowKnowledgeOnly
  modelType={modelType}
  setModelType={setModelType}
  churchType={churchType}
  handleChurchTypeChange={handleChurchTypeChange}
  useKnowledgeBase={useKnowledgeBase}
  setUseKnowledgeBase={setUseKnowledgeBase}
/>
         
          
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12}>
              <TextField
                placeholder={placeholderText}
                fullWidth
                value={topic}
                error={Boolean(topicError)}
                helperText={topicError}
                onChange={(e) => {
                  const wordCount = e.target.value
                    .split(" ")
                    .filter((n) => n).length;
                  if (wordCount <= 100) {
                    setTopic(e.target.value);
                    setTopicError("");
                  } else {
                    setTopicError(
                      "Topic should not contain more than 100 words."
                    );
                  }
                }}
                sx={{ mb: 1.5 }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12}>
              <TextField
                multiline
                rows={3}
                placeholder="Input any additional information (optional)"
                fullWidth
                value={information}
                error={Boolean(informationError)}
                helperText={informationError}
                onChange={(e) => {
                  const wordCount = e.target.value
                    .split(" ")
                    .filter((n) => n).length;
                  if (wordCount <= 500) {
                    setInformation(e.target.value);
                    setInformationError("");
                  } else {
                    setInformationError(
                      "Information should not contain more than 500 words."
                    );
                  }
                }}
                sx={{ mb: 1.5 }}
              />
            </Grid>
          </Grid>
         
          <Grid container spacing={2} alignItems="flex-end">
            <KnowledgeBase
              useKnowledgeBase={useKnowledgeBase}
              organisationName={organisationName}
              selectedOrganisation={selectedOrganisation}
              setSelectedOrganisation={setSelectedOrganisation}
              itemName={itemName}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
            />
          </Grid>
          <Grid container spacing={2} alignItems="flex-end">
            
            <Grid item xs={12} sm={4}>
              <LanguageSelect language={language} setLanguage={setLanguage} />
            </Grid>
     
            <Grid item xs={12} sm={4}>
              <TextField
                multiline
                placeholder="Enter keywords"
                fullWidth
                value={keywords}
                error={Boolean(keywordsError)}
                helperText={keywordsError}
                onChange={(e) => {
                  const wordLimit = e.target.value
                    .split(" ")
                    .filter((n) => n).length;
                  if (wordLimit <= 10) {
                    setKeywords(e.target.value);
                    setKeywordsError("");
                  } else {
                    setKeywordsError(
                      "Keywords should not contain more than 10 words."
                    );
                  }
                }}
                sx={{ mb: 1.5 }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Button
                disableElevation
                variant="contained"
                type="submit"
                sx={{
                  color: "white",
                  width: "100%",
                  padding: "1.1em",
                  mb: 1.5,
                }}
                disabled={buttonDisabled}
              >
                {buttonText}
              </Button>
            </Grid>
          </Grid>

        </form>

        {/* Card component */}
        <Card
  sx={{
    p: 2,
    border: 1,
    boxShadow: 0,
    borderColor: theme.palette.divider,
    borderRadius: 2,
    height: "500px",
    bgcolor: theme.palette.background.alt,
    position: "relative",
    overflow: 'auto'
  }}
>
{isLoading && (
    <Box sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      zIndex: 2
    }}>
      <CircularProgress color="primary" />
      <Typography variant="h6" color="text.primary" sx={{ mt: 2 }}>
        Generating...
      </Typography>
    </Box>
  )}
  {/* Top border with label and copy button */}
  {aiText && (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "50px",
        position: "sticky",
        top: 0,
        bgcolor: theme.palette.background.level1,
        px: 1,
        zIndex: 1,
        borderBottom: `1px solid ${theme.palette.divider}`,
      }}
    >
              <Typography
                variant="body2"
                component="span"
                sx={{
                  marginTop: 1.8,
                  marginLeft: 1,
                  color: "currentColor",
                  fontWeight: 500,
                }}
              >
                {label}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={copyToClipboardHtml}
              >
                {copied ? (
                  <DoneOutlinedIcon
                    color="theme.palette.text.primary"
                    style={{ marginRight: 1, marginTop: 12 }}
                  />
                ) : (
                  <ContentPasteOutlinedIcon
                    color="theme.palette.text.primary"
                    style={{ marginRight: 1, marginTop: 12 }}
                  />
                )}
                <Typography
                  variant="body2"
                  component="span"
                  sx={{
                    marginLeft: 1,
                    marginRight: 1,
                    marginTop: 1.8,
                    color: "currentColor",
                    fontWeight: 500,
                  }}
                >
                  {copied ? "Copied!" : "Copy text"}
                </Typography>
              </Box>
            </Box>
          )}

          {/* Content */}
{loading ? (
  <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      zIndex: 2
    }}
  >
    <CircularProgress color="primary" />
    <Typography variant="h6" color="text.primary" sx={{ mt: 2 }}>
      Loading...
    </Typography>
  </Box>
) : (
  <>
    <Box
      component="div"
      ref={contentRef}
      sx={{
        margin: 0,
        padding: '1rem 16px',
        height: aiText ? '100%' : 'auto',
        overflow: 'auto',
        fontWeight: 500,
        fontSize: 16,
        lineHeight: 1.8,
                bgcolor: theme.palette.background.level1,

        visibility: loading ? 'hidden' : 'visible',
        position: 'relative',
        minHeight: '100%',
    '& h1, & h2, & h3, & h4, & h5, & h6': {
      lineHeight: 1.4,
      marginBottom: '0.5em',
      marginTop: '1em'
    },
    '& p': {
      marginBottom: '1em',
      lineHeight: 1.5
    }
      }}
    >
      {aiText ? (
        <div
          dangerouslySetInnerHTML={{ __html: aiText }}
          style={{ 
            transition: 'none',
            WebkitTransition: 'none'
          }}
        />
      ) : (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontWeight: 500,
            fontSize: 20,
            textAlign: "center",
          }}
        >
          {aiPlaceholder}
        </Box>
      )}
    </Box>
  </>
)}
</Card>

        {/* Card Actions */}
        <Grid
          container
          justifyContent={matches ? "center" : "space-between"}
          alignItems="center"
          sx={{ marginTop: 2, marginBottom: 2 }}
        >
          <Grid
            item
            xs={matches ? 12 : "auto"}
            style={{ textAlign: matches ? "center" : "left" }}
          >
            <Typography>
              Not the tool you were looking for?{" "}
              <Link href="/dashboard">Go back</Link>
            </Typography>
          </Grid>
          <Grid
            item
            xs={matches ? 12 : "auto"}
            style={{ textAlign: matches ? "center" : "right" }}
          >
            <ButtonGroup variant="contained" color="primary">
              <Button
                variant="contained"
                color="inherit"
                onClick={handleDownload}
              >
                Download PDF
              </Button>
              <Button variant="contained" color="inherit" onClick={handlePrint}>
                Print
              </Button>
              <Button variant="contained" color="primary" onClick={resetAll}>
                Reset
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>

        <PrintDownErrorDialog
          open={isPrintDownErrorDialogOpen}
          onClose={() => setPrintDownErrorDialogOpen(false)}
        />
        <MonthlyWordCountErrorDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          errorMessage="Monthly word limit exceeded. Please wait until the next reset or upgrade your subscription."
        />
      </Box>
    );
  };
}

export default createOptLangKnowToneKeyTemplate;
