// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { useFormik } from "formik";
// import * as yup from "yup";
// import instance from "../../../../../helpers/axiosInstance";

// import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid";
// import TextField from "@mui/material/TextField";
// import Button from "@mui/material/Button";
// import Link from "@mui/material/Link";
// import Typography from "@mui/material/Typography";
// import IconButton from "@mui/material/IconButton";
// import InputAdornment from "@mui/material/InputAdornment";
// import RegisterDialogComponent from "../../../Dialog/RegisterDialog";
// import EmailVerificationErrorDialog from "../../../Dialog/EmailVerificationErrorDialog";
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";



// const validationSchema = yup.object({
//   email: yup
//     .string()
//     .trim()
//     .email("Please enter a valid email address")
//     .required("Email is required."),
//   password: yup
//     .string()
//     .required("Please specify your password")
//     .min(8, "The password should have a minimum length of 8"),
// });

// // const Form = () => {
// //   const navigate = useNavigate();
// //   const [error, setError] = useState("");
// //   const [showPassword, setShowPassword] = useState(false);
// //   const [openRegister, setOpenRegister] = useState(false);
// //   const [openResendVerification, setOpenResendVerification] = useState(false);
// //   const [unverifiedEmail, setUnverifiedEmail] = useState("");

// //   const handleClickShowPassword = () => setShowPassword(!showPassword);

// //   const formik = useFormik({
// //     initialValues: {
// //       email: "",
// //       password: "",
// //     },
// //     validationSchema: validationSchema,
// //     onSubmit: async (values) => {
// //       const { email, password } = values;

// //       try {
// //         const response = await instance.post("/api/auth/login", {
// //           email,
// //           password,
// //         });

// //         if (response.data && response.data.success) {
// //           const { token, user } = response.data;

// //           if (user && token && token.accessToken) {
// //             if (String(user.emailVerified) === "true") {
// //               instance.defaults.headers.common[
// //                 "Authorization"
// //               ] = `Bearer ${token.accessToken}`;
// //               localStorage.setItem("authToken", `Bearer ${token.accessToken}`); // Add this line
// //               navigate("/", { replace: true });
// //               window.location.reload();

// //               if (token.refreshToken) {
// //                 document.cookie = `refreshToken=${token.refreshToken}; path=/`;
// //               }
// //             } else {
// //               setUnverifiedEmail(user.email);
// //               setOpenResendVerification(true);
// //             }
// //           }
// //         }
// //       } catch (err) {
// //         if (err === "That email is not registered") {
// //           setOpenRegister(true);
// //         } else {
// //           console.error(err);
// //         }
// //       }
// //     },
// //   });

// const Form = () => {
//   const navigate = useNavigate();
//   const [error, setError] = useState("");
//   const [showPassword, setShowPassword] = useState(false);
//   const [openRegister, setOpenRegister] = useState(false);
//   const [openResendVerification, setOpenResendVerification] = useState(false);
//   const [unverifiedEmail, setUnverifiedEmail] = useState("");

//   const handleClickShowPassword = () => setShowPassword(!showPassword);

//   const formik = useFormik({
//     initialValues: {
//       email: "",
//       password: "",
//     },
//     validationSchema: validationSchema,
//     onSubmit: async (values) => {
//       const { email, password } = values;

//       try {
//         const response = await instance.post("/api/auth/login", {
//           email,
//           password,
//         });

//         if (response.data && response.data.success) {
//           const { token, user } = response.data;

//           if (user && token && token.accessToken) {
//             if (String(user.emailVerified) === "true") {
//               // Store token and set axios header
//               localStorage.setItem("authToken", token.accessToken);
// instance.defaults.headers.common["Authorization"] = `Bearer ${token.accessToken}`;

//               // Handle refresh token
//               if (token.refreshToken) {
//                 document.cookie = `refreshToken=${token.refreshToken}; path=/; secure; samesite=strict`;
//               }


//               // Navigate after everything is set up
//               navigate("/");
//             } else {
//               setUnverifiedEmail(user.email);
//               setOpenResendVerification(true);
//             }
//           }
//         }
//       } catch (err) {
//         console.error("Login error:", err);
//         if (err.response?.data?.message === "That email is not registered") {
//           setOpenRegister(true);
//         } else {
//           setError(err.response?.data?.message || "Login failed");
//         }
//       }
//     },
//   });

//   return (
//     <Box>
//       <Box marginBottom={4}>
//         <Typography
//           sx={{
//             textTransform: "uppercase",
//             fontWeight: "medium",
//           }}
//           gutterBottom
//           color={"text.secondary"}
//         >
//           Login
//         </Typography>
//         <Typography
//           variant="h4"
//           sx={{
//             fontWeight: 700,
//           }}
//         >
//           Welcome back
//         </Typography>
//         <Typography color="text.secondary">
//           Login to manage your account.
//         </Typography>
//       </Box>
//       <form onSubmit={formik.handleSubmit}>
//         <Grid container spacing={4}>
//           <Grid item xs={12}>
//             <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
//               Enter your email
//             </Typography>
//             <TextField
//               label="Email *"
//               variant="outlined"
//               name={"email"}
//               fullWidth
//               value={formik.values.email}
//               onChange={formik.handleChange}
//               error={formik.touched.email && Boolean(formik.errors.email)}
//               helperText={formik.touched.email && formik.errors.email}
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
//               Enter your password
//             </Typography>
//             <TextField
//               label="Password *"
//               variant="outlined"
//               name={"password"}
//               type={showPassword ? "text" : "password"}
//               fullWidth
//               value={formik.values.password}
//               onChange={formik.handleChange}
//               error={formik.touched.password && Boolean(formik.errors.password)}
//               helperText={formik.touched.password && formik.errors.password}
//               InputProps={{
//                 endAdornment: (
//                   <InputAdornment position="end">
//                     <IconButton
//                       aria-label="toggle password visibility"
//                       onClick={handleClickShowPassword}
//                       edge="end"
//                     >
//                       {showPassword ? <Visibility /> : <VisibilityOff />}
//                     </IconButton>
//                   </InputAdornment>
//                 ),
//               }}
//             />
//             <Typography variant={"subtitle2"}>
//               <Link
//                 component={"a"}
//                 color={"primary"}
//                 href={"/forgot-password"}
//                 underline={"none"}
//               >
//                 Forgot your password?
//               </Link>
//             </Typography>
//           </Grid>
//           <Grid item container xs={12}>
//             <Button size={"large"} variant={"contained"} type={"submit"}>
//               Login
//             </Button>
//           </Grid>
//         </Grid>
//       </form>

//       <RegisterDialogComponent
//         open={openRegister}
//         handleClose={() => setOpenRegister(false)}
//         navigate={navigate}
//       />
//       <EmailVerificationErrorDialog
//         open={openResendVerification}
//         handleClose={() => setOpenResendVerification(false)}
//         email={unverifiedEmail}
//       />
//     </Box>
//   );
// };

// export default Form;


import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../../../firebase';
import instance from "../../../../../helpers/axiosInstance";

import { useSetRecoilState } from 'recoil';
import { authState } from '../../../../../atoms';

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Alert from "@mui/material/Alert";

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
  password: yup
    .string()
    .required("Please specify your password")
    .min(8, "The password should have a minimum length of 8"),
});

const Form = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const setAuthState = useSetRecoilState(authState);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    // onSubmit: async (values) => {
    //   try {
    //     // Firebase authentication
    //     const userCredential = await signInWithEmailAndPassword(
    //       auth,
    //       values.email,
    //       values.password
    //     );
        
    //     if (userCredential.user) {
    //       // Get the ID token
    //       const idToken = await userCredential.user.getIdToken();
    //       // Store the token
    //       localStorage.setItem("authToken", idToken);

    //       // Check if email is verified
    //       if (userCredential.user.emailVerified) {
    //         // Get user data from your backend
    //         try {
    //           const response = await instance.get('/api/auth/user', {
    //             headers: {
    //               Authorization: `Bearer ${idToken}`
    //             }
    //           });

    //           if (response.data) {
    //             // Update auth state
    //             setAuthState({
    //               user: {
    //                 email: response.data.email,
    //                 firstName: response.data.firstName,
    //                 lastName: response.data.lastName,
    //                 avatar: response.data.avatar,
    //                 subscription: response.data.subscription,
    //               },
    //               accessToken: idToken,
    //               isAuthenticated: true,
    //               isLoading: false,
    //               emailVerified: userCredential.user.emailVerified
    //             });

    //             navigate("/dashboard");
    //           }
    //         } catch (error) {
    //           console.error("Error fetching user data:", error);
    //           setError("Error fetching user data. Please try again.");
    //         }
    //       } else {
    //         setError("Please verify your email before logging in.");
    //       }
    //     }

    onSubmit: async (values) => {
      try {
        // Firebase authentication
        const userCredential = await signInWithEmailAndPassword(
          auth, 
          values.email,
          values.password
        );
   
        if (!userCredential.user.emailVerified) {
          setError("Please verify your email before logging in.");
          return;
        }
   
        // Get Firebase ID token
        const idToken = await userCredential.user.getIdToken();
        localStorage.setItem("authToken", idToken);
   
        // Login with backend
        const loginResponse = await instance.post('/api/auth/login', {
          token: idToken
        });
   
        if (!loginResponse.data.success) {
          throw new Error(loginResponse.data.message || 'Login failed');
        }
   
        // Set authentication state
        setAuthState({
          user: loginResponse.data.user,
          accessToken: idToken,
          isAuthenticated: true,
          isLoading: false,
          emailVerified: userCredential.user.emailVerified
        });
   
        navigate("/dashboard");
   
      } catch (error) {
        console.error("Login error:", error);
        
        switch (error.code) {
          case 'auth/user-not-found':
            setError("No account found with this email.");
            break;
          case 'auth/wrong-password':
            setError("Invalid password.");
            break;
          case 'auth/invalid-email':
            setError("Invalid email address.");
            break;
          case 'auth/too-many-requests':
            setError("Too many failed attempts. Please try again later.");
            break;
          default:
            setError(error.message || "An error occurred during login. Please try again.");
        }
      }
    },
   });

  //   onSubmit: async (values) => {
  //     try {
  //       const userCredential = await signInWithEmailAndPassword(auth, values.email, values.password);
  //       const idToken = await userCredential.user.getIdToken();
        
  //       // First login to get session
  //       const loginResponse = await instance.post('/api/auth/login', { token: idToken });
  //       if (!loginResponse.data.success) throw new Error('Login failed');
    
  //       // Then get user data
  //       const userResponse = await instance.get('/api/auth/user');
  //       const userData = userResponse.data;
    
  //       setAuthState({
  //         user: userData,
  //         accessToken: idToken,
  //         isAuthenticated: true,
  //         isLoading: false,
  //         emailVerified: userCredential.user.emailVerified
  //       });
    
  //       navigate("/dashboard");
  //     } catch (error) {
  //       console.error("Login error:", error);
  //       setError(error.message);
  //     }
    
  //     } catch (error) {
  //       console.error("Login error:", error);
  //       switch (error.code) {
  //         case 'auth/user-not-found':
  //           setError("No account found with this email.");
  //           break;
  //         case 'auth/wrong-password':
  //           setError("Invalid password.");
  //           break;
  //         case 'auth/invalid-email':
  //           setError("Invalid email address.");
  //           break;
  //         case 'auth/too-many-requests':
  //           setError("Too many failed attempts. Please try again later.");
  //           break;
  //         default:
  //           setError("An error occurred during login. Please try again.");
  //       }
  //     }
  //   },
  // });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium",
          }}
          gutterBottom
          color={"text.secondary"}
        >
          Login
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Welcome back
        </Typography>
        <Typography color="text.secondary">
          Login to manage your account.
        </Typography>
      </Box>

      {error && (
        <Box mb={3}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your email
            </Typography>
            <TextField
              label="Email *"
              variant="outlined"
              name={"email"}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your password
            </Typography>
            <TextField
              label="Password *"
              variant="outlined"
              name={"password"}
              type={showPassword ? "text" : "password"}
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Typography variant={"subtitle2"} sx={{ mt: 1 }}>
              <Link
                component={"a"}
                color={"primary"}
                href={"/forgot-password"}
                underline={"none"}
              >
                Forgot your password?
              </Link>
            </Typography>
          </Grid>
          <Grid item container xs={12}>
            <Button 
              size={"large"} 
              variant={"contained"} 
              type={"submit"}
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? "Logging in..." : "Login"}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"}>
              Don't have an account yet?{" "}
              <Link
                component={"a"}
                color={"primary"}
                href={"/register"}
                underline={"none"}
              >
                Sign up here
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;