import React from "react";
import { colors } from "@mui/material";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { alpha } from "@mui/material";

const websitePageContentGenerator = {
  title: "Website Page Content Generator",
  subtitle: "Generate unique content that truly represents your ministry.",
  targetRoute: "/website-page-content-generator",
  color: colors.lightBlue[300],
  bgcolor: alpha(colors.lightBlue[300], 0.1),
  icon: <LanguageOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default websitePageContentGenerator;
