import createNoKeyOptChapLangToneTemplate from "../NoKeyOptChapLangToneTemplate";

const scriptureStudyPlanWomen = createNoKeyOptChapLangToneTemplate({
  name: "scripturestudyplanwomen",
  endpoint: "/api/openai/scripture-study-plan-women",
  aiText: "scriptureStudyPlanWomen",
  aiTextHandler: "scriptureStudyPlanWomenHandler",
  aiTextPlainText: "scriptureStudyPlanWomenPlainText",
  title: "Women's Scripture Study Planner",
  subText:
    "Create a Bible study plan specially designed for women. Input your favourite Biblical books or topics along with duration and receive a custom plan.",
  label: "Bible Study Plan for Women",
  placeholderText: "Enter study duration and key themes…",
  buttonText: "Generate Study Plan",
  aiPlaceholder: "Bible study plan for women will be displayed here",
});

export default scriptureStudyPlanWomen;
