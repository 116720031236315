import React from "react";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

const CurrencyDialogComponent = ({ open, handleClose, handleSelect }) => {
  const theme = useTheme();
  const [currency, setCurrency] = React.useState("");

  const flags = {
    usd: "🇺🇸",
    eur: "🇪🇺",
    gbp: "🇬🇧",
    jpy: "🇯🇵",
    cny: "🇨🇳",
    cad: "🇨🇦",
    inr: "🇮🇳",
  };

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleOk = () => {
    handleSelect(currency);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <Paper
        sx={{
          padding: theme.spacing(2),
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <DialogTitle>
          <Typography variant="h6" component="div">
            Select Currency
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth variant="outlined">
            <InputLabel
              id="demo-simple-select-label"
              shrink={currency !== ""}
              style={{
                backgroundColor: "white",
                paddingLeft: "2px",
                paddingRight: "2px",
                transform:
                  currency !== "" ? "translate(14px, -4px) scale(0.75)" : "",
              }}
            >
              Currency
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={currency}
              label="Currency"
              onChange={handleChange}
              renderValue={(selected) =>
                `${flags[selected]}  ${selected.toUpperCase()}`
              }
            >
              <MenuItem value={"usd"}>{flags["usd"]} &nbsp; USD</MenuItem>
              <MenuItem value={"eur"}>{flags["eur"]} &nbsp; EUR</MenuItem>
              <MenuItem value={"gbp"}>{flags["gbp"]} &nbsp; GBP</MenuItem>
              <MenuItem value={"jpy"}>{flags["jpy"]} &nbsp; JPY</MenuItem>
              <MenuItem value={"cny"}>{flags["cny"]} &nbsp; CNY</MenuItem>
              <MenuItem value={"cad"}>{flags["cad"]} &nbsp; CAD</MenuItem>
              <MenuItem value={"inr"}>{flags["inr"]} &nbsp; INR</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary" variant="contained">
            OK
          </Button>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default CurrencyDialogComponent;
