import createOptLangToneTemplate from "../OptLangToneTemplate";

const modernHymnMaker = createOptLangToneTemplate({
  name: "modernhymnmaker",
  endpoint: "/api/openai/modern-hymn-maker",
  aiText: "modernHymnMaker",
  aiTextHandler: "modernHymnMakerHandler",
  aiTextPlainText: "modernHymnMakerPlainText",
  title: "Modern Hymn Maker",
  subText:
    "Give old hymns a new life with Modern Hymn Maker. Input any hymn and get a refreshed, modern version.",
  label: "Modernized Hymn",
  placeholderText: "Which hymn would you like to modernize?",
  buttonText: "Modernize Hymn",
  aiPlaceholder: "Your modernized hymn will appear here",
});

export default modernHymnMaker;
