import createOptLangKnowKeyTemplate from "../OptLangKnowKeyTemplate";

const blogOutline = createOptLangKnowKeyTemplate({
  name: "blogoutline",
  endpoint: "/api/openai/blog-outline",
  aiText: "blogOutline",
  aiTextHandler: "blogOutlineHandler",
  aiTextPlainText: "blogOutlinePlainText",
  title: "Detailed Blog Outline Generator",
  subText:
    "Craft detailed blog outlines with our Outline Generator. Enter your topic and get a comprehensive blog outline with H2, H3, subheadings, and bullet points.",
  label: "Blog Outline",
  placeholderText: "Enter your blog's main topic…",
  buttonText: "Create Outline",
  aiPlaceholder: "Your blog outline will appear here",
});

export default blogOutline;
