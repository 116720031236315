import React from "react";
import { colors } from "@mui/material";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import { alpha } from "@mui/material";

const parishNewsletterCreator = {
  title: "Parish Newsletter Creator",
  subtitle: "Compose engaging newsletters to keep your parish informed.",
  targetRoute: "/parish-newsletter-creator",
  color: colors.teal[300],
  bgcolor: alpha(colors.teal[300], 0.1),
  icon: <MarkEmailReadOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default parishNewsletterCreator;
