import React from "react";
import { colors } from "@mui/material";
import VideoLibraryOutlinedIcon from "@mui/icons-material/VideoLibraryOutlined";
import { alpha } from "@mui/material";

const youtubeVideoScriptwriter = {
  title: "YouTube Scriptwriter",
  subtitle:
    "Turn your ideas into compelling scripts for your Church's YouTube channel.",
  targetRoute: "/youtube-scriptwriter",
  color: colors.red[700],
  bgcolor: alpha(colors.red[700], 0.1),

  icon: <VideoLibraryOutlinedIcon fontSize="medium" />,
  tags: [
    "YouTube",
    "script",
    "write script",
    "scriptwriter",
    "script writer",
    "scriptwriting",
    "script writing",
  ],
};

export default youtubeVideoScriptwriter;
