import createAiTextGenLang from "../AiTextGenLangTemplate";

const catechismReference = createAiTextGenLang({
  name: "catechismReference",
  endpoint: "/api/openai/catechism-reference",
  aiText: "catechismReference",
  aiTextHandler: "catechismReferenceHandler",
  aiTextPlainText: "catechismReferencePlainText",
  title: "Catechism Cross Referencer",
  subText:
    "Navigate the depth of Catholic doctrine with ease using our Catechism reference tool. Simply type in a topic like 'God's Love' and get the pertinent sections from the Catechism of the Catholic Church.",
  label: "CCC References",
  placeholderText: "Enter a topic for which you need CCC reference",
  buttonText: "Explore CCC",
  aiPlaceholder: "Your CCC references will appear here",
});

export default catechismReference;
