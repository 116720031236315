import createOptLangToneTemplate from "../OptLangToneTemplate";

const rapCreator = createOptLangToneTemplate({
  name: "rapcreator",
  endpoint: "/api/openai/rap-creator",
  aiText: "rapCreator",
  aiTextHandler: "rapCreatorHandler",
  aiTextPlainText: "rapCreatorPlainText",
  title: "Rap Creator",
  subText:
    "Create engaging, faith-inspired rap with the Rap Creator. Start with a theme, such as 'God's Forgiveness', and let the beat drop!",
  label: "Rap Lyrics",
  placeholderText: "Input a theme for your rap...",
  buttonText: "Compose Rap",
  aiPlaceholder: "Your rap verse will appear here",
});

export default rapCreator;
