import createOptLangKnowKeyNoBibleTemplate from "../OptLangKnowKeyNoBibleTemplate";

const familyMinistryBlogPostIdeas = createOptLangKnowKeyNoBibleTemplate({

  name: "familyministryblogpostideas",
  endpoint: "/api/openai/family-ministry-blog-post-ideas",
  aiText: "familyMinistryBlogPostIdeas",
  aiTextHandler: "familyMinistryBlogPostIdeasHandler",
  aiTextPlainText: "familyMinistryBlogPostIdeasPlainText",
  title: "Family Ministry Blog Post Ideas",
  subText:
    "Craft engaging blog post topics for your family ministry. Input your ministry's key areas and receive a list of unique post ideas.",
  label: "Family Ministry Blog Topics",
  placeholderText: "Enter your ministry's focus areas",
  buttonText: "Generate Ideas",
  aiPlaceholder: "Family ministry blog post ideas will appear here.",
});

export default familyMinistryBlogPostIdeas;
