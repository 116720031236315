//client/src/components/screens/KnowledgeBaseUpload.js
import React, { useState } from "react";
import { getAuth } from "firebase/auth";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import UploadingDialog from "./Dialog/UploadingDialog";
import instance from "../../helpers/axiosInstance";

function KnowledgeBaseUpload() {
  const [text, setText] = useState("");
  const [organisationName, setOrganisationName] = useState("");
  const [itemName, setItemName] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);

  const handleClose = () => {
    setShowSuccess(false);
  };

  const uploadText = async (e) => {
    e.preventDefault();
    setIsUploading(true);
    setError("");

    if (!text) {
      setError("No text input for upload.");
      setIsUploading(false);
      return;
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      
      if (!user) {
        setError("You must be logged in to upload");
        setIsUploading(false);
        return;
      }

      const data = {
        text,
        organisationName,
        itemName
      };

      const response = await instance.post(
        "/api/knowledgebase/upload-knowledge",
        data
      );

      if (response.status === 200) {
        // Reset form values
        setText("");
        setOrganisationName("");
        setItemName("");
        setShowSuccess(true);
      }
    } catch (error) {
      console.error(error);
      setError(error.response?.data?.message || "Failed to upload knowledge base");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={uploadText}
      sx={{ margin: { xs: "2rem", sm: "10rem" }, mt: 12 }}
    >
      <UploadingDialog open={isUploading} />
      
      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Knowledge base uploaded successfully!
        </Alert>
      </Snackbar>
      
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
        Upload your Knowledge Base
      </Typography>
      
      <Typography color="text.secondary">
        Fill out the form and press Upload.
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3} marginTop={2}>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Organisation Name"
            value={organisationName}
            onChange={(e) => setOrganisationName(e.target.value)}
          />
        </Grid>
        
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Item Name"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            label="Text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            multiline
            rows={4}
          />
        </Grid>
        
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={isUploading}
            sx={{ height: { xs: "auto", md: "56px" } }}
          >
            {isUploading ? "Uploading..." : "Upload"}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default KnowledgeBaseUpload;