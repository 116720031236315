import React from "react";
import { colors } from "@mui/material";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import { alpha } from "@mui/material";

const scriptureStudyPlanWomen = {
  title: "Women's Scripture Study Planner",
  subtitle: "Create a scripture study plan tailored for women.",
  targetRoute: "/scripture-study-plan-women",
  color: colors.pink[400],
  bgcolor: alpha(colors.pink[400], 0.1),
  icon: <LocalLibraryOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default scriptureStudyPlanWomen;
