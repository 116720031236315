/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from "react";
import { alpha, useTheme } from "@mui/material/styles";
import {
  Box,
  Container,
  Typography,
  Grid,
  IconButton,
  Card,
  Avatar,
} from "@mui/material";

import Fuse from "fuse.js";

import { useNavigate } from "react-router-dom";
import { checkSubscription } from "../../../helpers/subscriptionHelper";
import LoginDialog from "../Dialog/LoginDialog";
import SubscribeDialog from "../Dialog/SubscribeDialog";

import CtaSimpleCentered from "./CtaSimpleCentered/CtaSimpleCentered";

import {
  bibleGamesForFamilies,
  bibleQuizGenerator,
  bibleStoriesForKids,
  bibleStudyGuide,
  bibleStudyPlanGenerator,
  bibleTriviaGeneratorAdults,
  bibleTriviaGeneratorChildren,
  biblicalSongwriter,
  couplesBibleStudyGuide,
  familyScriptureStudyPlan,
  scriptureCrossReference,
  scriptureSays,
  scriptureStudyPlanMen,
  scriptureStudyPlanWomen,
} from "../cards/BibleTools/index";

import {
  canonLawReference,
  catechismReference,
  sacramentalPreparationGuide,
  saintBiography,
  saintSayings,
} from "../cards/CatholicTools/index";

import {
  analogyMaker,
  blogContentPlanner,
  blogOutline,
  blogTopicIdeas,
  blogWriter,
  longReflectionGenerator,
  lyricsGenerator,
  keywordResearch,
  mensMinistryBlogPostIdeas,
  modernHymnMaker,
  narrateToAChild,
  quoteGenerator,
  rapCreator,
  relatedTopicsResearch,
  sermon,
  shortReflectionGenerator,
  websiteContentPlanner,
  websitePageContentGenerator,
  weddingVowsGenerator,
  womensMinistryBlogPostIdeas,
} from "../cards/ContentTools/index";

import {
  couplesDevotionalPlanner,
  dailyDevotionsPlanner,
  hymnRecommender,
  parishEventPlanner,
  parishNewsletterCreator,
  songRecommender,
  youthMinistryEventPlanner,
} from "../cards/PlannerTools/index";

import {
  familyPrayerGenerator,
  prayerGenerator,
} from "../cards/PrayerTools/index";

import {
  marriageRetreatPlanner,
  retreatPlanner,
} from "../cards/RetreatTools/index";

import {
  contentPlanner,
  podcastCreator,
  socialMediaContentGenerator,
  youtubeScriptwriter,
  youtubeVideoDescription,
  YoutubeVideoIdeas,
  youtubeVideoTitle,
} from "../cards/SocialMediaTools/index";

import {
  blogWriterFromSpeech,
  summaryFromSpeech,
  bulletPointsFromSpeech,
} from "../cards/SpeechToTextTools/index";

const features = {
  "Bible Tools": [
    bibleGamesForFamilies,
    bibleQuizGenerator,
    bibleStoriesForKids,
    bibleStudyGuide,
    bibleStudyPlanGenerator,
    bibleTriviaGeneratorAdults,
    bibleTriviaGeneratorChildren,
    biblicalSongwriter,
    couplesBibleStudyGuide,
    familyScriptureStudyPlan,
    scriptureCrossReference,
    scriptureSays,
    scriptureStudyPlanMen,
    scriptureStudyPlanWomen,
  ],

  "Catholic Tools": [
    canonLawReference,
    catechismReference,
    sacramentalPreparationGuide,
    saintBiography,
    saintSayings,
  ],

  "Content Tools": [
    analogyMaker,
    blogContentPlanner,
    blogOutline,
    blogTopicIdeas,
    blogWriter,
    contentPlanner,
    keywordResearch,
    longReflectionGenerator,
    lyricsGenerator,
    mensMinistryBlogPostIdeas,
    modernHymnMaker,
    narrateToAChild,
    quoteGenerator,
    rapCreator,
    relatedTopicsResearch,
    sermon,
    shortReflectionGenerator,
    websiteContentPlanner,
    websitePageContentGenerator,
    weddingVowsGenerator,
    womensMinistryBlogPostIdeas,
  ],

  "Planner Tools": [
    couplesDevotionalPlanner,
    dailyDevotionsPlanner,
    hymnRecommender,
    parishEventPlanner,
    parishNewsletterCreator,
    songRecommender,
    youthMinistryEventPlanner,
  ],

  "Prayer Tools": [familyPrayerGenerator, prayerGenerator],

  "Retreat Tools": [marriageRetreatPlanner, retreatPlanner],

  "Social Media Tools": [
    contentPlanner,
    podcastCreator,
    socialMediaContentGenerator,
    youtubeScriptwriter,
    youtubeVideoDescription,
    YoutubeVideoIdeas,
    youtubeVideoTitle,
  ],

  // "Speech-to-Text Tools": [
  //   blogWriterFromSpeech,
  //   bulletPointsFromSpeech,
  //   summaryFromSpeech,
  // ],
};

const options = {
  includeScore: true,
  keys: ["title", "subtitle", "tags"],
};

const AllTools = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openSubscribeDialog, setOpenSubscribeDialog] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState(features);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);

  useEffect(() => {
    if (searchQuery) {
      const results = {};
      for (let category in features) {
        const fuse = new Fuse(features[category], options);
        const searchRes = fuse.search(searchQuery);
        results[category] = searchRes.map(({ item }) => item);
      }
      console.log(results);

      setSearchResults(results);
    } else {
      setSearchResults(features);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (localStorage.getItem("authToken")) {
      checkSubscription().then((status) => {
        setSubscriptionStatus(status);
      });
    }
  }, []); // empty dependency array makes this run once on mount

  const handleClickOpen = async (plan, targetRoute) => {
    if (localStorage.getItem("authToken")) {
      if (!subscriptionStatus) {
        setSelectedPlan(plan);
        setOpenSubscribeDialog(true); // Open SubscribeDialog
      } else {
        if (targetRoute) {
          navigate(targetRoute);
        }
      }
    } else {
      setOpenLoginDialog(true); // Open LoginDialog
    }
  };

  return (
    <>
      <CtaSimpleCentered
        searchQuery={searchQuery}
        onSearchChange={(event) => setSearchQuery(event.target.value)}
      />
      {Object.entries(searchResults).map(([category, cards], i) => (
        <Box
          key={i}
          sx={{
            backgroundImage: `linear-gradient(to bottom, ${alpha(
              theme.palette.background.paper,
              0
            )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
            backgroundRepeat: "repeat-x",
            position: "relative",
          }}
        >
          <Container
            sx={{
              paddingTop: theme.spacing(10), // Adds padding top of 16px
              paddingBottom: theme.spacing(10), // Adds padding bottom of 16px
              marginTop: theme.spacing(0), // Adds margin top of 16px
              marginBottom: theme.spacing(0), // Adds margin bottom of 16px
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                marginBottom: 3,
                fontWeight: "500",
              }}
            >
              {category}
            </Typography>
            <Grid container spacing={4}>
              {cards.map((item, j) => (
                <Grid item xs={12} sm={6} md={4} key={j}>
                  <IconButton
                    onClick={() => handleClickOpen("basic", item.targetRoute)}
                    sx={{
                      width: "100%",
                      height: "100%",
                      padding: 0,
                      borderRadius: 2,
                      border: "1px solid transparent",
                      transition: "border-color 0.3s, box-shadow 0.3s",
                      "&:hover": {
                        borderColor: theme.palette.action.hover,
                        boxShadow: theme.shadows[4],
                      },
                    }}
                  >
                    <Box
                      component={Card}
                      padding={4}
                      borderRadius={2}
                      width={1}
                      height={1}
                      data-aos={"fade-up"}
                      data-aos-delay={i * 100}
                    >
                      <Box
                        component={Avatar}
                        width={60}
                        height={60}
                        marginBottom={2}
                        bgcolor={item.bgcolor}
                        color={item.color}
                        variant={"rounded"}
                        borderRadius={2}
                      >
                        {item.icon}
                      </Box>
                      <Typography
                        variant={"h6"}
                        gutterBottom
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography color="text.secondary">
                        {item.subtitle}
                      </Typography>
                    </Box>
                  </IconButton>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      ))}
      <SubscribeDialog
        open={openSubscribeDialog}
        handleClose={() => setOpenSubscribeDialog(false)}
        navigate={navigate}
      />
      <LoginDialog
        open={openLoginDialog}
        handleClose={() => setOpenLoginDialog(false)}
        navigate={navigate}
      />
    </>
  );
};

export default AllTools;
