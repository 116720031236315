import React from "react";
import { colors } from "@mui/material";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import { alpha } from "@mui/material";

const saintBiography = {
  title: "Saint Biography Generator",
  subtitle: "Get short and concise biographies of saints in just a minute!",
  targetRoute: "/saint-biography",
  color: colors.blueGrey[500],
  bgcolor: alpha(colors.blueGrey[500], 0.1),
  icon: <LibraryBooksOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default saintBiography;
