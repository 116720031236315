import React from "react";
import { colors } from "@mui/material";
import GraphicEqOutlinedIcon from "@mui/icons-material/GraphicEqOutlined";
import { alpha } from "@mui/material";

const summaryFromSpeech = {
  title: "Summary from Speech",
  subtitle: "Generate summaries from sermon audio files.",
  targetRoute: "/summary-from-speech",
  color: colors.pink["A700"],
  bgcolor: alpha(colors.pink["A700"], 0.1),
  icon: <GraphicEqOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default summaryFromSpeech;
