import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import logo from "../../../../../../assets/images/ChurchScribe-logo.svg";
import negativeLogo from "../../../../../../assets/images/ChurchScribe-logo-negative.svg";
import instance from "../../../../../../helpers/axiosInstance";
import { useTheme } from "@mui/material/styles";
import ThemeModeToggler from "../../../../../../components/ThemeModeToggler";
import { useNavigate } from "react-router-dom";

import NavItem from "./components/NavItem";

const SidebarNav = ({ pages, colorInvert = false }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  const navigate = useNavigate();

  const {
    about: aboutPages,
    tools: toolsPages,
    pricing: pricingPages,
    dashboard: dashboardPages,
    contact: contactPages,
  } = pages;

  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("authToken") !== null
  );

  useEffect(() => {
    const checkRefresh = async () => {
      try {
        const tokenResponse = await instance.get("/api/auth/refresh-token");
        const accessToken = tokenResponse.data.accessToken;

        if (!accessToken) {
          localStorage.removeItem("authToken");
          setLoggedIn(false);
        }
      } catch (err) {
        console.log("checkingRefresh", err);
      }
    };

    checkRefresh();
  }, []);

  const logoutHandler = async () => {
    try {
      await instance.post("/api/auth/logout").then((res) => {
        if (res.data.success) {
          localStorage.removeItem("authToken");
          navigate("/login"); // navigate to /login after logout
        }
      });
    } catch (err) {
      console.log("logouthanderr");
    }
  };
  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={"flex"}
          component="a"
          href="/"
          title="theFront"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={"img"}
            src={mode === "light" && !colorInvert ? logo : negativeLogo}
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box>
          <NavItem title={"About"} items={aboutPages} />
        </Box>
        <Box>
          <NavItem title={"Tools"} items={toolsPages} />
        </Box>
        <Box>
          <NavItem title={"Pricing"} items={pricingPages} />
        </Box>
        <Box>
          <NavItem title={"Dashboard"} items={dashboardPages} />
        </Box>
        <Box>
          <NavItem title={"Contact"} items={contactPages} />
        </Box>
        <Box marginTop={2}>
          <ThemeModeToggler />
        </Box>
        {loggedIn ? (
          <Box marginTop={2}>
            <Button
              onClick={logoutHandler}
              variant="contained"
              color="primary"
              fullWidth
            >
              Logout
            </Button>
          </Box>
        ) : (
          <>
            <Box marginTop={2}>
              <Button
                size={"large"}
                variant="contained"
                fullWidth
                href="/register"
              >
                Register
              </Button>
            </Box>
            <Box marginTop={1}>
              <Button
                href="/login"
                variant="contained"
                color="primary"
                fullWidth
              >
                Login
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
