import createNoKeyOptChapLangToneTemplate from "../NoKeyOptChapLangToneTemplate";

const bibleStudyPlanGenerator = createNoKeyOptChapLangToneTemplate({
  name: "biblestudyplangenerator",
  endpoint: "/api/openai/bible-study-plan-generator",
  aiText: "bibleStudyPlanGenerator",
  aiTextHandler: "bibleStudyPlanGeneratorHandler",
  aiTextPlainText: "bibleStudyPlanGeneratorPlainText",
  title: "Bible Study Plan Generator",
  subText:
    "Generate an insightful Bible Study Plan. Simply input a Biblical book or topic, and get a curated study plan.",
  label: "Your Bible Study Roadmap",
  placeholderText: "Enter theme, duration...",
  buttonText: "Create Study Plan",
  aiPlaceholder: "Your Bible study plan will appear here",
});

export default bibleStudyPlanGenerator;
