import createOptLangKnowToneTemplate from "../OptLangKnowToneTemplate";

const parishNewsletterCreator = createOptLangKnowToneTemplate({
  name: "parishnewslettercreator",
  endpoint: "/api/openai/parish-newsletter-creator",
  aiText: "parishNewsletterCreator",
  aiTextHandler: "parishNewsletterCreatorHandler",
  aiTextPlainText: "parishNewsletterCreatorPlainText",
  title: "Parish Newsletter Creator",
  subText:
    "Compose engaging parish newsletters with our Creator. Enter a theme or topic, like 'Easter celebrations’ along with your parish information, and get a captivating newsletter.",
  label: "Your Parish Newsletter",
  placeholderText: "Enter info for your newsletter ",
  buttonText: "Create Newsletter",
  aiPlaceholder: "Your parish newsletter will appear here",
});

export default parishNewsletterCreator;
