import createNoKeyOptChapLangToneTemplate from "../NoKeyOptChapLangToneTemplate";

const scriptureStudyPlanMen = createNoKeyOptChapLangToneTemplate({
  name: "scripturestudyplanmen",
  endpoint: "/api/openai/scripture-study-plan-men",
  aiText: "scriptureStudyPlanMen",
  aiTextHandler: "scriptureStudyPlanMenHandler",
  aiTextPlainText: "scriptureStudyPlanMenPlainText",
  title: "Men's Scripture Study Planner",
  subText:
    "Formulate a Scripture learning plan for men's ministry. Input your study period and main themes to devise a plan.",
  label: "Men's Bible Study Schedule",
  placeholderText: "Enter study duration and key themes…",
  buttonText: "Generate Study Plan",
  aiPlaceholder: "Men's Scripture study plan will appear here",
});

export default scriptureStudyPlanMen;
