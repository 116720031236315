import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Page from "../components/Page";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import instance from "../../../../helpers/axiosInstance";

const Profile = () => {
  const theme = useTheme();
  const [fullName, setFullName] = React.useState("");
  const [user, setUser] = useState(null);
  const [usedWordCount, setUsedWordCount] = useState(0);
  const [remainingWordCount, setRemainingWordCount] = useState(75000);
  const [nextResetCycle, setNextResetCycle] = useState(new Date());

  const calculateNextResetDate = (subscription, subscribedDate) => {
    let date = new Date(subscribedDate);
    switch (subscription) {
      case "cs-pro-monthly":
      case "cs-enter-monthly":
      case "Pro Plan - Monthly":
      case "Enterprise Plan - Monthly":
        date.setMonth(date.getMonth() + 1);
        return date;
      case "cs-pro-annual":
      case "cs-enter-annual":
      case "Pro Plan - Annual":
      case "Enterprise Plan - Annual":
        date.setFullYear(date.getFullYear() + 1);
        return date;
      case "church-personal":
      case "Personal Plan":
        date.setDate(date.getDate() + 1);
        return date;

      case "cs-test":
      case "Testing Church":
        date.setDate(date.getDate() + 1);
        return date;
      case "Starter":
        return "-";
      default:
        return date;
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const authToken = localStorage.getItem("authToken");
        console.log("Current auth token:", authToken);

        const response = await instance.get("/api/auth/user", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        });
        console.log("Fetched User Profile:", response.data);

        const userData = response.data;
        console.log("Subscribed Date from API:", userData.subscribedDate);

        if (response.status === 200) {
          setUser(userData);
          setFullName(userData.username);
          setUsedWordCount(userData.usedWordCount);
          console.log(
            "Total monthly credit count:",
            userData.totalMonthlyCreditCount
          );
          console.log("Used word count:", userData.usedWordCount);
          setRemainingWordCount(
            userData.totalMonthlyCreditCount - userData.usedWordCount
          );

          const nextResetDate = calculateNextResetDate(
            userData.subscription,
            userData.subscribedDate
          );
          setNextResetCycle(nextResetDate);
        } else {
        }
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const getSubscriptionDisplayName = (subscription) => {
    switch (subscription) {
      case "cs-pro-monthly":
        return "Pro Plan - Monthly";
      case "cs-pro-annual":
        return "Pro Plan - Annual";
      case "cs-enter-monthly":
        return "Enterprise Plan - Monthly";
      case "cs-enter-annual":
        return "Enterprise Plan - Annual";
      case "Starter":
        return "Starter Plan";
      case "church-personal":
        return "Church Personal";
      default:
        return subscription;
    }
  };

  return (
    <Page>
      <Stack alignItems={"center"} spacing={2}>
        <Avatar
          src={user?.avatar || ""}
          variant={"circular"}
          sx={{
            width: { xs: theme.spacing(16), sm: theme.spacing(20) },
            height: { xs: theme.spacing(16), sm: theme.spacing(20) },
            border: `8px solid ${theme.palette.background.paper}`,
          }}
        />
        <Typography
          color={"text.primary"}
          variant={"h6"}
          align={"center"}
          mb={-5}
        >
          {fullName}
        </Typography>
        <Typography color={"text.secondary"} variant={"body1"} align={"center"}>
          {user?.email}
        </Typography>

        {user && (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow
                  sx={{
                    "&:nth-of-type(odd)": {
                      bgcolor: theme.palette.action.hover,
                    },
                  }}
                >
                  <TableCell>Subscription</TableCell>
                  <TableCell>
                    {getSubscriptionDisplayName(user.subscription)}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Words used this month</TableCell>
                  <TableCell>{usedWordCount}</TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    "&:nth-of-type(odd)": {
                      bgcolor: theme.palette.background.level2,
                    },
                  }}
                >
                  <TableCell>Remaining Word Count</TableCell>
                  <TableCell>{remainingWordCount}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Next Reset Date</TableCell>
                  <TableCell>
                    {typeof nextResetCycle === "string"
                      ? nextResetCycle
                      : nextResetCycle.toLocaleDateString()}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Typography color={"text.secondary"} variant={"h6"} align={"center"}>
          {user?.title || ""}
        </Typography>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 1, md: 2 }}
          width={1}
          alignItems={"center"}
          mt={2}
        >
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Box
              component={"svg"}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              width={18}
              height={18}
              color={"text.secondary"}
              marginRight={1}
            ></Box>
          </Box>
        </Stack>
      </Stack>
    </Page>
  );
};

export default Profile;
