import createOptLangKnowToneKeyTemplate from "../OptLangKnowToneKeyTemplate";

const podcastCreator = createOptLangKnowToneKeyTemplate({
  name: "podcastCreator",
  endpoint: "/api/openai/podcast-creator",
  aiText: "podcastCreator",
  aiTextHandler: "podcastCreatorHandler",
  aiTextPlainText: "podcastCreatorPlainText",
  title: "Podcast Creator",
  subText:
    "Input your information, and craft engaging and thought-provoking podcast scripts to captivate your audience.",
  label: "Podcast Script",
  placeholderText: "Enter your topic/theme",
  buttonText: "Create Podcast",
  aiPlaceholder: "Your Podcast will appear here",
});

export default podcastCreator;
