import React from "react";
import { colors } from "@mui/material";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { alpha } from "@mui/material";

const socialMediaContentGenerator = {
  title: "Social Media Content Generator",
  subtitle: "Generate customized content for your church's social media.",
  targetRoute: "/social-media-content-generator",
  color: colors.blue[400],
  bgcolor: alpha(colors.blue[400], 0.1),
  icon: <ShareOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default socialMediaContentGenerator;
