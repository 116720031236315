import createAiTextGenLang from "../AiTextGenLangTemplate";

const saintSayings = createAiTextGenLang({
  name: "saintSayings",
  endpoint: "/api/openai/saint-sayings",
  aiText: "saintSayings",
  aiTextHandler: "saintSayingsHandler",
  aiTextPlainText: "saintSayingsPlainText",
  title: "Saint Sayings",
  subText:
    "Find sacred wisdom on any subject with Saint Sayings. Enter a topic and get quotes from saints who spoke on the subject.",
  label: "Saintly Quotes",
  placeholderText: "Enter your subject/topic...",
  buttonText: "Find Quotes",
  aiPlaceholder: "Saints' quotes will appear here",
});

export default saintSayings;
