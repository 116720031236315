import React from "react";
import { colors } from "@mui/material";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import { alpha } from "@mui/material";

const couplesDevotionalPlanner = {
  title: "Couple's Devotional Planner",
  subtitle: "Foster spiritual growth in couples with personalized devotions.",
  targetRoute: "/couples-devotional-planner",
  color: colors.green[900],
  bgcolor: alpha(colors.green[900], 0.1),
  icon: <EventAvailableOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default couplesDevotionalPlanner;
