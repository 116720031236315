import React from "react";
import { colors } from "@mui/material";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import { alpha } from "@mui/material";

const blogContentPlanner = {
  title: "Blog Content Planner",
  subtitle:
    "Turn your topics into a structured blog content publishing calendar.",
  targetRoute: "/blog-content-planner",
  color: colors.indigo[900],
  bgcolor: alpha(colors.indigo[900], 0.1),
  icon: <ScheduleOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default blogContentPlanner;
