import createNoKeyOptLangToneTemplate from "../NoKeyOptLangToneTemplate";

const longReflectionGenerator = createNoKeyOptLangToneTemplate({
  name: "longreflectiongenerator",
  endpoint: "/api/openai/long-reflection-generator",
  aiText: "longReflectionGenerator",
  aiTextHandler: "longReflectionGeneratorHandler",
  aiTextPlainText: "longReflectionGeneratorPlainText",
  title: "Long Reflection Generator",
  subText:
    "Generate profound, extended reflections with our Long Reflection Generator. Enter a topic and receive a comprehensive 800-word reflection.",
  label: "In-depth Insight",
  placeholderText: "Enter a topic...",
  buttonText: "Generate Reflection",
  aiPlaceholder: "Reflection will appear here",
});

export default longReflectionGenerator;
