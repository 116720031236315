// import instance from "./axiosInstance";
// import { refreshToken } from "./refreshToken";

// export const checkSubscription = async () => {
//   try {
//     const authToken = localStorage.getItem("authToken");

//     if (!authToken) {
//       return false;
//     }

//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${authToken}`,
//       },
//     };

//     const { data } = await instance.get("/api/auth/subscription", config);
//     console.log("subscription:", data.subscription);

//     return data.subscription;
//   } catch (error) {
//     if (error.response.status === 401) {
//       // If we got a 401 error, try to refresh the token
//       const newToken = await refreshToken();

//       if (!newToken) {
//         // If refreshing the token didn't work, return false or throw an error
//         return false;
//       }

//       // If we got a new token, retry the API call
//       const config = {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${newToken}`,
//         },
//       };

//       const { data } = await instance.get("/api/auth/subscription", config);

//       return data.subscription;
//     }

//     console.error(error);
//     return false;
//   }
// };


// client/src/helpers/subscriptionHelper.js
import instance from "./axiosInstance";

export const checkSubscription = async () => {
  try {
    const { data } = await instance.get("/api/auth/subscription");
    console.log("subscription:", data.subscription);
    return data.subscription;
  } catch (error) {
    console.error("Subscription check error:", error);
    if (error.response?.status === 401) {
      // The axios interceptor will handle token refresh automatically
      return false;
    }
    return false;
  }
};