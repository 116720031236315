import createOptLangKnowKeyNoBibleTemplate from "../OptLangKnowKeyNoBibleTemplate";

const mensMinistryBlogPostIdeas = createOptLangKnowKeyNoBibleTemplate({
  name: "mensministryblogpostideas",
  endpoint: "/api/openai/mens-ministry-blog-post-ideas",
  aiText: "mensMinistryBlogPostIdeas",
  aiTextHandler: "mensMinistryBlogPostIdeasHandler",
  aiTextPlainText: "mensMinistryBlogPostIdeasPlainText",
  title: "Men's Ministry Blog Post Ideas",
  subText:
    "Create relevant blog post topics for your men's ministry. Enter your ministry's core themes and get a list of potential post topics.",
  label: "Men's Ministry Blog Post Ideas",
  placeholderText: "Enter your ministry's focus areas…",
  buttonText: "Generate Blog Ideas",
  aiPlaceholder: "Men's ministry blog post ideas will appear here",
});

export default mensMinistryBlogPostIdeas;
