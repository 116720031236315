import React from "react";
import { colors } from "@mui/material";
import FormatAlignLeftOutlinedIcon from "@mui/icons-material/FormatAlignLeftOutlined";
import { alpha } from "@mui/material";

const longReflectionGenerator = {
  title: "Long Reflection Generator",
  subtitle: "Generate in-depth reflections to inspire spiritual growth.",
  targetRoute: "/long-reflection-generator",
  color: colors.cyan[400],
  bgcolor: alpha(colors.cyan[400], 0.1),
  icon: <FormatAlignLeftOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default longReflectionGenerator;
