import createNoKeyOptLangToneTemplate from "../NoKeyOptLangToneTemplate";

const bibleStoriesForKids = createNoKeyOptLangToneTemplate({
  name: "biblestoriesforkids",
  endpoint: "/api/openai/bible-stories-for-kids",
  aiText: "bibleStoriesForKids",
  aiTextHandler: "bibleStoriesForKidsHandler",
  aiTextPlainText: "bibleStoriesForKidsPlainText",
  title: "Bible Stories for Kids",
  subText:
    "Transform Bible characters or events into stories for young ones. Input a Bible character or event and receive a story for kids.",
  label: "Kids' Bible Story",
  placeholderText: "Enter a Bible character or event…",
  buttonText: "Write Bible Story",
  aiPlaceholder: "Kids' Bible story will appear here",
});

export default bibleStoriesForKids;
