import React from "react";
import { colors } from "@mui/material";
import ChromeReaderModeOutlinedIcon from "@mui/icons-material/ChromeReaderModeOutlined";
import { alpha } from "@mui/material";

const sacramentalPreparationGuide = {
  title: "Sacramental Preparation Guide",
  subtitle: "Make sacramental preparation seamless and thoughtful.",
  targetRoute: "/sacramental-preparation-guide",
  color: colors.lime[900],
  bgcolor: alpha(colors.lime[900], 0.1),
  icon: <ChromeReaderModeOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default sacramentalPreparationGuide;
