import React from "react";
import { colors } from "@mui/material";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import { alpha } from "@mui/material";

const scriptureCrossReference = {
  title: "Scripture Cross Referencer",
  subtitle:
    "Navigate scripture cross-references effortlessly using a verse or keyword.",
  targetRoute: "/scripture-cross-reference",
  color: colors.indigo[600],
  bgcolor: alpha(colors.indigo[600], 0.1),
  icon: <FormatListBulletedOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default scriptureCrossReference;
