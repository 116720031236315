import React from "react";
import { colors } from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { alpha } from "@mui/material";

const blogWriter = {
  title: "Blog Writer",
  subtitle: "Compose engaging Catholic blog posts that resonate with readers.",
  targetRoute: "/blog-writer",
  color: colors.orange[500],
  bgcolor: alpha(colors.orange[500], 0.1),
  icon: <BorderColorOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default blogWriter;
