import createOptLangKnowNoBibleTemplate from "../OptLangKnowNoBibleTemplate";

const youthMinistryEventPlanner = createOptLangKnowNoBibleTemplate({
  name: "youthministryeventplanner",
  endpoint: "/api/openai/youth-ministry-event-planner",
  aiText: "youthMinistryEventPlanner",
  aiTextHandler: "youthMinistryEventPlannerHandler",
  aiTextPlainText: "youthMinistryEventPlannerPlainText",
  title: "Youth Ministry Event Planner",
  subText:
    "Plan engaging events for your Youth Ministry with our Planner. Input your event details and get a comprehensive event plan.",
  label: "Your Youth Ministry Event Plan",
  placeholderText: "Enter your event details",
  buttonText: "Create Plan",
  aiPlaceholder: "Your Youth Ministry event plan will appear here",
});

export default youthMinistryEventPlanner;
