import React from "react";
import { colors } from "@mui/material";
import MarkUnreadChatAltOutlinedIcon from "@mui/icons-material/MarkUnreadChatAltOutlined";
import { alpha } from "@mui/material";

const scriptureSays = {
  title: "Scripture Says",
  subtitle: "Find relevant scripture verses for any subject.",
  targetRoute: "/scripture-says",
  color: colors.red[900],
  bgcolor: alpha(colors.red[900], 0.1),
  icon: <MarkUnreadChatAltOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default scriptureSays;
