import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FaqGroupItem = ({ title, items }) => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={2}>
        <Typography fontWeight={700} variant={"h5"}>
          {title}
        </Typography>
      </Box>
      <Box>
        {items.map((item, i) => (
          <Box
            component={Accordion}
            key={i}
            padding={1}
            marginBottom={i === item.length - 1 ? 0 : 2}
            borderRadius={`${theme.spacing(1)} !important`}
            sx={{
              "&::before": {
                display: "none",
              },
            }}
          >
            <Box
              component={AccordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`panel1a-header--${i}`}
            >
              <Typography fontWeight={600}>{item.title}</Typography>
            </Box>
            <AccordionDetails>
              <Typography color="text.secondary">{item.subtitle}</Typography>
            </AccordionDetails>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

FaqGroupItem.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

const Content = () => {
  return (
    <Box>
      <Box marginBottom={6}>
        <FaqGroupItem
          title={"Basics"}
          items={[
            {
              title: "What is Church Scribe?",
              subtitle:
                "Church Scribe is an AI writer web app specifically designed to cater to the needs of the Catholic Church. It is a tool to create written content like sermons, religious articles or blogs, church bulletins, generate Bible quiz, write a Biblical story for children, compose songs, etc. in an easy and efficient way.",
            },
            {
              title: "How accurate is the AI writer?",
              subtitle:
                "Church Scribe uses advanced AI technology to ensure the highest accuracy. However, as with any AI tool, we recommend a final human review to ensure it aligns perfectly with your message.",
            },
            {
              title: "How quickly can Church Scribe generate content?",
              subtitle:
                "Church Scribe works almost instantaneously. Depending on the length and complexity of the request, you can expect to receive your content within minutes.",
            },
            {
              title: "Can I use Church Scribe on my mobile device?",
              subtitle:
                "Yes, Church Scribe is designed to be compatible with various platforms, including desktops, laptops, and mobile devices.",
            },
            {
              title: "Is there a limit to how much I can write at one time?",
              subtitle:
                "There is no specific limit to how much you can write at one time, but remember your word count depends on the subscription plan you have.",
            },
            {
              title:
                "Can Church Scribe generate content in languages other than English?",
              subtitle:
                "Currently, Church Scribe primarily operates in English. However, we are working to add more languages to better serve our global user base.",
            },
            {
              title:
                "Can Church Scribe help with historical and theological accuracy?",
              subtitle:
                "Church Scribe is programmed with extensive religious knowledge, but it's always best to cross-verify any historical or theological references for accuracy.",
            },
            {
              title:
                "Is Church Scribe suitable for all Christian denominations?",
              subtitle:
                "While Church Scribe is designed with the Catholic Church in mind, it will certainly be helpful to other Christian denominations as well.",
            },
            {
              title: "Does Church Scribe require internet access?",
              subtitle:
                "Yes, Church Scribe is a web-based application and requires internet access to function.",
            },
            {
              title:
                "How does Church Scribe ensure the uniqueness of the content generated?",
              subtitle:
                "Church Scribe is designed with a vast knowledge base and advanced AI algorithms to generate unique content based on your specific inputs.",
            },
          ]}
        />
      </Box>
      <Box marginBottom={6}>
        <FaqGroupItem
          title={"Ethics"}
          items={[
            {
              title:
                "Is using an AI writer for religious content like Church Scribe ethical?",
              subtitle:
                "Yes, Church Scribe is designed to assist with content creation, not to replace human creativity or interpretation. It helps to lighten the workload, allowing more time for other vital church activities. We advise users to always review and customize the content generated to align with their unique message and values.",
            },
            {
              title: "Is using Church Scribe legal?",
              subtitle:
                "Absolutely. Church Scribe is a tool for content generation and its use is completely legal. It does not infringe on any copyright laws as the content generated is unique and based on user inputs.",
            },
            {
              title:
                "Does using Church Scribe go against any church teachings?",
              subtitle:
                "No, Church Scribe is just a tool designed to assist with content creation. It helps generate sermons, articles, songs, and more, but the final decision and control over the content remains with the user.",
            },
            {
              title:
                "How does Church Scribe maintain respect and reverence for religious content?",
              subtitle:
                "Church Scribe has been designed with a deep understanding of the sensitivity and respect required for religious content. It uses a vast knowledge base of religious texts and established theological concepts to ensure the content generated is respectful and appropriate.",
            },
          ]}
        />
      </Box>
      <Box marginBottom={6}>
        <FaqGroupItem
          title={"Account & billing"}
          items={[
            {
              title: "How can I start using Church Scribe?",
              subtitle:
                "To begin using Church Scribe, you first need to subscribe to one of our plans. We offer a $1 subscription to get you started, which includes 3000 words. From there, you can select a larger plan to suit your needs.",
            },
            {
              title: "How do I purchase a subscription for Church Scribe?",
              subtitle:
                "Purchasing a subscription is easy. Simply select your desired plan and proceed to checkout. You can pay with your credit or debit card.",
            },
            {
              title: "What is included in the $1 subscription?",
              subtitle:
                "Our $1 subscription is a one-time purchase that provides you with 3000 words. This introductory offer allows you to try out the software and see if it meets your needs.",
            },
            {
              title: "Do you offer a free trial?",
              subtitle:
                "We do not offer a free trial, but we have a low-cost $1 subscription that allows you to generate 3000 words. This gives you an opportunity to use and evaluate our tool.",
            },
            {
              title: "Do the unused words roll over to the next month?",
              subtitle:
                "No, any leftover words from your subscription will not roll over to the next month. We recommend planning your usage to maximize the value of your subscription.",
            },
            {
              title: "Can I upgrade my subscription plan if I need more words?",
              subtitle:
                "Absolutely! If you find that you need more words than your current plan provides, you can upgrade your subscription at any time.",
            },
            {
              title: "Can I downgrade my subscription plan?",
              subtitle:
                "Yes, you can downgrade your subscription plan according to your needs.",
            },
            {
              title: "What happens to my content if I cancel my subscription?",
              subtitle:
                "After canceling your subscription, you will still have access to any content you have created. However, you won't be able to generate new content until you reactivate your subscription.",
            },
          ]}
        />
      </Box>
      <Box>
        <FaqGroupItem
          title={"Security"}
          items={[
            {
              title: "Is my information safe with Church Scribe?",
              subtitle:
                "Absolutely! We adhere to strict privacy and security standards to ensure your information is protected.",
            },
            {
              title:
                "Does Church Scribe use any personal data to generate content?",
              subtitle:
                "No, Church Scribe generates content based on your inputs and does not use personal data.",
            },
          ]}
        />
      </Box>
    </Box>
  );
};

export default Content;
