import React from "react";
import { colors } from "@mui/material";
import VolunteerActivismOutlinedIcon from "@mui/icons-material/VolunteerActivismOutlined";
import { alpha } from "@mui/material";

const prayerGenerator = {
  title: "Prayer Generator",
  subtitle:
    "Generate heartfelt prayers seamlessly by mentioning your intention.",
  targetRoute: "/prayer-generator",
  color: colors.green[500],
  bgcolor: alpha(colors.green[500], 0.1),
  icon: <VolunteerActivismOutlinedIcon fontSize="medium" />,
  tags: "",
};

export default prayerGenerator;
