import React from "react";
import { colors } from "@mui/material";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import { alpha } from "@mui/material";

const bibleStudyGuide = {
  title: "Bible Study Guide",
  subtitle: "Create study guides that make Bible learning more engaging.",
  targetRoute: "/bible-study-guide",
  color: colors.blue[700],
  bgcolor: alpha(colors.blue[700], 0.1),
  icon: <MenuBookOutlinedIcon fontSize="medium" />,
  tags: [
    "bible",
    "study",
    "guide",
    "bible study",
    "bible study guide",
    "bible help",
    "bible study help",
    "how to read bible",
    "how to study bible",
  ],
};

export default bibleStudyGuide;
