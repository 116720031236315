import ReactGA from "react-ga4";

export default function trackButtonClick(label) {
  ReactGA.event({
    name: "click_button",
    params: {
      event_category: "User",
      event_label: label,
    },
  });
}
