const pages = {
  about: [
    {
      title: "About us",
      href: "/about",
    },
    {
      title: "FAQ",
      href: "/faq",
    },
    {
      title: "Terms and Privacy",
      href: "/company-terms",
    },
  ],

  tools: [
    {
      title: "All Tools",
      href: "/tools",
    },
    {
      title: "Popular Tools",
      href: "/popular-tools",
    },
    {
      title: "Bible Tools",
      href: "/bible-tools",
    },

    {
      title: "Just in",
      href: "/new-tools",
    },
  ],
  pricing: [
    {
      title: "Pricing",
      href: "/pricing",
    },
    {
      title: "Billing Portal",
      href: "/billing-portal",
    },
  ],
  dashboard: [
    {
      title: "My Dashboard",
      href: "/dashboard",
    },
    {
      title: "My Profile",
      href: "/user-profile",
    },
  ],

  contact: [
    {
      title: "Contact us",
      href: "/contact",
    },
    {
      title: "Support",
      href: "/support",
    },
    {
      title: "Request new feature",
      href: "/support",
    },
  ],
};

export default pages;
