import createOptLangKnowToneTemplate from "../OptLangKnowToneTemplate";

const quoteGenerator = createOptLangKnowToneTemplate({
  name: "quotegenerator",
  endpoint: "/api/openai/quote-generator",
  aiText: "quoteGenerator",
  aiTextHandler: "quoteGeneratorHandler",
  aiTextPlainText: "quoteGeneratorPlainText",
  title: "Quote Generator",
  subText:
    "Generate the perfect quote for any occasion with our Quote Generator. Enter a topic like 'love' or 'forgiveness' and receive an instant quote!",
  label: "Words of Wisdom",
  placeholderText: "Enter a theme for your quote...",
  buttonText: "Generate Quote",
  aiPlaceholder: "Generated quote will appear here",
});

export default quoteGenerator;
