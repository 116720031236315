import React from "react";
import { colors } from "@mui/material";
import HelpCenterOutlinedIcon from "@mui/icons-material/HelpCenterOutlined";
import { alpha } from "@mui/material";

const bibleTriviaGeneratorChildren = {
  title: "Bible Trivia Generator - Children",
  subtitle: "Create fun and educational Bible trivia for children.",
  targetRoute: "/bible-trivia-generator-children",
  color: colors.red[800],
  bgcolor: alpha(colors.red[800], 0.1),
  icon: <HelpCenterOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default bibleTriviaGeneratorChildren;
