import createOptLangKnowToneKeyTemplate from "../OptLangKnowToneKeyTemplate";

const youtubeScriptwriter = createOptLangKnowToneKeyTemplate({
  name: "youtubeScriptwriter",
  endpoint: "/api/openai/youtube-scriptwriter",
  aiText: "youtubeScriptwriter",
  aiTextHandler: "youtubeScriptwriterHandler",
  aiTextPlainText: "youtubeScriptwriterPlainText",
  title: "YouTube Scriptwriter",
  subText:
    "YouTube Scriptwriter generates engaging narratives for your videos. Enter a topic such as 'Fasting during Lent', and quickly get a ready-to-film script for your YouTube channel!",
  label: "Video Script",
  placeholderText: "Type in your video's theme...",
  buttonText: "Compose Script",
  aiPlaceholder: "Your YouTube script will be displayed here",
});

export default youtubeScriptwriter;
