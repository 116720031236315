import React from "react";
import { colors } from "@mui/material";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import { alpha } from "@mui/material";

const scriptureStudyPlanMen = {
  title: "Men's Scripture Study Planner",
  subtitle: "Guide men in their faith journey with targeted study plans.",
  targetRoute: "/scripture-study-plan-men",
  color: colors.blue[900],
  bgcolor: alpha(colors.blue[900], 0.1),
  icon: <LocalLibraryOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default scriptureStudyPlanMen;
