import createNoKeyOptLangKnowToneTemplate from "../NoKeyOptLangKnowToneTemplate";

const retreatPlanner = createNoKeyOptLangKnowToneTemplate({
  name: "retreatplanner",
  endpoint: "/api/openai/retreat-planner",
  aiText: "retreatPlanner",
  aiTextHandler: "retreatPlannerHandler",
  aiTextPlainText: "retreatPlannerPlainText",
  title: "Retreat Planner",
  subText:
    "Organize your spiritual retreats with ease using our Retreat Planner. Input details like duration, theme, and activities, and get a comprehensive retreat plan.",
  label: "Your Retreat Blueprint",
  placeholderText: "Enter key theme and retreat duration…",
  buttonText: "Generate Plan",
  aiPlaceholder: "Your retreat plan will appear here",
});

export default retreatPlanner;
