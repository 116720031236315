import React from "react";
import { colors } from "@mui/material";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import { alpha } from "@mui/material";

const dailyDevotionsPlanner = {
  title: "Daily Devotions Planner",
  subtitle: "Organize your prayer life with a tailored schedule.",
  targetRoute: "/daily-devotions-planner",
  color: colors.yellow[700],
  bgcolor: alpha(colors.yellow[700], 0.1),
  icon: <DateRangeOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default dailyDevotionsPlanner;
