import React from "react";
import { colors } from "@mui/material";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import { alpha } from "@mui/material";

const couplesBibleStudyGuide = {
  title: "Couples' Bible Study Guide",
  subtitle: "Strengthen your spiritual bond with targeted Bible study.",
  targetRoute: "/couples-bible-study-guide",
  color: colors.blue[700],
  bgcolor: alpha(colors.blue[700], 0.1),
  icon: <PeopleOutlineOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default couplesBibleStudyGuide;
