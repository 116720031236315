import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import Container from "../../../Container";
import youtubeVideoIdeas from "../../cards/SocialMediaTools/YoutubeVideoIdeas";

const features = [youtubeVideoIdeas];

const options = {
  includeScore: true,
  keys: ["title", "subtitle", "tags"],
};

const fuse = new Fuse(features, options);

const CtaSimpleCentered = ({ searchQuery, onSearchChange }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Container>
      <Box>
        <Typography
          variant="h4"
          color="text.primary"
          align={"center"}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          The Complete Ministry Solution{" "}
        </Typography>
        <Typography
          variant="h6"
          component="p"
          color="text.secondary"
          sx={{ fontWeight: 400 }}
          align={"center"}
        >
          Unleash the power of 50+ AI-assisted tools to simplify all aspects of
          your church activities, so you can dedicate more time to prayer and
          worship.
        </Typography>
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems={"center"}
          justifyContent={"center"}
          marginTop={4}
        >
          <TextField
            variant="outlined"
            color="primary"
            size="medium"
            sx={{
              width: { xs: "100%", sm: "400px" },
              marginRight: isMd ? 2 : 0,
              marginBottom: isMd ? 0 : 2,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon color={"primary"} />
                </InputAdornment>
              ),
            }}
            value={searchQuery}
            onChange={onSearchChange}
            placeholder="Search for features..."
          />
        </Box>
      </Box>
    </Container>
  );
};

export default CtaSimpleCentered;
