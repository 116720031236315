import { Routes, Route as ElementRoute } from "react-router-dom";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import { useState, useMemo } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { RecoilRoot } from 'recoil';
import { RecoilNexus } from 'recoil-nexus';
import ScrollToTop from "./components/ScrollToTop";
import getTheme from "./theme/index";
import Page from "./components/Page";
import Main from "./layouts/Main/Main";
import EmailVerification from "./components/screens/EmailVerificationScreen";
import PaymentVerification from "./components/screens/PaymentVerificationScreen";
import SuccessfulVerification from "./components/screens/SuccessfulVerification";
import FailedVerification from "./components/screens/FailedVerification";
import VerifyEmail from "./components/screens/VerifyEmail";
import LoginScreen from "./components/screens/Login/LoginScreen";
import RegisterScreen from "./components/screens/Register/RegisterScreen";
import UserHistoryScreen from "./components/screens/UserHistoryScreen";

import HistoryDetailScreen from "./components/screens/HistoryDetailScreen";
import ProtectedRoute from "./components/routing/ProtectedRoute";
import AuthManager from './AuthManager';

import Home from "./components/screens/Home/Home";
import AboutUs from "./components/screens/AboutUs/AboutUs";
import ForgotPassword from "./components/screens/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/screens/PasswordReset/PasswordReset";
import UpdatePassword from "./components/screens/Account/Security/Security";
import UserProfile from "./components/screens/Account/Profile";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import BillingPortal from "./components/screens/Account/BillingPortal";
import Pricing from "./components/screens/Pricing/Pricing";
import MainSidebar from "./layouts/Main Sidebar/Main Sidebar";
import ContactPage from "./components/screens/ContactPage/ContactPage";
import SupportPage from "./components/screens/SupportPage/SupportPage";
import TermsAndPrivacy from "./components/screens/CompanyTerms/CompanyTerms";
import Faq from "./components/screens/Faq/Faq";
import NotFound from "./components/screens/NotFound/NotFound";
import ToneOfVoice from "./components/screens/ToneOfVoiceUpload";
import KnowledgeBase from "./components/screens/KnowledgeBaseUpload";
import AllTools from "./components/screens/Tools/AllTools";
import Dashboard from "./components/screens/Tools/Dashboard";
import BibleTools from "./components/screens/Tools/BibleTools";
import MyBibleTools from "./components/screens/Tools/MyBibleTools";
import CatholicTools from "./components/screens/Tools/CatholicTools";
import MyCatholicTools from "./components/screens/Tools/MyCatholicTools";
import ContentTools from "./components/screens/Tools/ContentTools";
import MyContentTools from "./components/screens/Tools/MyContentTools";
import PlannerTools from "./components/screens/Tools/PlannerTools";
import MyPlannerTools from "./components/screens/Tools/MyPlannerTools";
import PrayerTools from "./components/screens/Tools/PrayerTools";
import MyPrayerTools from "./components/screens/Tools/MyPrayerTools";
import RetreatTools from "./components/screens/Tools/RetreatTools";
import MyRetreatTools from "./components/screens/Tools/MyRetreatTools";
import SocialMediaTools from "./components/screens/Tools/SocialMediaTools";
import MySocialMediaTools from "./components/screens/Tools/MySocialMediaTools";
import SpeechToTextTools from "./components/screens/Tools/SpeechToTextTools";
import MySpeechToTextTools from "./components/screens/Tools/MySpeechToTextTools";



import { createImage } from "./components/screens/Features/AiScreens/ImageTools/index";

import {
  analogyMaker,
  bibleGamesForFamilies,
  bibleQuizGenerator,
  bibleStoriesForKids,
  bibleStudyGuide,
  bibleStudyPlanGenerator,
  bibleTriviaGeneratorAdults,
  bibleTriviaGeneratorChildren,
  biblicalSongwriter,
  blogContentPlanner,
  blogOutline,
  blogTopicIdeas,
  blogWriter,
  blogWriterFromSpeech,
  bulletPointsFromSpeech,
  canonLawReference,
  catechismReference,
  contentPlanner,
  couplesBibleStudyGuide,
  couplesDevotionalPlanner,
  dailyDevotionsPlanner,
  familyMinistryBlogPostIdeas,
  familyPrayerGenerator,
  familyScriptureStudyPlan,
  hymnRecommender,
  keywordResearch,
  longReflectionGenerator,
  lyricsGenerator,
  marriageRetreatPlanner,
  mensMinistryBlogPostIdeas,
  modernHymnMaker,
  narrateToChild,
  parishEventPlanner,
  parishNewsletterCreator,
  podcastCreator,
  prayerGenerator,
  quoteGenerator,
  rapCreator,
  relatedTopicsResearch,
  retreatPlanner,
  sacramentalPreparationGuide,
  saintBiography,
  saintSayings,
  scriptureCrossReference,
  scriptureSays,
  scriptureStudyPlanMen,
  scriptureStudyPlanWomen,
  sermon,
  shortReflectionGenerator,
  socialMediaContentGenerator,
  songRecommender,
  summaryFromSpeech,
  websiteContentPlanner,
  websitePageContentGenerator,
  weddingVowsGenerator,
  womensMinistryBlogPostIdeas,
  youthMinistryEventPlanner,
  youtubeVideoIdeas,
  youtubeScriptwriter,
  youtubeVideoDescription,
  youtubeVideoTitle,
} from "./components/screens/Features/AiScreens/index";

const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
);

function AppContent() {
  const [mode, setMode] = useState("light");

  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("G-P5NX584CZ9");
    // Initial page view
    ReactGA.send("pageview", location.pathname);
  }, []);

  useEffect(() => {
    // This will run whenever the location changes
    ReactGA.send("pageview", location.pathname);
  }, [location]);

  const themeToggler = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  const theme = useMemo(
    () => createTheme(getTheme(mode, themeToggler)),
    [mode]
  );

  return (
    <Elements stripe={stripePromise}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ScrollToTop />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <div style={{ flexGrow: 1 }}>
          
            <Page>
              <Routes>
                <ElementRoute
                  exact
                  path="/"
                  element={
                    <Main>
                      <Home />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/about"
                  element={
                    <Main>
                      <AboutUs />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/login"
                  element={
                    <Main>
                      <LoginScreen />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/register"
                  element={
                    <Main>
                      <RegisterScreen />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/successful-verification"
                  element={
                    <Main>
                      <SuccessfulVerification />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/failed-verification"
                  element={
                    <Main>
                      <FailedVerification />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/verify-email/:token"
                  element={
                    <Main>
                      <VerifyEmail />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/forgot-password"
                  element={
                    <Main>
                      <ForgotPassword />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/reset-password/:token"
                  element={
                    <Main>
                      <ResetPassword />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/email-verification"
                  element={
                    <Main>
                      <EmailVerification />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/success"
                  element={
                    <Main>
                      <PaymentVerification />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/tools"
                  element={
                    <Main>
                      <AllTools />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/pricing"
                  element={
                    <Main>
                      <Pricing />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/company-terms"
                  element={
                    <Main>
                      <TermsAndPrivacy />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/faq"
                  element={
                    <Main>
                      <Faq />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/contact"
                  element={
                    <Main>
                      <ContactPage />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/support"
                  element={
                    <Main>
                      <SupportPage />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/user-profile"
                  element={
                    <Main>
                      <ProtectedRoute component={UserProfile} />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/update-password"
                  element={
                    <Main>
                      <ProtectedRoute component={UpdatePassword} />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/billing-portal"
                  element={
                    <Main>
                      <BillingPortal />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/bible-tools"
                  element={
                    <Main>
                      <BibleTools />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/catholic-tools"
                  element={
                    <Main>
                      <CatholicTools />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/content-tools"
                  element={
                    <Main>
                      <ContentTools />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/catholic-tools"
                  element={
                    <Main>
                      <CatholicTools />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/planner-tools"
                  element={
                    <Main>
                      <PlannerTools />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/prayer-tools"
                  element={
                    <Main>
                      <PrayerTools />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/retreat-tools"
                  element={
                    <Main>
                      <RetreatTools />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/social-media-tools"
                  element={
                    <Main>
                      <SocialMediaTools />
                    </Main>
                  }
                />
                <ElementRoute
                  exact
                  path="/speech-to-text-tools"
                  element={
                    <Main>
                      <SpeechToTextTools />
                    </Main>
                  }
                />
                {/* Features screens */}
                <ElementRoute
                  exact
                  path="/dashboard"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={Dashboard} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/my-bible-tools"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={MyBibleTools} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/my-catholic-tools"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={MyCatholicTools} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/my-content-tools"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={MyContentTools} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/my-planner-tools"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={MyPlannerTools} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/my-prayer-tools"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={MyPrayerTools} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/my-retreat-tools"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={MyRetreatTools} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/my-social-media-tools"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={MySocialMediaTools} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/my-speech-to-text-tools"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={MySpeechToTextTools} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/analogy-maker"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={analogyMaker} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/bible-games-for-families"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={bibleGamesForFamilies} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/bible-quiz-generator"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={bibleQuizGenerator} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/bible-stories-for-kids"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={bibleStoriesForKids} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/bible-study-guide"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={bibleStudyGuide} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/bible-study-plan-generator"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={bibleStudyPlanGenerator} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/bible-trivia-generator-adults"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={bibleTriviaGeneratorAdults} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/bible-trivia-generator-children"
                  element={
                    <MainSidebar>
                      <ProtectedRoute
                        component={bibleTriviaGeneratorChildren}
                      />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/biblical-songwriter"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={biblicalSongwriter} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/blog-content-planner"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={blogContentPlanner} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/blog-outline"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={blogOutline} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/blog-topic-ideas"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={blogTopicIdeas} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/blog-writer"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={blogWriter} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/blog-writer-from-speech"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={blogWriterFromSpeech} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/bullet-points-from-speech"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={bulletPointsFromSpeech} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/canon-law-reference"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={canonLawReference} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/catechism-reference"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={catechismReference} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/content-planner"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={contentPlanner} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/couples-bible-study-guide"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={couplesBibleStudyGuide} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/couples-devotional-planner"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={couplesDevotionalPlanner} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/daily-devotions-planner"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={dailyDevotionsPlanner} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/family-ministry-blog-post-ideas"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={familyMinistryBlogPostIdeas} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/family-prayer-generator"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={familyPrayerGenerator} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/family-scripture-study-planner"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={familyScriptureStudyPlan} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/hymn-recommender"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={hymnRecommender} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/keyword-research"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={keywordResearch} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/long-reflection-generator"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={longReflectionGenerator} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/lyrics-generator"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={lyricsGenerator} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/marriage-retreat-planner"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={marriageRetreatPlanner} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/mens-ministry-blog-post-ideas"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={mensMinistryBlogPostIdeas} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/modern-hymn-maker"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={modernHymnMaker} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/narrate-to-child"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={narrateToChild} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/parish-event-planner"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={parishEventPlanner} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/parish-newsletter-creator"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={parishNewsletterCreator} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/podcast-creator"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={podcastCreator} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/prayer-generator"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={prayerGenerator} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/quote-generator"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={quoteGenerator} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/rap-creator"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={rapCreator} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/related-topics-research"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={relatedTopicsResearch} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/retreat-planner"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={retreatPlanner} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/sacramental-preparation-guide"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={sacramentalPreparationGuide} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/saint-biography"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={saintBiography} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/saint-sayings"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={saintSayings} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/scripture-cross-reference"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={scriptureCrossReference} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/scripture-says"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={scriptureSays} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/scripture-study-plan-men"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={scriptureStudyPlanMen} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/scripture-study-plan-women"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={scriptureStudyPlanWomen} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/sermon"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={sermon} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/short-reflection-generator"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={shortReflectionGenerator} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/social-media-content-generator"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={socialMediaContentGenerator} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/song-recommender"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={songRecommender} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/summary-from-speech"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={summaryFromSpeech} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/website-content-planner"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={websiteContentPlanner} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/website-page-content-generator"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={websitePageContentGenerator} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/wedding-vows-generator"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={weddingVowsGenerator} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/womens-ministry-blog-post-ideas"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={womensMinistryBlogPostIdeas} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/youth-ministry-event-planner"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={youthMinistryEventPlanner} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/youtube-video-ideas"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={youtubeVideoIdeas} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/youtube-scriptwriter"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={youtubeScriptwriter} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/youtube-video-description"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={youtubeVideoDescription} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/youtube-video-title"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={youtubeVideoTitle} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/history"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={UserHistoryScreen} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/history/:id"
                  element={<ProtectedRoute component={HistoryDetailScreen} />}
                />
                <ElementRoute
                  exact
                  path="/knowledge-base"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={KnowledgeBase} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  exact
                  path="/upload-tone"
                  element={
                    <MainSidebar>
                      <ProtectedRoute component={ToneOfVoice} />
                    </MainSidebar>
                  }
                />
                // image generators
                <ElementRoute
                  exact
                  path="/create-image"
                  element={
                    <MainSidebar component={createImage}>
                      <ProtectedRoute component={createImage} />
                    </MainSidebar>
                  }
                />
                <ElementRoute
                  path="*"
                  element={
                    <Main>
                      <NotFound />
                    </Main>
                  }
                />
              </Routes>
            </Page>
           
          </div>
        </div>
      </ThemeProvider>
    </Elements>
  );
}

function App() {
  return (
    <AuthManager>
      <AppContent />
    </AuthManager>
  );
}


export default App;
