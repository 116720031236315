import React from "react";
import { colors } from "@mui/material";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import { alpha } from "@mui/material";

const bulletPointsFromSpeech = {
  title: "Bullet Points From Speech",
  subtitle: "Create bullet points from spoken sermons instantly.",
  targetRoute: "/bullet-points-from-speech",
  color: colors.amber["A700"],
  bgcolor: alpha(colors.amber["A700"], 0.1),
  icon: <FormatListBulletedOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default bulletPointsFromSpeech;
