import createOptVersionTemplate from "../OptVersionTemplate";

const scriptureCrossReference = createOptVersionTemplate({
  name: "scripturecrossreference",
  endpoint: "/api/openai/scripture-cross-reference",
  aiText: "scriptureCrossReference",
  aiTextHandler: "scriptureCrossReferenceHandler",
  aiTextPlainText: "scriptureCrossReferencePlainText",
  title: "Scripture Cross Referencer",
  subText:
    "Scripture Cross-Referencer helps in finding related verses across the Bible. Enter a verse like 'Romans 8:28' or a keyword like 'love', and get a list of related scriptures.",
  label: "Related Bible Verses",
  placeholderText: "Enter a Bible verse or keyword...",
  buttonText: "Discover Scriptures",
  aiPlaceholder: "Your cross-referenced scriptures will appear here",
});

export default scriptureCrossReference;
