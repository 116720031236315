import createNoKeyOptLangToneTemplate from "../NoKeyOptLangToneTemplate";

const prayerGenerator = createNoKeyOptLangToneTemplate({
  name: "prayergenerator",
  endpoint: "/api/openai/prayer-generator",
  aiText: "prayerGenerator",
  aiTextHandler: "prayerGeneratorHandler",
  aiTextPlainText: "prayerGeneratorPlainText",
  title: "Prayer Generator",
  subText:
    "Create heartfelt prayers with our Prayer Generator. Input a theme like 'forgiveness' or a specific intention, and receive a personalized prayer.",
  label: "Let us pray",
  placeholderText: "Enter your intention…",
  buttonText: "Help me pray!",
  aiPlaceholder: "Prayer will be displayed here",
});

export default prayerGenerator;
