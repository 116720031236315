import React from "react";
import { Select, MenuItem } from "@mui/material";

const ProtestantBibleSelect = ({ bibleVersion, setBibleVersion }) => {
  const handleChange = (event) => {
    setBibleVersion(event);
  };

  const bibleVersions = [
    "New International Version (NIV)",
    "King James Version (KJV)",
    "English Standard Version (ESV)",
    "New Living Translation (NLT)",
    "New King James Version (NKJV)",
    "Revised Standard Version (RSV)",
    "American Standard Version (ASV)",
    "The Message (MSG)",
    "Good News Bible (GNT)",
    "The Passion Translation (TPT)",
  ];

  return (
    <Select
      displayEmpty
      renderValue={(value) =>
        value !== "" ? value : "New International Version (NIV)"
      }
      fullWidth
      value={bibleVersion}
      onChange={handleChange}
      sx={{ mb: 1.5 }}
    >
      <MenuItem value="">
        <em>Select a Bible version</em>
      </MenuItem>
      {bibleVersions.map((version) => (
        <MenuItem key={version} value={version}>
          {version}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ProtestantBibleSelect;
