import creatOptTopicLang from "../OptTopicLangTemplate";

const hymnrecommender = creatOptTopicLang({
  name: "hymnrecommender",
  endpoint: "/api/openai/hymn-recommender",
  aiText: "hymnRecommender",
  aiTextHandler: "hymnRecommenderHandler",
  aiTextPlainText: "hymnRecommender PlainText",
  title: "Hymn Recommender",
  subText:
    "Discover the perfect hymn for your service with our Hymn Recommender. Input a theme or scripture, like 'grace' or 'Psalm 23', and receive a fitting hymn.",
  label: "Your Hymn Recommendation",
  placeholderText: "Enter a theme or Bible verse…",
  buttonText: "Recommend Hymn",
  aiPlaceholder: "Hymns will appear here",
});

export default hymnrecommender;
