import React from 'react';
import { Grid, Typography, Box, Menu, MenuItem } from '@mui/material';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const SimpleControlRow = ({
  modelType,
  setModelType,
  toneOption,
  setToneOption,
  setUseUploadedTone
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  const handleToneClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToneClose = () => {
    setAnchorEl(null);
  };

  const handleToneSelect = (option) => {
    setToneOption(option);
    setUseUploadedTone(option === "select");
    handleToneClose();
  };

  return (
    <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
      {/* Model Selection */}
      <Grid item>
        <ToggleButtonGroup
          value={modelType}
          exclusive
          onChange={(e, newValue) => {
            if (newValue !== null) {
              setModelType(newValue);
            }
          }}
          size="small"
          sx={{
            height: '40px',
            '& .MuiToggleButton-root': {
              px: 2,
              border: '1px solid',
              borderColor: 'divider',
              transition: 'all 0.2s ease',
              '&.Mui-selected': {
                backgroundColor: 'transparent',
                borderColor: 'primary.main',
                color: 'primary.main',
                fontWeight: 500,
              }
            }
          }}
        >
          <ToggleButton value="gpt" aria-label="GPT-4">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <SmartToyOutlinedIcon sx={{ fontSize: 20 }} />
              <Typography variant="body2">GPT</Typography>
            </Box>
          </ToggleButton>
          <ToggleButton value="anthropic" aria-label="Claude">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <AutoAwesomeOutlinedIcon sx={{ fontSize: 20 }} />
              <Typography variant="body2">Claude</Typography>
            </Box>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      {/* Divider */}
      <Grid item>
        <Box sx={{ borderLeft: 1, borderColor: 'divider', height: 24, mx: 1 }} />
      </Grid>

      {/* Tone Dropdown Button */}
      <Grid item>
        <ToggleButton
          value="tone"
          selected={Boolean(toneOption)}
          onClick={handleToneClick}
          size="small"
          sx={{
            height: '40px',
            px: 2,
            border: '1px solid',
            borderColor: 'divider',
            '&.Mui-selected': {
              backgroundColor: 'transparent',
              borderColor: 'primary.main',
              color: 'primary.main',
              fontWeight: 500,
            }
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <TuneOutlinedIcon sx={{ fontSize: 20 }} />
            <Typography variant="body2">Tone</Typography>
            <KeyboardArrowDownIcon sx={{ fontSize: 20, ml: 0.5 }} />
          </Box>
        </ToggleButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleToneClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={() => handleToneSelect('write')} selected={toneOption === 'write'}>
            <EditNoteOutlinedIcon sx={{ fontSize: 20, mr: 1 }} />
            Write a tone
          </MenuItem>
          <MenuItem onClick={() => handleToneSelect('select')} selected={toneOption === 'select'}>
            <FormatListBulletedOutlinedIcon sx={{ fontSize: 20, mr: 1 }} />
            Select a tone
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
};

export default SimpleControlRow;