import createNoKeyOptChapLangToneTemplate from "../NoKeyOptChapLangToneTemplate";

const bibleQuizGenerator = createNoKeyOptChapLangToneTemplate({
  name: "biblequizgenerator",
  endpoint: "/api/openai/bible-quiz-generator",
  aiText: "bibleQuizGenerator",
  aiTextHandler: "bibleQuizGeneratorHandler",
  aiTextPlainText: "bibleQuizGeneratorPlainText",
  title: "Bible Quiz Generator",
  subText:
    "Test your Biblical knowledge with our Bible Quiz Generator. Enter a scripture range or choose a book of the Bible and get a custom quiz instantly.",
  label: "Your Bible Quiz",
  placeholderText: "Input the type of quiz, number of questions, etc.",
  buttonText: "Build Quiz",
  aiPlaceholder: "Your Bible quiz will appear here",
});

export default bibleQuizGenerator;
