import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import useMediaQuery from "@mui/material/useMediaQuery";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import instance from "../../../../../helpers/axiosInstance";
import StarterSubscribedDialog from "../../../Dialog/StarterSubscribedDialog";
import CurrencyDialogComponent from "../../../Dialog/CurrencyDialogComponent";
import SplashScreen from "../../../../splashScreen";
import SimpleDialog from "../../../Dialog/SimpleDialog";

import { checkSubscription } from "../../../../../helpers/subscriptionHelper";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import LoginDialog from "../../../Dialog/LoginDialog";

const mock = [
  {
    title: "Starter",
    subtitle:
      "Kickstart your church's digital journey with multi-modal content creation",
    price: {
      monthly: "$1",
      annual: "$10",
    },
    features: [
      "One-time $1 plan to explore multi-modal content generation",
      "10,000 credits: perfect for creating diverse content formats",
      // "Generate up to 10,000 words, 5 SD images, or 2 HD images",
      // "Includes up to 6.64 minutes of Text-to-Speech or 33.33 minutes of Speech-to-Text",
      "Experience the versatility of integrating text, image, and voice in your ministry's outreach",
      "Ideal for small churches or ministries beginning to explore digital content creation",
    ],
    isHighlighted: false,
  },

  {
    title: "Pro",
    subtitle: "Empower Your Ministry with Advanced Content Creation",
    price: {
      monthly: "$49",
      annual: "$490",
    },
    features: [
      "415,000 credits each month, ideal for versatile content creation",
      // "Generate up to 415,000 words, 207 DALL·E images, 415 Midjourney images, or 461 Stable Diffusion images",
      // "Up to 138.33 minutes (approx. 2.3 hours) of Text-to-Speech or 1,383.33 minutes (approx. 23 hours) of Speech-to-Text",
      "Perfect for small to medium-sized churches aiming to enhance their digital presence",
      "Designed to support digital outreach and community engagement effectively",
    ],
    isHighlighted: true,
  },

  {
    title: "Enterprise",
    subtitle: "Transform Your Ministry with Premier AI Tools",
    price: {
      monthly: "$70",
      annual: "$700",
    },
    features: [
      "630,000 credits monthly for extensive multi-modal content generation",
      // "Generate up to 630,000 words, 315 SD images, 630 Midjourney images, or 700 Stable Diffusion images",
      // "Utilize up to 210 minutes (approx. 3.5 hours) of Text-to-Speech or 2,100 minutes (approx. 35 hours) of Speech-to-Text",
      "The ultimate solution for large churches and ministries aiming for a significant digital impact",
      "A top choice for ministries seeking to lead in digital innovation and outreach",
    ],
    isHighlighted: false,
  },
];

const Main = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false);
  const [subscriptionDialogText, setSubscriptionDialogText] = useState("");
  const [subscriptionDialogButtonAction, setSubscriptionDialogButtonAction] =
    useState(null);
  const [openCurrencyDialog, setOpenCurrencyDialog] = React.useState(false);
  const [currency, setCurrency] = useState("usd"); // default to USD
  const [checkoutParams, setCheckoutParams] = useState({});
  const [showSplashScreen, setShowSplashScreen] = useState(false);
  const [startCheckout, setStartCheckout] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleCurrencySelect = (selectedCurrency) => {
    const priceList = {
      usd: 100,
      eur: 100,
      gbp: 81,
      jpy: 140,
      cny: 700,
      cad: 136,
      inr: 8200,
    };

    const selectedPrice = priceList[selectedCurrency];

    setCurrency(selectedCurrency);
    setCheckoutParams((prevState) => ({
      ...prevState,
      currency: selectedCurrency,
      unit_amount: selectedPrice,
      subType: "Starter", // If it is the Starter plan
    }));
    // Set startCheckout to true
    setStartCheckout(true);
  };

  useEffect(() => {
    const initiateCheckout = async () => {
      if (
        startCheckout &&
        checkoutParams.currency &&
        checkoutParams.unit_amount
      ) {
        await redirectToCheckout(
          checkoutParams.priceId,
          checkoutParams.subType,
          checkoutParams.targetRoute
        );
        setStartCheckout(false);
        setShowSplashScreen(false);
      }
    };
    initiateCheckout();
  }, [checkoutParams, currency, startCheckout]);

  const closeCurrencyDialog = async () => {
    setOpenCurrencyDialog(false);
    if (checkoutParams.subType === "Starter") {
      setShowSplashScreen(true);
      setStartCheckout(true);
    }
  };

  useEffect(() => {
    console.log("showSubscriptionDialog has changed:", showSubscriptionDialog);
  }, [showSubscriptionDialog]);

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    setIsLoggedIn(!!authToken);
  }, []);

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const [pricingOption, setPricingOption] = useState("monthly");
  const [error, setError] = useState("");

  const handleClick = (event, newPricingOption) => {
    setPricingOption(newPricingOption);
  };

  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  const showErrorDialog = () => {
    setOpenErrorDialog(true);
  };

  const handleCloseErrorDialog = () => {
    console.log("handleCloseErrorDialog");
    setOpenErrorDialog(false);
  };

  const openBillingPortal = async () => {
    try {
      const token = await instance.get("/api/auth/refresh-token");
      if (token.data && token.data.accessToken) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.data.accessToken}`,
          },
        };
        const customerId = await instance.get("/api/auth/customer", config);
        if (customerId.data.customerId) {
          const portal = await instance.post(
            "/api/stripe/portal",
            { customerId: customerId.data.customerId },
            config
          );
          if (portal.data.url) {
            window.open(portal.data.url, "_self");
          }
        }
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        console.log("Unauthorized. Please login again.");
      } else {
        console.log("Error in creating portal", err);
      }
    }
  };

  const handleSubscribe = async (priceId, subType, targetRoute) => {
    if (!isLoggedIn) {
      // If the user is NOT logged in
      setOpenLoginDialog(true);
      return;
    }

    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      const userDataResponse = await instance.get("/api/auth/user", config);
      const userData = userDataResponse.data;

      // Requirement 1
      if (subType === "Starter" && userData.starterSubscribed) {
        showDialogUpgradeToPricing();
        return;
      }

      // Requirement 2
      if (
        subType !== "Starter" &&
        userData.subscriptionPlan &&
        userData.subscriptionPlan !== "Starter"
      ) {
        console.log(userData);
        showDialogBillingPortal();
        return;
      }

      // Requirement 3 & 4
      if (subType === "Starter" && !userData.subscriptionPlan) {
        setOpenCurrencyDialog(true); // Will lead to checkout-one-time when currency is selected
        return;
      } else if (!userData.subscriptionPlan) {
        redirectToCheckout(priceId, subType, targetRoute); // Will lead to checkout-subscription
        return;
      }
    } else {
      setOpenLoginDialog(true);
    }
  };

  const showDialogUpgradeToPricing = () => {
    setOpenDialog(true);
  };

  const showDialogBillingPortal = () => {
    setSubscriptionDialogText(
      "You are already subscribed to a plan. Please visit your billing portal to renew or upgrade your plan."
    );
    setSubscriptionDialogButtonAction(() => openBillingPortal());
    setShowSubscriptionDialog(true);
  };

  const closeLoginDialog = () => {
    setOpenLoginDialog(false);
  };

  const redirectToCheckout = async (priceId, subType, targetRoute) => {
    try {
      const authToken = localStorage.getItem("authToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };

      let session;
      if (subType === "Starter") {
        // Handle one-time purchase (Starter plan)
        session = await instance.post(
          "/api/stripe/checkout-one-time",
          {
            currency: checkoutParams.currency,
            unit_amount: checkoutParams.unit_amount,
            metadata: { subscription: subscriptionMetadata["Starter"] },
          },
          config
        );
      } else {
        // Handle recurring subscription (Pro and Enterprise plans)
        session = await instance.post(
          "/api/stripe/checkout-subscription",
          {
            priceId,
            metadata: { subscription: subscriptionMetadata[subType] },
          },
          config
        );
      }

      // Check the response type
      if (session.data.redirectType === "portal") {
        // Redirect to the billing portal
        window.location.href = session.data.url;
        return;
      }

      console.log("session data:", session.data);
      console.log("session:", session);

      if (session.data) {
        // const stripe = await loadStripe(
        //   "pk_test_51NhqQrSBOxpsQq9ZpsiFQb4GoBIenPryTRr2HPO6OXKR8hYl1oO46HPEenLKxUif5xEOLHxRykgZiIN7jn7cimRd00SFEZRC7f"
        // );
        const stripe = await loadStripe(
          "pk_live_51NhqQrSBOxpsQq9ZsMI3r6dHYXijJJAxRbWzdTCHVUSwIKRjZxwbc8JnGUrdiGcyshZWvw0bseLOiBYep0zyWgGJ00py5vuW5y"
        );

        const result = await stripe.redirectToCheckout({
          sessionId: session.data.id,
        });

        if (result.error) {
          console.error(result.error.message);
        } else {
          window.addEventListener("popstate", () => {
            if (targetRoute) {
              navigate(targetRoute);
            }
          });
        }
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data.message) {
        console.log("err.response:", err.response);

        setError(err.response.data.message);
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    }
  };

  const handleCloseSubscriptionDialog = () => {
    console.log("Closing dialog");
    setShowSubscriptionDialog(false);
    setSubscriptionDialogButtonAction(() => {});
  };

  const subscriptionMetadata = {
    Starter: "Starter",
    ProMonthly: "cs-pro-monthly",
    ProPersonal: "church-personal",
    ProAnnual: "cs-pro-annual",
    EnterpriseMonthly: "cs-enter-monthly",
    EnterpriseAnnual: "cs-enter-annual",
    Testing: "cs-test",
  };

  const renderToggler = () => (
    <Box display={"flex"} justifyContent={"center"} marginBottom={4}>
      <ToggleButtonGroup value={pricingOption} exclusive onChange={handleClick}>
        <ToggleButton
          value="annual"
          size={isMd ? "large" : "small"}
          sx={{
            backgroundColor:
              pricingOption === "annual"
                ? `${theme.palette.primary.light} !important`
                : "transparent",
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 700,
              color:
                pricingOption === "annual" ? "common.white" : "text.primary",
            }}
          >
            Annual
          </Typography>
        </ToggleButton>
        <ToggleButton
          value="monthly"
          size={isMd ? "large" : "small"}
          sx={{
            backgroundColor:
              pricingOption === "monthly"
                ? `${theme.palette.primary.light} !important`
                : "transparent",
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 700,
              color:
                pricingOption !== "annual" ? "common.white" : "text.primary",
            }}
          >
            Monthly
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );

  return (
    <>
      {showSplashScreen ? (
        <SplashScreen />
      ) : (
        <Box>
          <Box
            sx={{
              position: "relative",
              backgroundColor: theme.palette.alternate.main,
              backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
              marginTop: -13,
              paddingTop: 13,
            }}
          >
            <Container position={"relative"} zIndex={3}>
              <Box>
                <Box marginBottom={4}>
                  <Typography
                    variant="h3"
                    gutterBottom
                    align={"center"}
                    sx={{
                      fontWeight: 900,
                      mb: 2,
                      mt: 10,
                    }}
                  >
                    Flexible pricing options
                  </Typography>
                  <Typography
                    variant="h6"
                    component="p"
                    color="text.primary"
                    align={"center"}
                  >
                    Discover pricing options that enable your church to harness
                    the power of AI, no matter its size, budget, or
                    communication needs.
                  </Typography>
                </Box>
                {renderToggler()}
              </Box>
            </Container>
          </Box>
          <Container>
            <Grid container spacing={4}>
              {mock.map((item, i) => (
                <Grid item xs={12} md={4} key={i}>
                  <Box
                    component={Card}
                    height={1}
                    display={"flex"}
                    flexDirection={"column"}
                    variant={"outlined"}
                  >
                    <CardContent
                      sx={{
                        padding: 4,
                      }}
                    >
                      <Box marginBottom={2}>
                        <Typography
                          variant={"h4"}
                          fontWeight={600}
                          gutterBottom
                        >
                          {item.title}
                        </Typography>
                        <Typography color={"text.secondary"}>
                          {item.subtitle}
                        </Typography>
                      </Box>
                      <Box
                        display={"flex"}
                        alignItems={"baseline"}
                        marginBottom={2}
                      >
                        <Typography variant={"h3"} fontWeight={700}>
                          {pricingOption === "annual"
                            ? item.price.annual
                            : item.price.monthly}
                        </Typography>
                        <Typography
                          variant={"subtitle1"}
                          color={"text.secondary"}
                          fontWeight={700}
                        >
                          {pricingOption === "annual" &&
                          item.title !== "Starter"
                            ? "/y"
                            : item.title !== "Starter"
                            ? "/mo"
                            : ""}
                        </Typography>
                      </Box>
                      <Grid container spacing={1}>
                        {item.features.map((feature, j) => (
                          <Grid item xs={12} key={j}>
                            <Box
                              component={ListItem}
                              disableGutters
                              width={"auto"}
                              padding={0}
                            >
                              <Box
                                component={ListItemAvatar}
                                minWidth={"auto !important"}
                                marginRight={2}
                              >
                                <Box
                                  component={Avatar}
                                  bgcolor={theme.palette.primary.main}
                                  width={20}
                                  height={20}
                                >
                                  <svg
                                    width={12}
                                    height={12}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </Box>
                              </Box>
                              <ListItemText primary={feature} />
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </CardContent>
                    <Box flexGrow={1} />
                    <CardActions
                      sx={{ justifyContent: "flex-end", padding: 4 }}
                    >
                      <Button
                        size={"large"}
                        variant={"contained"}
                        onClick={() => {
                          const unitPrice =
                            item.title === "Starter"
                              ? 8200
                              : item.title === "Pro" &&
                                pricingOption === "monthly"
                              ? "price_1NnQaiSBOxpsQq9ZG1Y3pRw8"
                              : // "price_1OjKmcSBOxpsQq9ZVLB4zbGH" //test price
                              item.title === "Pro" && pricingOption === "annual"
                              ? "price_1NnQdsSBOxpsQq9ZGBTMkjtq"
                              : item.title === "Enterprise" &&
                                pricingOption === "monthly"
                              ? "price_1NnQfrSBOxpsQq9ZnYslzgsx"
                              : "price_1NnQhlSBOxpsQq9ZfN0GlZ8A";

                          const subType =
                            item.title === "Starter"
                              ? "Starter"
                              : item.title === "Pro" &&
                                pricingOption === "monthly"
                              ? "ProMonthly"
                              : item.title === "Pro" &&
                                pricingOption === "annual"
                              ? "ProAnnual"
                              : item.title === "Enterprise" &&
                                pricingOption === "monthly"
                              ? "EnterpriseMonthly"
                              : "EnterpriseAnnual";

                          handleSubscribe(unitPrice, subType, "/");
                        }}
                      >
                        {item.title === "Starter"
                          ? "Buy Starter"
                          : pricingOption === "annual"
                          ? "Subscribe"
                          : "Subscribe"}
                      </Button>
                    </CardActions>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Dialog
              open={openErrorDialog}
              handleClose={handleCloseErrorDialog}
            />
            <LoginDialog
              open={openLoginDialog}
              handleClose={closeLoginDialog}
              navigate={navigate}
            />
          </Container>
          <StarterSubscribedDialog
            open={showSubscriptionDialog}
            handleClose={handleCloseSubscriptionDialog}
            text={subscriptionDialogText}
            buttonAction={subscriptionDialogButtonAction}
          />
          <CurrencyDialogComponent
            open={openCurrencyDialog}
            handleClose={closeCurrencyDialog}
            handleSelect={handleCurrencySelect}
          />
          <SimpleDialog
            open={openDialog}
            handleClose={handleCloseDialog}
            title="Upgrade to a Subscription Plan"
            text="You can buy the Starter plan only once. Please upgrade your plan to continue."
          />
        </Box>
      )}
    </>
  );
};

export default Main;
