import React, { useEffect, useState } from "react";
import instance from "../../../helpers/axiosInstance";

import Page from "./components/Page/Page";
import { Box, Typography, Button } from "@mui/material";

const BillingPortal = () => {
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("authToken") !== null
  );

  useEffect(() => {
    const checkRefresh = async () => {
      try {
        const tokenResponse = await instance.get("/api/auth/refresh-token");
        const accessToken = tokenResponse.data.accessToken;

        if (!accessToken) {
          localStorage.removeItem("authToken");
          setLoggedIn(false);
        }
      } catch (err) {
        console.log("checkingRefresh", err);
      }
    };

    checkRefresh();
  }, []);

  const openBillingPortal = async () => {
    try {
      const token = await instance.get("/api/auth/refresh-token");
      if (token.data && token.data.accessToken) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.data.accessToken}`,
          },
        };
        const customerId = await instance.get("/api/auth/customer", config);
        if (customerId.data.customerId) {
          const portal = await instance.post(
            "/api/stripe/portal",
            { customerId: customerId.data.customerId },
            config
          );
          if (portal.data.url) {
            window.open(portal.data.url, "_self");
          }
        }
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        console.log("Unauthorized. Please login again.");
      } else {
        console.log("Error in creating portal", err);
      }
    }
  };

  return (
    <Page title="Billing Portal">
      <Box
        className="billing-portal"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="h6" gutterBottom>
          Seamless Billing Management
        </Typography>
        <Typography variant="body1" gutterBottom>
          Review your invoice history, easily switch your subscription plan, or
          cancel your subscription at any time. Get started by clicking the
          button below.
        </Typography>
        {loggedIn ? (
          <Button
            variant="contained"
            color="primary"
            onClick={openBillingPortal}
          >
            Open Billing Portal
          </Button>
        ) : (
          <Typography variant="subtitle1">
            Please log in to access the billing portal.
          </Typography>
        )}
      </Box>
    </Page>
  );
};

export default BillingPortal;
