import creatOptTopicLang from "../OptTopicLangTemplate";

const songRecommender = creatOptTopicLang({
  name: "songrecommender",
  endpoint: "/api/openai/song-recommender",
  aiText: "songRecommender",
  aiTextHandler: "songRecommenderHandler",
  aiTextPlainText: "songRecommender PlainText",
  title: "Song Recommender",
  subText:
    "Discover the perfect song for your prayer gathering with our Song Recommender. Input a theme or scripture, like 'love' or 'Psalm 63', and receive a fitting song.",
  label: "Your Song Recommendation",
  placeholderText: "Enter a theme or Bible verse…",
  buttonText: "Recommend Song",
  aiPlaceholder: "Songs will appear here",
});

export default songRecommender;
