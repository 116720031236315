import createNoKeyOptLangToneTemplate from "../NoKeyOptLangToneTemplate";

const analogyMaker = createNoKeyOptLangToneTemplate({
  name: "analogymaker",
  endpoint: "/api/openai/analogy-maker",
  aiText: "analogyMaker",
  aiTextHandler: "analogyMakerHandler",
  aiTextPlainText: "analogyMakerPlainText",
  title: "Biblical Analogy Maker",
  subText:
    "Make Biblical concepts relatable with the Analogy Maker. Input a complex idea or scripture and get a simple, relatable analogy.",
  label: "Biblical Analogy",
  placeholderText: "Enter a Biblical concept or scripture…",
  buttonText: "Create Analogy",
  aiPlaceholder: "Your analogy will be displayed here",
});

export default analogyMaker;
