import createOptVersionTemplate from "../OptVersionTemplate";

const scriptureSays = createOptVersionTemplate({
  name: "scripturesays",
  endpoint: "/api/openai/scripture-says",
  aiText: "scriptureSays",
  aiTextHandler: "scriptureSaysHandler",
  aiTextPlainText: "scriptureSaysPlainText",
  title: "Scripture Says",
  subText:
    "Find what the Bible says about any subject with Scripture Says. Input a topic like 'humility' or 'perseverance' and get relevant scripture verses.",
  label: "Scripture Says",
  placeholderText: "Enter a topic…",
  buttonText: "Explore the Word",
  aiPlaceholder: "Scriptures will be displayed here",
});

export default scriptureSays;
