// import React, { useState } from "react";
// import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid";
// import TextField from "@mui/material/TextField";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import UploadingDialog from "./Dialog/UploadingDialog";
// import instance from "../../helpers/axiosInstance";

// function ToneOfVoiceUpload() {
//   const [toneName, setToneName] = useState("");
//   const [toneDescription, setToneDescription] = useState("");
//   const [isUploading, setIsUploading] = useState(false);

//   const uploadText = async (e) => {
//     e.preventDefault();
//     setIsUploading(true);

//     if (!toneName) {
//       console.error("No tone name for upload.");
//       setIsUploading(false);
//       return;
//     }

//     const data = {
//       toneName,
//       toneDescription,
//     };

//     try {
//       const authToken = localStorage.getItem("authToken");

//       const res = await instance.post(
//         "http://localhost:3030/api/tone/upload-tone",
//         data,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${authToken}`,
//           },
//         }
//       );

//       console.log(res.data);
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setIsUploading(false); // Set isUploading to false when the upload ends, whether it succeeds or fails

//       // Reset form values
//       setToneName("");
//       setToneDescription("");
//     }
//   };

//   return (
//     <Box
//       component="form"
//       onSubmit={uploadText}
//       sx={{ margin: { xs: "2rem", sm: "10rem" }, mt: 12 }}
//     >
//       <UploadingDialog open={isUploading} />
//       <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
//         Upload your Tone of Voice
//       </Typography>
//       <Typography color="text.secondary">
//         Fill out the form and press Upload.
//       </Typography>
//       <Grid container spacing={3} marginTop={2}>
//         <Grid item xs={12}>
//           <TextField
//             variant="outlined"
//             rows={3}
//             fullWidth
//             label="Name your tone of voice"
//             value={toneName}
//             onChange={(e) => setToneName(e.target.value)}
//           />
//         </Grid>
//         <Grid item xs={12}>
//           <TextField
//             variant="outlined"
//             multiline
//             rows={3}
//             fullWidth
//             label="Tone Description"
//             value={toneDescription}
//             onChange={(e) => setToneDescription(e.target.value)}
//           />
//         </Grid>

//         <Grid item xs={12}>
//           <Button
//             variant="contained"
//             color="primary"
//             type="submit"
//             fullWidth
//             disabled={isUploading}
//             sx={{ height: { xs: "auto", md: "56px" } }}
//           >
//             Upload
//           </Button>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// }

// export default ToneOfVoiceUpload;


import React, { useState } from "react";
import { getAuth } from "firebase/auth";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import UploadingDialog from "./Dialog/UploadingDialog";
import instance from "../../helpers/axiosInstance";

function ToneOfVoiceUpload() {
  const [toneName, setToneName] = useState("");
  const [toneDescription, setToneDescription] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);

  const handleClose = () => {
    setShowSuccess(false);
  };

  const uploadText = async (e) => {
    e.preventDefault();
    setIsUploading(true);
    setError("");

    if (!toneName) {
      setError("No tone name provided.");
      setIsUploading(false);
      return;
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      
      if (!user) {
        setError("You must be logged in to upload");
        setIsUploading(false);
        return;
      }

      const data = {
        toneName,
        toneDescription,
      };

      const response = await instance.post("/api/tone/upload-tone", data);

      if (response.status === 200) {
        // Reset form values
        setToneName("");
        setToneDescription("");
        setShowSuccess(true);
      }
    } catch (error) {
      console.error(error);
      setError(error.response?.data?.message || "Failed to upload tone of voice");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={uploadText}
      sx={{ margin: { xs: "2rem", sm: "10rem" }, mt: 12 }}
    >
      <UploadingDialog open={isUploading} />
      
      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Tone of voice uploaded successfully!
        </Alert>
      </Snackbar>
      
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
        Upload your Tone of Voice
      </Typography>
      
      <Typography color="text.secondary">
        Fill out the form and press Upload.
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3} marginTop={2}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            label="Name your tone of voice"
            value={toneName}
            onChange={(e) => setToneName(e.target.value)}
          />
        </Grid>
        
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            label="Tone Description"
            value={toneDescription}
            onChange={(e) => setToneDescription(e.target.value)}
          />
        </Grid>
        
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={isUploading}
            sx={{ height: { xs: "auto", md: "56px" } }}
          >
            {isUploading ? "Uploading..." : "Upload"}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ToneOfVoiceUpload;