import createOptLangToneTemplate from "../OptLangToneTemplate";

const biblicalSongwriter = createOptLangToneTemplate({
  name: "biblicalsongwriter",
  endpoint: "/api/openai/biblical-songwriter",
  aiText: "biblicalSongwriter",
  aiTextHandler: "biblicalSongwriterHandler",
  aiTextPlainText: "biblicalSongwriterPlainText",
  title: "Biblical Songwriter",
  subText:
    "Transform scriptures into song with the Biblical Songwriter. Enter a verse, like 'John 3:16', and get a beautiful worship song!",
  label: "Biblical Song",
  placeholderText: "Which verse would you like to transform?",
  buttonText: "Compose Song",
  aiPlaceholder: "Your song will be generated here",
});

export default biblicalSongwriter;
