import createOptLangKnowToneKeyTemplate from "../OptLangKnowToneKeyTemplate";

const blogWriter = createOptLangKnowToneKeyTemplate({
  name: "blogWriter",
  endpoint: "/api/openai/blog-writer",
  aiText: "blogWriter",
  aiTextHandler: "blogWriterHandler",
  aiTextPlainText: "blogWriterPlainText",
  title: "Blog Writer",
  subText:
    "Compose engaging Catholic blog posts with our Blog Writer. Input your topic and receive a thoughtfully-written blog post for your website.",
  label: "Your Blog Post",
  placeholderText: "Enter your blog's main topic…",
  buttonText: "Write Blog Post",
  aiPlaceholder: "Your blog post will appear here",
});

export default blogWriter;
