import React from "react";
import { colors } from "@mui/material";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import { alpha } from "@mui/material";

const saintSayings = {
  title: "Saint Sayings",
  subtitle: "Find which Saint spoke what on a particular topic.",
  targetRoute: "/saint-sayings",
  color: colors.teal[700],
  bgcolor: alpha(colors.teal[700], 0.1),
  icon: <MessageOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default saintSayings;
