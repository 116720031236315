import createNoKeyOptChapLangToneTemplate from "../NoKeyOptChapLangToneTemplate";

const couplesBibleStudyGuide = createNoKeyOptChapLangToneTemplate({
  name: "couplesbiblestudyguide",
  endpoint: "/api/openai/couples-bible-study-guide",
  aiText: "couplesBibleStudyGuide",
  aiTextHandler: "couplesBibleStudyGuideHandler",
  aiTextPlainText: "couplesBibleStudyGuidePlainText",
  title: "Couples' Bible Study Guide",
  subText:
    "Generate a couple-focused Bible study schedule. Input your preferred books or themes and duration, for example, “Genesis, 2 weeks”, and get a detailed study plan.",
  label: "Couples' Bible Study Schedule",
  placeholderText: "Input books or theme and duration…",
  buttonText: "Create Guide",
  aiPlaceholder: "Couples' Bible study schedule will be displayed here",
});

export default couplesBibleStudyGuide;
