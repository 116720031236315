import React from "react";
import { colors } from "@mui/material";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { alpha } from "@mui/material";

const marriageRetreatPlanner = {
  title: "Marriage Retreat Planner",
  subtitle: "Plan the perfect retreat to strengthen marriages.",
  targetRoute: "/marriage-retreat-planner",
  color: colors.pink[400],
  bgcolor: alpha(colors.pink[400], 0.1),
  icon: <FavoriteBorderOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default marriageRetreatPlanner;
