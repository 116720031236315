/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { checkSubscription } from "../../../../../helpers/subscriptionHelper";
import LoginDialog from "../../../Dialog/LoginDialog";
import SubscribeDialog from "../../../Dialog/SubscribeDialog";

import {
  bibleGamesForFamilies,
  bibleQuizGenerator,
  bibleStoriesForKids,
  bibleStudyGuide,
  bibleStudyPlanGenerator,
  bibleTriviaGeneratorAdults,
  bibleTriviaGeneratorChildren,
  biblicalSongwriter,
  couplesBibleStudyGuide,
  familyScriptureStudyPlan,
  scriptureCrossReference,
  scriptureSays,
  scriptureStudyPlanMen,
  scriptureStudyPlanWomen,
} from "../../../cards/BibleTools";
const Benefits = () => {
  const theme = useTheme();

  const navigate = useNavigate();

  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openSubscribeDialog, setOpenSubscribeDialog] = useState(false);

  const handleClickOpen = async (plan, targetRoute) => {
    if (localStorage.getItem("authToken")) {
      const hasSubscription = await checkSubscription();
      if (!hasSubscription) {
        setOpenSubscribeDialog(true);
      } else {
        if (targetRoute) {
          navigate(targetRoute);
        }
      }
    } else {
      setOpenLoginDialog(true);
    }
  };

  const mock = [
    bibleGamesForFamilies,
    bibleQuizGenerator,
    bibleStoriesForKids,
    bibleStudyGuide,
    bibleStudyPlanGenerator,
    bibleTriviaGeneratorAdults,
    bibleTriviaGeneratorChildren,
    biblicalSongwriter,
    couplesBibleStudyGuide,
    familyScriptureStudyPlan,
    scriptureCrossReference,
    scriptureSays,
    scriptureStudyPlanMen,
    scriptureStudyPlanWomen,
  ];

  return (
    <Grid container spacing={4}>
      {mock.map((item, i) => (
        <Grid item xs={12} sm={6} md={4} key={i}>
          <IconButton
            onClick={() => handleClickOpen("basic", item.targetRoute)}
            sx={{
              width: "100%",
              height: "100%",
              padding: 0,
              borderRadius: 2,
              border: "1px solid transparent",
              transition: "border-color 0.3s, box-shadow 0.3s",
              "&:hover": {
                borderColor: theme.palette.action.hover,
                boxShadow: theme.shadows[4],
              },
            }}
          >
            <Box
              component={Card}
              padding={4}
              borderRadius={2}
              width={1}
              height={1}
              data-aos={"fade-up"}
              data-aos-delay={i * 100}
              sx={{
                border: `1.2px solid ${theme.palette.divider}`,
                boxShadow: "none",
              }}
            >
              <Box display={"flex"} flexDirection={"column"}>
                <Box
                  component={Avatar}
                  width={60}
                  height={60}
                  marginBottom={2}
                  bgcolor={item.bgcolor}
                  color={item.color}
                  variant={"rounded"}
                  borderRadius={2}
                >
                  {item.icon}
                </Box>
                <Typography
                  variant={"h6"}
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                >
                  {item.title}
                </Typography>
                <Typography color="text.secondary">{item.subtitle}</Typography>
              </Box>
            </Box>
          </IconButton>
        </Grid>
      ))}

      <SubscribeDialog
        open={openSubscribeDialog}
        handleClose={() => setOpenSubscribeDialog(false)}
        navigate={navigate}
      />
      <LoginDialog
        open={openLoginDialog}
        handleClose={() => setOpenLoginDialog(false)}
        navigate={navigate}
      />
      <Grid item xs={12} container justifyContent="center">
        <Button
          component={RouterLink}
          to="/tools"
          variant="contained"
          color="primary"
          size="large"
          sx={{ mt: 3 }}
        >
          Discover over 50 more ministry-empowering tools
        </Button>
      </Grid>
    </Grid>
  );
};

export default Benefits;
