import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { alpha, useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import instance from "../../../../helpers/axiosInstance";
import ThemeModeToggler from "../../../../components/ThemeModeToggler";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../Main/components/Sidebar/Sidebar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";

import { NavItem } from "./components";
import pagesData from "../../../navigation";

import logo from "../../../../assets/images/ChurchScribe-logo.svg";
import negativeLogo from "../../../../assets/images/ChurchScribe-logo-negative.svg";
import MySidebar from "../MySidebar/MySidebar";

import { useRecoilValue, useResetRecoilState } from 'recoil';

import { logout } from '../../../../helpers/api';
import { authState } from '../../../../atoms';

const Topbar = ({
  onSidebarOpen,
  onMySidebarOpen,
  pages,
  colorInvert = false,
}) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const {
    about: aboutPages,
    tools: toolsPages,
    pricing: pricingPages,
    dashboard: dashboardPages,
    contact: contactPages,
  } = pagesData;

  const auth = useRecoilValue(authState);
  const resetAuthState = useResetRecoilState(authState);
  const isAuthenticated = auth.isAuthenticated;

  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("authToken") !== null
  );

  const [mobileOpen, setMobileOpen] = useState(false);
  const [mySidebarOpen, setMySidebarOpen] = useState(false);

  const navigate = useNavigate();

  const handleMenuClick = (path) => {
    navigate(path);
    setMobileOpen(false);
  };

  const handleMySidebarOpen = () => {
    setMySidebarOpen(!mySidebarOpen);
    if (typeof onMySidebarOpen === "function") {
      onMySidebarOpen();
    }
  };
  // const logoutHandler = async () => {
  //   try {
  //     await instance.post("/api/auth/logout").then((res) => {
  //       if (res.data.success) {
  //         localStorage.removeItem("authToken");
  //         navigate("/login"); // navigate to /login after logout
  //       }
  //     });
  //   } catch (err) {
  //     console.log("logouthanderr");
  //   }
  // };

  const logoutHandler = async () => {
    try {
      await logout();
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const sidebar = (
    <div>
      <List>
        {[
          { name: "About", path: "/about" },
          { name: "Tools", path: "/tools" },
          { name: "Pricing", path: "/pricing" },
          { name: "Dashboard", path: "/dashboard" },
          { name: "Contact", path: "/contact" },
        ].map((item, index) => (
          <ListItem key={item.name} onClick={() => handleMenuClick(item.path)}>
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
      </List>

      <Box marginLeft={4}>
        <ThemeModeToggler />
      </Box>
      {loggedIn ? (
        <Box marginLeft={4}>
          <Button onClick={logoutHandler} variant="contained" color="primary">
            Logout
          </Button>
        </Box>
      ) : (
        <>
          <Box marginLeft={4}>
            <Button
              component={Link}
              href="/register"
              variant="contained"
              color="primary"
            >
              Register
            </Button>
          </Box>
          <Box marginLeft={4}>
            <Button
              component={Link}
              href="/login"
              variant="contained"
              color="primary"
            >
              Login
            </Button>
          </Box>
        </>
      )}
    </div>
  );

  // useEffect(() => {
  //   const checkRefresh = async () => {
  //     try {
  //       const tokenResponse = await instance.get("/api/auth/refresh-token");
  //       const accessToken = tokenResponse.data.accessToken;

  //       if (!accessToken) {
  //         localStorage.removeItem("authToken");
  //         setLoggedIn(false);
  //       }
  //     } catch (err) {
  //       console.log("checkingRefresh", err);
  //     }
  //   };

  //   checkRefresh();
  // }, []);

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={1}
    >
      <Box
        display={"flex"}
        component="a"
        href="/"
        title="Cube"
        width={{ xs: 100, md: 120 }}
      >
        <Box
          component={"img"}
          src={mode === "light" ? logo : negativeLogo}
          height={35}
        />
      </Box>

      <Box sx={{ display: { xs: "none", md: "flex" } }} alignItems={"center"}>
        <Box>
          <NavItem
            title={"About"}
            id={"about-pages"}
            items={aboutPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={"Tools"}
            id={"tools-pages"}
            items={toolsPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={"Pricing"}
            id={"pricing-pages"}
            items={pricingPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={"Dashboard"}
            id={"dashboard-pages"}
            items={dashboardPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={"Contact"}
            id={"contact-pages"}
            items={contactPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <ThemeModeToggler />
        </Box>
        <Box sx={{ display: { xs: "none", md: "flex" } }} alignItems={"center"}>
          {loggedIn ? (
            <Box marginLeft={4}>
              <Button
                onClick={logoutHandler}
                variant="contained"
                color="primary"
              >
                Logout
              </Button>
            </Box>
          ) : (
            <>
              <Box marginLeft={4}>
                <Button
                  component={Link}
                  href="/register"
                  variant="contained"
                  color="primary"
                >
                  Register
                </Button>
              </Box>
              <Box marginLeft={4}>
                <Button
                  component={Link}
                  href="/login"
                  variant="contained"
                  color="primary"
                >
                  Login
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>

      <Box sx={{ display: { xs: "flex", md: "none" } }} alignItems={"center"}>
        <Button
          onClick={handleMySidebarOpen}
          aria-label="MySidebar Menu"
          variant={"outlined"}
          sx={{
            borderRadius: 2,
            minWidth: "auto",
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
            marginLeft: 2,
          }}
        >
          <TuneOutlinedIcon />
        </Button>
        <Button
          onClick={handleDrawerToggle}
          aria-label="Menu"
          variant={"outlined"}
          sx={{
            borderRadius: 2,
            minWidth: "auto",
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
            marginLeft: 2,
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
      <MySidebar
        onClose={handleMySidebarOpen}
        open={mySidebarOpen}
        variant="temporary"
      />
      <Sidebar
        onClose={handleDrawerToggle}
        open={mobileOpen}
        variant="temporary"
        pages={pagesData}
      />
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  onMySidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;
