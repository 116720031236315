import React from "react";
import { colors } from "@mui/material";
import Woman2OutlinedIcon from "@mui/icons-material/Woman2Outlined";
import { alpha } from "@mui/material";

const womensMinistryBlogPostIdeas = {
  title: "Women's Ministry Blog Idea Generator",
  subtitle: "Generate blog topics that resonate with your women's ministry.",
  targetRoute: "/womens-ministry-blog-post-ideas",
  color: colors.pink[200],
  bgcolor: alpha(colors.pink[200], 0.1),
  icon: <Woman2OutlinedIcon fontSize="medium" />,
  tags: [],
};

export default womensMinistryBlogPostIdeas;
