import React from "react";
import { Select, MenuItem, Checkbox, ListItemText } from "@mui/material";

const AudienceTypeSelect = ({ audienceType, setAudienceType }) => {
  const handleChange = (event) => {
    setAudienceType(event.target.value);
  };

  const audienceOptions = [
    "Youth",
    "Working Professionals",
    "Homemakers",
    "Parents",
    "College Students",
    "Young Children",
    "Retirees",
    "Spiritual Seekers",
    "Non-Believers",
  ];

  return (
    <Select
      multiple
      displayEmpty
      value={audienceType}
      onChange={handleChange}
      sx={{ mb: 1.5 }}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return "Select audience type";
        }
        return selected.join(", ");
      }}
      fullWidth
    >
      {audienceOptions.map((option) => (
        <MenuItem key={option} value={option}>
          <Checkbox checked={audienceType.indexOf(option) > -1} />
          <ListItemText primary={option} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default AudienceTypeSelect;
