import createSaintBiography from "../SaintBiographyTemplate";

const saintBiography = createSaintBiography({
  name: "saintbiography",
  endpoint: "/api/openai/saint-biography",
  aiText: "saintBiography",
  aiTextHandler: "saintBiographyHandler",
  aiTextPlainText: "saintBiographyPlainText",
  title: "Saint Biography Generator",
  subText:
    "Dive into the inspiring lives of the saints with our Biography Generator. Enter a saint's name like 'St. Francis of Assisi', and get a brief but detailed account of their life.",
  label: "Life of the Saint",
  placeholderText: "Enter the name of a saint...",
  buttonText: "Generate Biography",
  aiPlaceholder: "Saint's biography will appear here",
});

export default saintBiography;
