import React from "react";
import { colors } from "@mui/material";
import PageviewOutlinedIcon from "@mui/icons-material/PageviewOutlined";
import { alpha } from "@mui/material";

const relatedTopicsResearch = {
  title: "Related Topic Finder",
  subtitle: "Uncover relevant topics to broaden your content scope.",
  targetRoute: "/related-topics-research",
  color: colors.pink[900],
  bgcolor: alpha(colors.pink[900], 0.1),
  icon: <PageviewOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default relatedTopicsResearch;
