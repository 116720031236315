import createNoKeyOptLangToneTemplate from "../NoKeyOptLangToneTemplate";

const shortReflectionGenerator = createNoKeyOptLangToneTemplate({
  name: "shortreflectiongenerator",
  endpoint: "/api/openai/short-reflection-generator",
  aiText: "shortReflectionGenerator",
  aiTextHandler: "shortReflectionGeneratorHandler",
  aiTextPlainText: "shortReflectionGeneratorPlainText",
  title: "Short Reflection Generator",
  subText:
    "Generate concise yet profound Biblical reflections on any topic with our Short Reflection Generator. Enter a subject and receive a reflective thought.",
  label: "Short Insight",
  placeholderText: "Enter a topic...",
  buttonText: "Generate Reflection",
  aiPlaceholder: "Reflection will appear here",
});

export default shortReflectionGenerator;
