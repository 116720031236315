import createOptLangKnowKeyNoBibleTemplate from "../OptLangKnowKeyNoBibleTemplate";

const womensMinistryBlogPostIdeas = createOptLangKnowKeyNoBibleTemplate({
  name: "womensministryblogpostideas",
  endpoint: "/api/openai/womens-ministry-blog-post-ideas",
  aiText: "womensMinistryBlogPostIdeas",
  aiTextHandler: "womensMinistryBlogPostIdeasHandler",
  aiTextPlainText: "womensMinistryBlogPostIdeasPlainText",
  title: "Women's Ministry Blog Idea Generator",
  subText:
    "Unearth compelling blog post ideas for your Women's Ministry. Input a theme and get a tailored list of engaging blog post ideas.",
  label: "Blog Post Ideas",
  placeholderText: "Input a broad topic…",
  buttonText: "Generate Blog Ideas",
  aiPlaceholder: "Blog post ideas will appear here",
});

export default womensMinistryBlogPostIdeas;
