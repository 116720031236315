import React from "react";
import { colors } from "@mui/material";
import DynamicFormOutlinedIcon from "@mui/icons-material/DynamicFormOutlined";
import { alpha } from "@mui/material";

const bibleTriviaGeneratorAdults = {
  title: "Bible Trivia Generator - Adults",
  subtitle: "Engage adults in faith-based learning through trivia.",
  targetRoute: "/bible-trivia-generator-adults",
  color: colors.lime[600],
  bgcolor: alpha(colors.lime[600], 0.1),
  icon: <DynamicFormOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default bibleTriviaGeneratorAdults;
