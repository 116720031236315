import React, { useRef, useState, useEffect } from "react";
import { io } from "socket.io-client";
import {
  Box,
  Link,
  Typography,
  useTheme,
  useMediaQuery,
  Collapse,
  Alert,
  TextField,
  Button,
  Card,
  CircularProgress,
  Grid,
  ButtonGroup,
} from "@mui/material";


import instance from "../../../helpers/axiosInstance";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import MonthlyWordCountErrorDialog from "../Dialog/monthlyWordCountErrorDialog";
import trackButtonClick from "../../../helpers/trackButtonClick";
import GeneratingDialog from "../Dialog/GeneratingDialog";
import LanguageSelect from "../../../helpers/LanguageSelect";
import ToneOfVoice from "../../../helpers/ToneOfVoice";
import downloadPdf from "../../../helpers/downloadPdf";
import printDocument from "../../../helpers/printDocument";
import PrintDownErrorDialog from "../Dialog/PrintDownErrorDialog";
import CatholicBibleSelect from "../../../helpers/CatholicBibleSelect";
import ProtestantBibleSelect from "../../../helpers/ProtestantBibleSelect";
import CatholicBibleChaptersSelect from "../../../helpers/CatholicBibleChaptersSelect";
import ProtestantBibleChaptersSelect from "../../../helpers/ProtestantBibleChaptersSelect";

import {BaseControlRow} from "../../../utils/ControlRow";

import { auth } from '../../../firebase';

export function createLongTopicNoKeyOptChapLangToneTemplate({
  name,
  placeholderText,
  label,
  title,
  subText,
  buttonText,
  aiPlaceholder,
}) {
  return function LongTopicNoKeyOptChapLangToneTemplate() {
    const theme = useTheme();
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    const contentRef = useRef(null);
  
    const [topic, setTopic] = useState("");
    const [error, setError] = useState("");
    const [aiText, setAiText] = useState("");
    const [aiTextPlainText, setAiTextPlainText] = useState("");
    const [information, setInformation] = useState("");
    const [informationError, setInformationError] = useState("");
    const [loading, setLoading] = useState(false);
    const [isPrintDownErrorDialogOpen, setPrintDownErrorDialogOpen] =
      useState(false);
    const [copied, setCopied] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [topicError, setTopicError] = useState("");
    const [language, setLanguage] = useState("English (American)");
    const [toneOfVoice, setToneOfVoice] = useState("");
    const [toneOfVoiceError, setToneOfVoiceError] = useState("");
    const [churchType, setChurchType] = useState("Catholic");

    const [toneName, setToneName] = useState([]);
    const [selectedTone, setSelectedTone] = useState("");
    const [useUploadedTone, setUseUploadedTone] = useState(false);
    const [toneOption, setToneOption] = useState("");
    const [bibleVersion, setBibleVersion] = useState("");
    const [bibleChapter, setBibleChapter] = useState([]);

    const [modelType, setModelType] = useState("gpt");

    const [socket, setSocket] = useState(null);
    const [isInitializing, setIsInitializing] = useState(false);
    const [isStreaming, setIsStreaming] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // Socket setup - keep this exactly as is

    useEffect(() => {
      const setupSocket = async () => {
        try {
          const user = auth.currentUser;
          if (!user) {
            setError("Authentication required");
            return null;
          }
     
          const token = await user.getIdToken();
          const socketUrl = process.env.NODE_ENV === 'production' 
            ? process.env.REACT_APP_API_URL 
            : "http://localhost:4242";
     
          console.log("Connecting to socket at:", socketUrl);
          
          const newSocket = io(socketUrl, {
            auth: { token },
            transports: ["websocket"],
            withCredentials: true,
            autoConnect: true,
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: 5
          });
     
          newSocket.on("connect", () => {
            console.log("Socket connected with ID:", newSocket.id);
            setError("");
          });
     
          newSocket.on("connect_error", (err) => {
            console.error("Socket connection error:", err);
            setError(`Connection error: ${err.message}`);
          });
     
          newSocket.on("disconnect", (reason) => {
            console.log("Socket disconnected:", reason);
            if (reason === "io server disconnect") {
              newSocket.connect();
            }
          });
     
          return newSocket;
        } catch (error) {
          console.error("Socket setup error:", error);
          setError(`Setup error: ${error.message}`);
          return null;
        }
      };
     
      setupSocket().then(socket => {
        if (socket) {
          setSocket(socket);
          return () => {
            console.log("Cleaning up socket connection");
            socket.disconnect();
          };
        }
      });
     }, [name, label]);
     
  

    useEffect(() => {
      if (!socket) return;
    
      const handleProgress = ({ content }) => {
        if (isInitializing) {
          setIsInitializing(false);
          setIsStreaming(true);
          setIsLoading(false);
        }
       
         // Clean the content before adding it to aiText
         let cleanContent = content;
         if (isInitializing || !aiText) {
           // Only clean html prefix for first chunk
           cleanContent = content.replace(/^html/i, '');
         }
         
         // Remove closing backticks at any point
         cleanContent = cleanContent.replace(/```\s*$/g, '');
     
         setAiText(prevText => prevText + cleanContent);
       };

      const handleComplete = () => {
        setAiText(prevText => prevText.replace(/```\s*$/g, ''));
        setIsStreaming(false);
        setButtonDisabled(false);
        setIsLoading(false);
      };
    
      socket.on('generation-progress', handleProgress);
      socket.on('generation-complete', handleComplete);
      
      return () => {
        socket.off('generation-progress', handleProgress);
        socket.off('generation-complete', handleComplete);
      };
    }, [socket, isInitializing]);

    useEffect(() => {
      if (contentRef.current && aiText) {
        const scrollElement = contentRef.current;
        const isNearBottom = scrollElement.scrollHeight - scrollElement.scrollTop <= scrollElement.clientHeight + 100;
        
        if (isNearBottom) {
          scrollElement.scrollTop = scrollElement.scrollHeight;
        }
      }
    }, [aiText]);
    
    
    const handleChurchTypeChange = (event) => {
      setChurchType(event.target.checked ? "Protestant" : "Catholic");
    };

    const handleBibleVersionChange = (event) => {
      setBibleVersion(event.target.value);
    };

    let BibleSelect =
    churchType === "Catholic" ? CatholicBibleSelect : ProtestantBibleSelect;

    let BibleChaptersSelect =
    churchType === "Catholic"
      ? CatholicBibleChaptersSelect
      : ProtestantBibleChaptersSelect;

  useEffect(() => {
    switch (churchType) {
      case "Protestant":
        setBibleVersion("New International Version (NIV)");
        break;
      case "Catholic":
        setBibleVersion("New American Bible (NAB)");
        break;
      default:
        setBibleVersion("");
    }
  }, [churchType]);

  useEffect(() => {
    switch (churchType) {
      case "Protestant":
        setBibleChapter(["Entire Bible"]);
        break;
      case "Catholic":
        setBibleChapter(["Entire Bible"]);
        break;
      default:
        setBibleChapter([]);
    }
  }, [churchType]);


    

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
      const fetchToneName = async () => {
        if (useUploadedTone) {
          // Check if the useUploadedTone is checked
          try {
            const token = localStorage.getItem("authToken");
            const config = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };
            console.log(`Sending request for tone: ${selectedTone}`);

            const { data } = await instance.get("/api/tone/toneName", config);
            setToneName(data.toneName);
            console.log(data.toneName);
          } catch (error) {
            console.error(error);
          }
        }
      };

      fetchToneName();
    }, [useUploadedTone, instance]);

    const aiTextHandler = async (e) => {
      e.preventDefault();

      setAiText(''); 
      setIsInitializing(true);
      setIsStreaming(false);
      setError("");
      setButtonDisabled(true);
      setIsLoading(true);
    

      // New validations
      if (!topic) {
        setTopicError("Please input text.");
        setLoading(false);
        setButtonDisabled(false);
        return;
      }

      if (topic.split(" ").filter((n) => n).length < 1) {
        setTopicError("Text should contain at least 1 word.");
        setLoading(false);
        setButtonDisabled(false);
        return;
      }

      if (toneOption === "write" && !toneOfVoice.trim()) {
        setToneOfVoiceError("Please input a tone of voice.");
        setLoading(false);
        setButtonDisabled(false);
        return;
      }


      try {
        

        const payload = {
          topic,
          language,
          churchType,
          toneOfVoice,
          information,
          bibleVersion,
          bibleChapter,
          useMyUploadedTone: useUploadedTone,
          toolName: name,
          model: modelType

        };

        if (useUploadedTone) {
          payload.toneName = selectedTone;
        }
        // Emit the generation request
        socket.emit("start-generation", payload);
      } catch (err) {
        console.error("Error starting generation:", err);
        setError("Error starting generation. Please try again.");
        setIsInitializing(false);
        setButtonDisabled(false);
        setIsLoading(false);
      }
    };
    const copyToClipboardHtml = () => {
      const htmlContent = aiText; // This should be your HTML-formatted string
      const tempElement = document.createElement("div");
      tempElement.style.position = "absolute";
      tempElement.style.left = "-9999px";
      tempElement.innerHTML = htmlContent;
      document.body.appendChild(tempElement);

      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(tempElement);
      selection.removeAllRanges();
      selection.addRange(range);

      try {
        document.execCommand("copy");
        setCopied(true);
        setTimeout(() => setCopied(false), 5000);
      } catch (err) {
        console.error("Error copying HTML content: ", err);
      }

      document.body.removeChild(tempElement);
      selection.removeAllRanges();
    };

    const handleDownload = () => {
      if (!contentRef || !contentRef.current) {
        setPrintDownErrorDialogOpen(true);
        return;
      }

      downloadPdf(contentRef);
    };

    const handlePrint = () => {
      if (!contentRef || !contentRef.current) {
        setPrintDownErrorDialogOpen(true);
        return;
      }

      printDocument(contentRef);
    };

    // Define the resetAll function to reset all the states
    const resetAll = () => {
      setTopic("");
      setAiText("");
      setAiTextPlainText("");
      setError("");
      setLoading(false);
      setButtonDisabled(false);
      setLanguage("English (American)");
      setInformation("");
      setToneOfVoice("");
      setChurchType("Catholic");
      setToneName("");
      setSelectedTone("");
      setUseUploadedTone(false);
      setToneOption("");
      setBibleVersion("");
      setBibleChapter([]);
    };

    const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    return (
      <Box
        width={isNotMobile ? "70%" : "90%"}
        p="2rem"
        m="2rem auto"
        borderRadius={5}
        backgroundColor={theme.palette.background.alt}
        sx={{ boxShadow: 5 }}
      >
        <Collapse in={error}>
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        </Collapse>
        
        <form onSubmit={aiTextHandler}>
          <Box sx={{ mb: 3 }}>
            <Typography variant="h5" mb={1}>
              {title}
            </Typography>
            <Typography variant="body2" mb={2}>
              {subText}
            </Typography>
            <BaseControlRow
              modelType={modelType}
              setModelType={setModelType}
              toneOption={toneOption}
              setToneOption={setToneOption}
              setUseUploadedTone={setUseUploadedTone}
              churchType={churchType}
              handleChurchTypeChange={handleChurchTypeChange}
            />
          </Box>
          
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                placeholder={placeholderText}
                fullWidth
                multiline
                rows={3}
                value={topic}
                error={Boolean(topicError)}
                helperText={topicError}
                onChange={(e) => {
                  const wordCount = e.target.value
                    .split(" ")
                    .filter((n) => n).length;
                  if (wordCount <= 300) {
                    setTopic(e.target.value);
                    setTopicError("");
                  } else {
                    setTopicError(
                      "Input should not contain more than 300 words."
                    );
                  }
                }}
              />
            </Grid>
    
            <Grid item xs={12}>
              <TextField
                multiline
                rows={3}
                placeholder="Input any additional information (optional)"
                fullWidth
                value={information}
                error={Boolean(informationError)}
                helperText={informationError}
                onChange={(e) => {
                  const wordCount = e.target.value
                    .split(" ")
                    .filter((n) => n).length;
                  if (wordCount <= 500) {
                    setInformation(e.target.value);
                    setInformationError("");
                  } else {
                    setInformationError(
                      "Information should not contain more than 500 words."
                    );
                  }
                }}
              />
            </Grid>
    
            <Grid item xs={12}>
              <ToneOfVoice
                toneOption={toneOption}
                toneOfVoice={toneOfVoice}
                setToneOfVoice={setToneOfVoice}
                setToneOfVoiceError={setToneOfVoiceError}
                toneName={toneName}
                selectedTone={selectedTone}
                setSelectedTone={setSelectedTone}
              />
            </Grid>
          </Grid>
    
          {["Protestant", "Catholic"].includes(churchType) && (
            <>
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item xs={12} sm={6}>
                  <BibleSelect
                    bibleVersion={bibleVersion}
                    setBibleVersion={handleBibleVersionChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BibleChaptersSelect
                    bibleChapter={bibleChapter}
                    setBibleChapter={setBibleChapter}
                  />
                </Grid>
              </Grid>

                
              
             
                <Grid container spacing={2} alignItems="flex-end">
                <Grid item xs={12} sm={6}>
                  <LanguageSelect
                    language={language}
                    setLanguage={setLanguage}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Button
                    disableElevation
                    variant="contained"
                    type="submit"
                    sx={{
                      color: "white",
                      width: "100%",
                      padding: "1.1em",
                      mb: 1.5,
                    }}
                    disabled={buttonDisabled}
                  >
                    {buttonText}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </form>
    
        <Card
          sx={{
            p: 2,
            border: 1,
            boxShadow: 0,
            borderColor: theme.palette.divider,
            borderRadius: 2,
            height: "500px",
            bgcolor: theme.palette.background.alt,
            position: "relative",
            overflow: 'auto'
          }}
        >
          {isLoading && (
            <Box sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              zIndex: 2
            }}>
              <CircularProgress color="primary" />
              <Typography variant="h6" color="text.primary" sx={{ mt: 2 }}>
                Generating...
              </Typography>
            </Box>
          )}
    
          {aiText && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "50px",
                position: "sticky",
                top: 0,
                bgcolor: theme.palette.background.level1,
                px: 1,
                zIndex: 1,
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Typography
                variant="body2"
                component="span"
                sx={{
                  mt: 1.8,
                  ml: 1,
                  color: "currentColor",
                  fontWeight: 500,
                }}
              >
                {label}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={copyToClipboardHtml}
              >
                {copied ? (
                  <DoneOutlinedIcon
                    sx={{ mr: 1, mt: 1.5 }}
                  />
                ) : (
                  <ContentPasteOutlinedIcon
                    sx={{ mr: 1, mt: 1.5 }}
                  />
                )}
                <Typography
                  variant="body2"
                  component="span"
                  sx={{
                    mx: 1,
                    mt: 1.8,
                    color: "currentColor",
                    fontWeight: 500,
                  }}
                >
                  {copied ? "Copied!" : "Copy text"}
                </Typography>
              </Box>
            </Box>
          )}
    
          <Box
            component="div"
            ref={contentRef}
            sx={{
              m: 0,
              p: '1rem 16px',
              height: aiText ? '100%' : 'auto',
              overflow: 'auto',
              fontWeight: 500,
              fontSize: 16,
              lineHeight: 1.8,
              bgcolor: theme.palette.background.level1,
              visibility: isLoading ? 'hidden' : 'visible',
              position: 'relative',
              minHeight: '100%',
              '& h1, & h2, & h3, & h4, & h5, & h6': {
                lineHeight: 1.4,
                marginBottom: '0.5em',
                marginTop: '1em'
              },
              '& p': {
                marginBottom: '1em',
                lineHeight: 1.5
              }
            }}
          >
            {aiText ? (
              <div
                dangerouslySetInnerHTML={{ __html: aiText }}
                style={{ 
                  transition: 'none',
                  WebkitTransition: 'none'
                }}
              />
            ) : (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontWeight: 500,
                  fontSize: 20,
                  textAlign: "center",
                }}
              >
                {aiPlaceholder}
              </Box>
            )}
          </Box>
        </Card>
    
        <Grid
          container
          justifyContent={matches ? "center" : "space-between"}
          alignItems="center"
          sx={{ my: 2 }}
        >
          <Grid
            item
            xs={matches ? 12 : "auto"}
            sx={{ textAlign: matches ? "center" : "left" }}
          >
            <Typography>
              Not the tool you were looking for?{" "}
              <Link href="/dashboard">Go back</Link>
            </Typography>
          </Grid>
          <Grid
            item
            xs={matches ? 12 : "auto"}
            sx={{ textAlign: matches ? "center" : "right" }}
          >
            <ButtonGroup variant="contained" color="primary">
              <Button
                color="inherit"
                onClick={handleDownload}
              >
                Download PDF
              </Button>
              <Button color="inherit" onClick={handlePrint}>
                Print
              </Button>
              <Button color="primary" onClick={resetAll}>
                Reset
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
    
        <PrintDownErrorDialog
          open={isPrintDownErrorDialogOpen}
          onClose={() => setPrintDownErrorDialogOpen(false)}
        />
        <GeneratingDialog open={loading} />
        <MonthlyWordCountErrorDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          errorMessage="Monthly word limit exceeded. Please wait until the next reset or upgrade your subscription."
        />
      </Box>
    );
  };
}

export default createLongTopicNoKeyOptChapLangToneTemplate;
