import React from "react";
import { colors } from "@mui/material";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
import { alpha } from "@mui/material";

const blogTopicIdeas = {
  title: "Blog Idea Generator",
  subtitle: "Generate blog topics that can rank high on Google",
  targetRoute: "/blog-topic-ideas",
  color: colors.lightBlue[600],
  bgcolor: alpha(colors.lightBlue[600], 0.1),
  icon: <EmojiObjectsOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default blogTopicIdeas;
