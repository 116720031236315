import React from "react";
import { colors } from "@mui/material";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import { alpha } from "@mui/material";

const bibleQuizGenerator = {
  title: "Bible Quiz Generator",
  subtitle: "Engage your church community with fun Bible quizzes.",
  targetRoute: "/bible-quiz-generator",
  color: colors.purple[600],
  bgcolor: alpha(colors.purple[600], 0.1),
  icon: <QuizOutlinedIcon fontSize="medium" />,
  tags: [],
};

export default bibleQuizGenerator;
