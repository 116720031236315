import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link as RouterLink } from "react-router-dom";

const mock = [
  {
    title: "What is Church Scribe?",
    subtitle:
      "Church Scribe is an AI writer designed for the Catholic Church. It helps create content like sermons, articles, blogs, video scripts, and more.",
  },
  {
    title: "What can I do with the $1 plan?",
    subtitle:
      "Our $1 subscription provides 3000 words, allowing you to explore Church Scribe's capabilities and generate content for your needs.",
  },
  {
    title: "Is my information safe?",
    subtitle:
      "Yes, we prioritize the security and privacy of your information. Church Scribe adheres to strict privacy and security standards.",
  },
  {
    title: "How accurate is the AI writer?",
    subtitle:
      "Church Scribe is built on GPT4 to ensure high accuracy. We recommend a final human review to align it perfectly with your message.",
  },
  {
    title: "Is using an AI writer for church ethical?",
    subtitle:
      "Yes, Church Scribe is designed to assist with content creation, not to replace human creativity or interpretation. It helps to lighten the workload.",
  },
  {
    title: "Do the unused words roll over?",
    subtitle:
      "No, any leftover words from your subscription will not roll over to the next month. Please plan your usage to maximize the value of your subscription.",
  },
];

const Faq = () => {
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography fontWeight={700} variant={"h4"} align={"center"}>
          Frequently asked questions
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12} sm={6} md={4}>
            <Typography variant={"h6"} fontWeight={600} gutterBottom>
              {item.title}
            </Typography>
            <Typography color="text.secondary">{item.subtitle}</Typography>
          </Grid>
        ))}
      </Grid>
      <Box marginTop={4} display="flex" justifyContent="center">
        <Button
          component={RouterLink}
          to="/faq"
          variant="contained"
          color="primary"
          size="large"
        >
          Click for more FAQs
        </Button>
      </Box>
    </Box>
  );
};

export default Faq;
