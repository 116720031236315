import createNoKeyOptChapLangToneTemplate from "../NoKeyOptChapLangToneTemplate";

const bibleTriviaGeneratorChildren = createNoKeyOptChapLangToneTemplate({
  name: "bibletriviageneratorchildren",
  endpoint: "/api/openai/bible-trivia-generator-children",
  aiText: "bibleTriviaGeneratorChildren",
  aiTextHandler: "bibleTriviaGeneratorChildrenHandler",
  aiTextPlainText: "bibleTriviaGeneratorChildrenPlainText",
  title: "Bible Trivia Generator - Children",
  subText:
    "Generate engaging Bible trivia questions for children. Enter a topic or book of the Bible and create a fun quiz.",
  label: "Bible Trivia for Children",
  placeholderText: "Enter a topic or book of the Bible…",
  buttonText: "Generate Trivia",
  aiPlaceholder: "Bible trivia questions will appear here",
});

export default bibleTriviaGeneratorChildren;
