import createNoKeyOptLangToneTemplate from "../NoKeyOptLangToneTemplate";

const familyPrayerGenerator = createNoKeyOptLangToneTemplate({
  name: "familyprayergenerator",
  endpoint: "/api/openai/family-prayer-generator",
  aiText: "familyPrayerGenerator",
  aiTextHandler: "familyPrayerGeneratorHandler",
  aiTextPlainText: "familyPrayerGeneratorPlainText",
  title: "Family Prayer Generator",
  subText:
    "Create a heartfelt prayer for your family. Input your family's needs or situations and receive a personalized prayer.",
  label: "Your Family Prayer",
  placeholderText: "Enter your family's needs or situations…",
  buttonText: "Generate Family Prayer",
  aiPlaceholder: "Your family prayer will appear here",
});

export default familyPrayerGenerator;
