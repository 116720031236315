import React, { useRef, useState, useEffect } from "react";
import { io } from "socket.io-client";
import {
  Box,
  Link,
  Typography,
  useTheme,
  useMediaQuery,
  Collapse,
  Alert,
  Button,
  ButtonGroup,
  Card,
  CircularProgress,
  Grid,
  Switch,
} from "@mui/material";
import * as colors from "@mui/material/colors";

import instance from "../../../helpers/axiosInstance";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import MonthlyWordCountErrorDialog from "../Dialog/monthlyWordCountErrorDialog";
import trackButtonClick from "../../../helpers/trackButtonClick";
import AudioLanguage from "../../../helpers/audioLanguageSelect";
import LanguageSelect from "../../../helpers/LanguageSelect";
import GeneratingDialog from "../Dialog/GeneratingDialog";
import downloadPdf from "../../../helpers/downloadPdf";
import printDocument from "../../../helpers/printDocument";
import PrintDownErrorDialog from "../Dialog/PrintDownErrorDialog";

import { auth } from '../../../firebase';

export function createBlogFromSpeechTemplate({
  name,
  label,
  title,
  subText,
  buttonText,
  aiPlaceholder,
}) {
  return function BlogFromSpeechScreen() {
    const theme = useTheme();
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    const contentRef = useRef(null);

    const [topic, setTopic] = useState("");
    const [file, setFile] = useState(null);
    const [error, setError] = useState("");
    const [aiText, setAiText] = useState("");
    const [aiTextPlainText, setAiTextPlainText] = useState("");
    const [loading, setLoading] = useState(false);
    const [isPrintDownErrorDialogOpen, setPrintDownErrorDialogOpen] =
      useState(false);
    const [copied, setCopied] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [audioLanguage, setAudioLanguage] = useState("");
    const [language, setLanguage] = useState("");
    const [fileName, setFileName] = useState("");
    const [churchType, setChurchType] = useState("Catholic");

    const [socket, setSocket] = useState(null);
    const [isInitializing, setIsInitializing] = useState(false);
    const [isStreaming, setIsStreaming] = useState(false);
    const [isLoading, setIsLoading] = useState(false);



    // Socket setup - keep this exactly as is

    useEffect(() => {
      const setupSocket = async () => {
        try {
          const user = auth.currentUser;
          if (!user) {
            setError("Authentication required");
            return null;
          }
     
          const token = await user.getIdToken();
          const socketUrl = process.env.NODE_ENV === 'production' 
            ? process.env.REACT_APP_API_URL 
            : "http://localhost:4242";
     
          console.log("Connecting to socket at:", socketUrl);
          
          const newSocket = io(socketUrl, {
            auth: { token },
            transports: ["websocket"],
            withCredentials: true,
            autoConnect: true,
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: 5
          });
     
          newSocket.on("connect", () => {
            console.log("Socket connected with ID:", newSocket.id);
            setError("");
          });
     
          newSocket.on("connect_error", (err) => {
            console.error("Socket connection error:", err);
            setError(`Connection error: ${err.message}`);
          });
     
          newSocket.on("disconnect", (reason) => {
            console.log("Socket disconnected:", reason);
            if (reason === "io server disconnect") {
              newSocket.connect();
            }
          });
     
          return newSocket;
        } catch (error) {
          console.error("Socket setup error:", error);
          setError(`Setup error: ${error.message}`);
          return null;
        }
      };
     
      setupSocket().then(socket => {
        if (socket) {
          setSocket(socket);
          return () => {
            console.log("Cleaning up socket connection");
            socket.disconnect();
          };
        }
      });
     }, [name, label]);
     
  

    useEffect(() => {
      if (!socket) return;
    
      const handleProgress = ({ content }) => {
        if (isInitializing) {
          setIsInitializing(false);
          setIsStreaming(true);
          setIsLoading(false);
        }
        setAiText(prevText => prevText + content);
      };
    
      const handleComplete = () => {
        setIsStreaming(false);
        setButtonDisabled(false);
        setIsLoading(false);
      };
    
      socket.on('generation-progress', handleProgress);
      socket.on('generation-complete', handleComplete);
      
      return () => {
        socket.off('generation-progress', handleProgress);
        socket.off('generation-complete', handleComplete);
      };
    }, [socket, isInitializing]);

    useEffect(() => {
      if (contentRef.current && aiText) {
        const scrollElement = contentRef.current;
        const isNearBottom = scrollElement.scrollHeight - scrollElement.scrollTop <= scrollElement.clientHeight + 100;
        
        if (isNearBottom) {
          scrollElement.scrollTop = scrollElement.scrollHeight;
        }
      }
    }, [aiText]);
    
    const handleChurchTypeChange = (event) => {
      setChurchType(event.target.checked ? "Protestant" : "Catholic");
    };

    const pollForResults = async (taskId, toolName) => {
      console.log("tool name:", toolName);
      try {
        const token = localStorage.getItem("authToken");
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const { data } = await instance.get(
          `/api/status/check-status?taskId=${taskId}`,
          config
        );

        // Log the received data to the console
        console.log(data);

        if (data.status === "complete") {
          console.log("result:", data.result);

          setLoading(false);

          const receivedText = data.result[toolName];
          if (receivedText) {
            setAiText(receivedText);
            setAiTextPlainText(receivedText.replace(/<br\/>/g, "\n"));
          } else {
            setError(`No ${label} received from the server. Please try again.`);
            setTimeout(() => setError(""), 5000);
          }
        } else {
          setTimeout(() => pollForResults(taskId, toolName), 5000); // 5-second delay
        }
      } catch (err) {
        // Handle error
        setError("Error while fetching result. Please try again.");
        setTimeout(() => setError(""), 5000);
      }
    };

    const aiTextHandler = async (e) => {
      e.preventDefault();

      // Track button click here
      trackButtonClick(label);

      setLoading(true);
      setButtonDisabled(true); // Disable the button when processing starts

      setLoading(true);
      try {
        const token = localStorage.getItem("authToken");
        // Retrieve the token from local storage

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const formData = new FormData();
        formData.append("file", file);
        formData.append("topic", topic);
        formData.append("audioLanguage", audioLanguage);
        formData.append("language", language);
        formData.append("toolName", name);
        formData.append("churchType", churchType);

        const { data } = await instance.post(
          "/api/generation/start-generation",
          formData,
          config
        );

        console.log("name:", name);

        const taskId = data.taskId;

        // Start polling for results
        pollForResults(taskId, name);
      } catch (err) {
        let errorMessage = "Unknown error. Please try again.";
        if (err.response && err.response.status === 402) {
          errorMessage = err.response.data.message;
          setDialogOpen(true);
        } else if (
          err.response &&
          err.response.status === 403 &&
          err.response.data.message.includes("Monthly word limit exceeded")
        ) {
          errorMessage = err.response.data.message;
          setDialogOpen(true);
        } else if (err.response && err.response.data.error) {
          setError(err.response.data.error);
        } else if (err.message) {
          setError(err.message);
        }
        setTimeout(() => {
          setError("");
        }, 5000);
      } finally {
        setButtonDisabled(false);
      }
    };

    const copyToClipboard = () => {
      navigator.clipboard.writeText("\n" + aiTextPlainText + "\n");
      setCopied(true);
      setTimeout(() => setCopied(false), 5000);
    };

    const handleDownload = () => {
      if (!contentRef || !contentRef.current) {
        setPrintDownErrorDialogOpen(true);
        return;
      }

      downloadPdf(contentRef);
    };

    const handlePrint = () => {
      if (!contentRef || !contentRef.current) {
        setPrintDownErrorDialogOpen(true);
        return;
      }

      printDocument(contentRef);
    };

    const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    return (
      <Box
        width={isNotMobile ? "70%" : "90%"}
        p="2rem"
        m="2rem auto"
        borderRadius={5}
        backgroundColor={theme.palette.background.alt}
        sx={{ boxShadow: 5 }}
      >
        <Collapse in={error}>
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        </Collapse>
        <form onSubmit={aiTextHandler}>
          <Typography variant="h5" mb={2}>
            {title}
          </Typography>
          <Typography variant="body2" mb={2}>
            {subText}
          </Typography>
          <Grid container alignItems="center" spacing={2} mb={2}>
            <Grid item></Grid>
            <Grid item>
              <Switch
                checked={churchType === "Protestant"}
                onChange={handleChurchTypeChange}
                color="default"
                sx={{
                  "& .MuiSwitch-switchBase": {
                    color: churchType === "Catholic" ? colors.purple[700] : "",
                  },
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: churchType === "Protestant" ? colors.pink[700] : "",
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                    backgroundColor:
                      churchType === "Protestant" ? colors.pink[100] : "",
                  },
                  "& .MuiSwitch-track": {
                    backgroundColor:
                      churchType === "Catholic" ? colors.purple[100] : "",
                  },
                }}
              />
            </Grid>
            <Grid item>{churchType}</Grid>
          </Grid>
          <Typography variant="body2" mb={2}>
            <strong>Please note:</strong>
            <ul>
              <li>
                The audio file should be in mp3 format and not exceed 25mb.
              </li>
              <li>
                The quality of results is highly dependent on the clarity of the
                audio. Please ensure the audio is clear.
              </li>
              <li>
                Choose the correct audio language from the dropdown list.
                Incorrect selection may lead to incorrect or even nonsensical
                outputs.
              </li>
              <li>
                Audio-to-text transcriptions may take longer due to their
                complexity. Please be patient.
              </li>
            </ul>
          </Typography>

          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={12} sm={6}>
              <input
                accept="audio/*"
                type="file"
                id="file"
                name="file"
                style={{ display: "none" }}
                onChange={(e) => {
                  setFile(e.target.files[0]);
                  setFileName(e.target.files[0]?.name || "");
                }}
              />
              <label htmlFor="file">
                <Button
                  disableElevation
                  variant="outlined"
                  component="span"
                  sx={{
                    color: "primary",
                    width: "100%",
                    padding: "1.1em",
                    mb: 1.5,
                  }}
                >
                  Select audio
                </Button>
              </label>
              <Typography variant={"subtitle2"} color="primary">
                {fileName ? fileName : null}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <AudioLanguage
                audioLanguage={audioLanguage}
                setAudioLanguage={setAudioLanguage}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={12} sm={6}>
              <LanguageSelect language={language} setLanguage={setLanguage} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                disableElevation
                variant="contained"
                type="submit"
                sx={{
                  color: "white",
                  width: "100%",
                  padding: "1.1em",
                  mb: 1.5,
                }}
                disabled={buttonDisabled}
              >
                {buttonText}
              </Button>
            </Grid>
          </Grid>
        </form>
        {/* Card component */}
        <Card
          sx={{
            p: 2,
            border: 1,
            boxShadow: 0,
            borderColor: theme.palette.divider,
            borderRadius: 2,
            height: "500px",
            bgcolor: "background.default",
            position: "relative",
            overflow: loading ? "hidden" : "auto",
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* Top border with label and copy button */}
          {aiText && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "50px",
                position: "absolute",
                top: -16,
                left: 0,
                right: 0,
                bgcolor: theme.palette.background.level1,
                px: 1,
                zIndex: 1,
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Typography
                variant="body2"
                component="span"
                sx={{
                  marginTop: 1.8,
                  marginLeft: 1,
                  color: "currentColor",
                  fontWeight: 500,
                }}
              >
                {label}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={copyToClipboard}
              >
                {copied ? (
                  <DoneOutlinedIcon
                    color="theme.palette.text.primary"
                    style={{ marginRight: 1, marginTop: 12 }}
                  />
                ) : (
                  <ContentPasteOutlinedIcon
                    color="theme.palette.text.primary"
                    style={{ marginRight: 1, marginTop: 12 }}
                  />
                )}
                <Typography
                  variant="body2"
                  component="span"
                  sx={{
                    marginLeft: 1,
                    marginRight: 1,
                    marginTop: 1.8,
                    color: "currentColor",
                    fontWeight: 500,
                  }}
                >
                  {copied ? "Copied!" : "Copy text"}
                </Typography>
              </Box>
            </Box>
          )}

          {/* Content */}
          {loading ? (
            <Box
              sx={{
                textAlign: "center",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <CircularProgress color="primary" />
              <Typography variant="h6" color="text.primary" sx={{ mt: 2 }}>
                Loading...
              </Typography>
            </Box>
          ) : (
            <>
              {aiText ? (
                <Box
                  component="div"
                  sx={{
                    margin: 0,
                    padding: "3rem 16px",
                    marginBottom: "1.5rem",
                    flexGrow: 1,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    overflow: "auto",
                    fontWeight: 400,
                    fontSize: 14,
                    lineHeight: 1.8,
                  }}
                  dangerouslySetInnerHTML={{ __html: aiText }}
                  ref={contentRef}
                />
              ) : (
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    fontWeight: 500,
                    fontSize: 20,
                    textAlign: "center",
                  }}
                >
                  {aiPlaceholder}
                </Box>
              )}
            </>
          )}
        </Card>

        {/* Card Actions */}
        <Grid
          container
          justifyContent={matches ? "center" : "space-between"}
          alignItems="center"
          sx={{ marginTop: 2, marginBottom: 2 }}
        >
          <Grid
            item
            xs={matches ? 12 : "auto"}
            style={{ textAlign: matches ? "center" : "left" }}
          >
            <Typography>
              Not the tool you were looking for?{" "}
              <Link href="/dashboard">Go back</Link>
            </Typography>
          </Grid>
          <Grid
            item
            xs={matches ? 12 : "auto"}
            style={{ textAlign: matches ? "center" : "right" }}
          >
            <ButtonGroup variant="contained" color="primary">
              <Button
                variant="contained"
                color="inherit"
                onClick={handleDownload}
              >
                Download PDF
              </Button>
              <Button variant="contained" color="primary" onClick={handlePrint}>
                Print
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>

        <PrintDownErrorDialog
          open={isPrintDownErrorDialogOpen}
          onClose={() => setPrintDownErrorDialogOpen(false)}
        />
        <GeneratingDialog open={loading} />
        <MonthlyWordCountErrorDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          errorMessage="Monthly word limit exceeded. Please wait until the next reset or upgrade your subscription."
        />
      </Box>
    );
  };
}

export default createBlogFromSpeechTemplate;
