import React from "react";
import { Select, MenuItem } from "@mui/material";

const AudioLanguage = ({ audioLanguage, setAudioLanguage }) => {
  const languages = [
    { label: "English (America)", value: "en-US" },
    { label: "English (Australia)", value: "en-AU" },
    { label: "English (British)", value: "en-GB" },
    { label: "English (Canada)", value: "en-CA" },
    { label: "English (India)", value: "en-IN" },
    { label: "French (France)", value: "fr-FR" },
    { label: "German (Germany)", value: "de-DE" },
    { label: "Hebrew (Israel)", value: "iw-IL" },
    { label: "Hindi (India)", value: "hi-IN" },
    { label: "Japanese (Japan)", value: "ja-JP" },
    { label: "Kannada (India)", value: "kn-IN" },
    { label: "Korean (South Korea)", value: "ko-KR" },
    { label: "Malayalam (India)", value: "ml-IN" },
    { label: "Marathi (India)", value: "mr-IN" },
    { label: "Portuguese (Brazil)", value: "pt-BR" },
    { label: "Punjabi (Gurmukhi India)", value: "pa-Guru-IN" },
    { label: "Russian (Russia)", value: "ru-RU" },
    { label: "Spanish (Spain)", value: "es-ES" },
    { label: "Tamil (India)", value: "ta-IN" },
    { label: "Tamil (Sri Lanka)", value: "ta-LK" },
    { label: "Telugu (India)", value: "te-IN" },
    { label: "Urdu (Pakistan)", value: "ur-PK" },
  ];

  return (
    <Select
      displayEmpty
      fullWidth
      value={audioLanguage}
      onChange={(e) => setAudioLanguage(e.target.value)}
      renderValue={(value) =>
        value !== ""
          ? languages.find((lan) => lan.value === value).label
          : "Select audio language"
      }
      sx={{ mb: 1.5 }}
    >
      {languages.map((language) => (
        <MenuItem key={language.value} value={language.value}>
          {language.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default AudioLanguage;
