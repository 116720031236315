import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCR8ME-RzkCTSAEcnIsc1MuHZO9awJAqnU",
    authDomain: "church-scribe-3-16.firebaseapp.com",
    projectId: "church-scribe-3-16",
    storageBucket: "church-scribe-3-16.firebasestorage.app",
    messagingSenderId: "1000644848228",
    appId: "1:1000644848228:web:6d1141794501e9dce88d71",
    measurementId: "G-MMDS8MKZ20"
  };
  
  const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);