import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const Story = () => {
  const theme = useTheme();
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography fontWeight={700} variant={"h5"}>
            Our vision for churchScribe is to make the Word of God accessible,
            understandable, and shareable to everyone, anywhere.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography component={"p"} color={"text.secondary"} fontWeight={400}>
            The inception of churchScribe was not a sudden inspiration, but
            rather a divine call. As we stood at the crossroads of technology
            and faith, we felt the Holy Spirit guiding us to use our skills for
            a greater purpose - to spread the Word of God.
            <br />
            <br />
            And so, churchScribe was born - an AI writer infused with the power
            of GPT-4 and dedicated to that one call of Christ:{" "}
            <i>
              Go into all the world and proclaim the good news to the whole
              creation - Mark 16:15
            </i>
            <br />
            <br />
            Through perseverance, prayer, and endless lines of code, we've built
            this AI writer that embodies our commitment to our call. It
            represents our leap of faith into a future where technology is a
            medium for the divine Word. Join us, and let's make the Gospel
            accessible to everyone together, one AI script at a time.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Story;
